import * as TYPES from "./actionTypes";

export const getHome = (data, callback) => ({
  type: TYPES.GET_HOME,
  payload: { data, callback },
});

export const getHomeFail = (error) => ({
  type: TYPES.GET_HOME_FAIL,
  payload: error,
});

export const getHomeSuccess = (data) => ({
  type: TYPES.GET_HOME_SUCCESS,
  payload: data,
});

export const topGainer = (data, callback) => ({
  type: TYPES.TOP_GAINER,
  payload: { data, callback },
});

export const topGainerFail = (error) => ({
  type: TYPES.TOP_GAINER_FAIL,
  payload: error,
});

export const topGainerSuccess = (data) => ({
  type: TYPES.TOP_GAINER_SUCCESS,
  payload: data,
});

export const topLooser = (data, callback) => ({
  type: TYPES.TOP_LOOSER,
  payload: { data, callback },
});

export const topLooserFail = (error) => ({
  type: TYPES.TOP_LOOSER_FAIL,
  payload: error,
});

export const topLooserSuccess = (data) => ({
  type: TYPES.TOP_LOOSER_SUCCESS,
  payload: data,
});

export const industries = (data, callback) => ({
  type: TYPES.INDUSTRIES,
  payload: { data, callback },
});

export const industriesFail = (error) => ({
  type: TYPES.INDUSTRIES_FAIL,
  payload: error,
});

export const industriesSuccess = (data) => ({
  type: TYPES.INDUSTRIES_SUCCESS,
  payload: data,
});

export const popularStock = (data, callback) => ({
  type: TYPES.POPULARSTOCK,
  payload: { data, callback },
});

export const popularStockFail = (error) => ({
  type: TYPES.POPULARSTOCK_FAIL,
  payload: error,
});

export const popularStockSuccess = (data) => ({
  type: TYPES.POPULARSTOCK_SUCCESS,
  payload: data,
});

export const favouriteStock = (data, callback) => ({
  type: TYPES.FAVOURITE_STOCK,
  payload: { data, callback },
});

export const favouriteStockFail = (error) => ({
  type: TYPES.FAVOURITE_STOCK_FAIL,
  payload: error,
});

export const favouriteStockSuccess = (data) => ({
  type: TYPES.FAVOURITE_STOCK_SUCCESS,
  payload: data,
});

export const addRemoveFavouriteStock = (data, callback) => ({
  type: TYPES.ADD_REMOVE_FAVOURITE_STOCK,
  payload: { data, callback },
});

export const addRemoveFavouriteStockFail = (error) => ({
  type: TYPES.ADD_REMOVE_FAVOURITE_STOCK_FAIL,
  payload: error,
});

export const addRemoveFavouriteStockSuccess = (data) => ({
  type: TYPES.ADD_REMOVE_FAVOURITE_STOCK_SUCCESS,
  payload: data,
});

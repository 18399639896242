import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";

// img
import logo from "../../../Assets/images/logo.png";

// css
import styles from "../../../layout/Auth/Auth.module.scss";
//redux
import { useSelector, useDispatch } from "react-redux";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { resetPassword } from "store/actions";

const ResetPassword = () => {
  const [pass, setPass] = useState();
  const handlePass = () => {
    setPass(!pass);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();

  const { loading } = useSelector(state => state.Profile)
  const receivedData = location?.state?.data?.data;

  const validation = useFormik({
    // enableReinitialize
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Please enter your password").min(6, "Password must be at most 6 characters long"),
      confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),

    onSubmit: (values) => {
      const callback = (response) => {
        const { data, message } = response;
        
        if (response.status === "success") {
          toast.success(message)
          navigate("/")
        }
      }
      values.email = receivedData?.email
      console.log(values)
     dispatch(resetPassword(values, callback))
    },
  });
  return (
    <>
      <Col md="6" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
        >
          <span
            className="position-absolute graphic"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
          >
            <svg
              className="w-100 h-100"
              xmlns="http://www.w3.org/2000/svg"
              width="702"
              height="702"
              viewBox="0 0 702 702"
              fill="none"
            >
              <g opacity="0.3" filter="url(#filter0_f_0_599)">
                <circle cx="351" cy="351" r="117" fill="#CF0A10" />
              </g>
              <defs>
                <filter
                  id="filter0_f_0_599"
                  x="0"
                  y="0"
                  width="702"
                  height="702"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="117"
                    result="effect1_foregroundBlur_0_599"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <div className="formInner">
            <div
              className={`${styles.top} top py-2 text-center  position-relative`}
            >
              <h3 className="m-0 pb-2 fw-sbold head text-white">
                Reset Your Password
              </h3>
              {/* <p className="m-0 text-white">
                We Send a verification code to your Registerted email id
              </p> */}
            </div>
            <Form className="mx-auto py-3" style={{ maxWidth: 350 }}  onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              validation.setTouched({
                password: true,
                confirmPassword: true,
              });
              return false
            }}>
              <Row>
                <Col lg="12" className="my-2">
                  <Input
                    type="password"
                    className={`${styles.formControl} form-control rounded-pill px-4`}
                    placeholder="Enter New Password"
                    name="password"
                    value={validation.values.password || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg="12" className="my-2">
                  <Input
                    type="password"
                    className={`${styles.formControl} form-control  rounded-pill px-4`}
                    placeholder="Enter Confirm Password"
                    name="confirmPassword"
                    value={validation.values.confirmPassword || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.confirmPassword && validation.errors.confirmPassword
                        ? true
                        : false
                    }
                  />
                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.confirmPassword}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper`}>
                    <Button
                      // onClick={() => navigate("/login")}
                      type="submit"
                      disabled={loading}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                      {loading ? "Loading…" : "SUBMIT"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </>
  );
};

export default ResetPassword;

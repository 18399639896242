import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";

import { useState } from "react";
import { toast } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// img
import logo from "../../../Assets/images/logo.png";

// css
import styles from "../../../layout/Auth/Auth.module.scss";
// actions
import { loginUser } from "store/actions";
import { useFirebase } from "../../../firebase/firebase";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.Login);
  // const [loading, setLoading] = useState(false);

  const [pass, setPass] = useState();
  const handlePass = () => {
    setPass(!pass);
  };

  const { token: firebaseToken } = useFirebase();
  console.log(firebaseToken, "firebaseToken");
  const validation = useFormik({
    // enableReinitialize
    enableReinitialize: true,
    initialValues: {
      // email: localStorage.getItem("rememberedEmail") || "",
      // password: localStorage.getItem("rememberedPassword") || "",
      email: "",
      password: "",
      remember_me: false,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email address"),
      password: Yup.string()
        .required("Please enter your password")
        .min(6, "Password must be at most 6 characters long"),
      remember_me: Yup.boolean().oneOf([false, true], "Required"),
    }),

    onSubmit: (values) => {
      if (values.remember_me) {
        localStorage.setItem("rememberedEmail", values.email);
        localStorage.setItem("rememberedPassword", values.password);
      }
      const callback = (response) => {
        const { data, message } = response;

        if (response.status === "success") {
          toast.success(message);
          navigate("/home");
        }
      };
      values.loginType = "regular";
      values.firebaseToken = "none";
      values.firebaseToken = firebaseToken;
      dispatch(loginUser(values, callback));
    },
  });

  return (
    <>
      <Col md="6" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
        >
          <span
            className="position-absolute graphic"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
          >
            <svg
              className="w-100 h-100"
              xmlns="http://www.w3.org/2000/svg"
              width="702"
              height="702"
              viewBox="0 0 702 702"
              fill="none"
            >
              <g opacity="0.3" filter="url(#filter0_f_0_599)">
                <circle cx="351" cy="351" r="117" fill="#CF0A10" />
              </g>
              <defs>
                <filter
                  id="filter0_f_0_599"
                  x="0"
                  y="0"
                  width="702"
                  height="702"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="117"
                    result="effect1_foregroundBlur_0_599"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <div className="formInner">
            <div
              className={`${styles.top} top py-2 text-center  position-relative`}
            >
              <h3 className="m-0 pb-2 fw-sbold head text-white">
                Log in to your account
              </h3>
              <p className="m-0 text-white">
                Welcome back! Please enter your details.
              </p>
            </div>
            <Form
              className="mx-auto py-3"
              style={{ maxWidth: 350 }}
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                validation.setTouched({
                  email: true,
                  password: true,
                  remember_me: true,
                });
                return false;
              }}
            >
              <Row>
                <Col lg="12" className="my-2">
                  <Input
                    type="email"
                    name="email"
                    className={`${styles.formControl} form-control  rounded-pill px-4`}
                    placeholder="Email ID"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg="12" className="my-2">
                  <Input
                    type="password"
                    name="password"
                    value={validation.values.password || ""}
                    className={`${styles.formControl} form-control rounded-pill px-4`}
                    placeholder="Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg="12" className="my-2">
                  <div className="d-flex align-items-center justify-content-between gap-10">
                    <div className=" d-flex align-items-center ps-3 left">
                      <Input
                        type="checkbox"
                        name="remember_me"
                        className="form-check me-2"
                        value={validation.values.remember_me || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.remember_me &&
                          validation.errors.remember_me
                            ? true
                            : false
                        }
                      />

                      <label
                        htmlFor="rememeberMe"
                        className="form-label text-white m-0"
                      >
                        Remember Me
                      </label>
                    </div>

                    <div className="right">
                      <Link
                        className={`${styles.link} text-white`}
                        to={"/forgot-password"}
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper`}>
                    <Button
                      // onClick={() => navigate("/home")}
                      type="submit"
                      disabled={loading}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                      {loading ? "Loading…" : "LOGIN"}
                    </Button>
                  </div>
                  {/* <div className="text-center mt-3 d-flex align-items-center justify-content-between">
                    <p className="m-0 text-white">Don't have an account?</p>
                    <Link
                      className={`${styles.link} text-white`}
                      to={"/signup"}
                    >
                      Sign up
                    </Link>
                  </div> */}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </>
  );
};
export default Login;

import React, { useEffect, useState } from "react";
import moment from "moment";

// img
import c1 from "../../../../Assets/images/c1.png";
import { Nav, Tab, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { tradingActivities } from "store/actions";
//pagination
import ReactPagination from "../../../../components/Common/ReactPagination";
import {
  handleDecimalToViewOnly,
  itemsPerPage,
  quantityToSpecificDecimal,
} from "helpers/helper";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router-dom";
import HistoryOrderDetailModal from "./HistoryOrderDetailModal";

const HistoricalTab = ({
  historyOrderList,
  filter,
  setFilter,
  handleChangePicker,
  field,
  setfields,
}) => {
  const dispatch = useDispatch();
  const { tradingActivityList, loading } = useSelector((state) => state?.Basic);
  const [currentTab, setCurrentTab] = useState("Orders");
  const [key, setKey] = useState(0);
  const navigate = useNavigate();
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (currentTab === "TradingActivities") {
      dispatch(tradingActivities());
    }
  }, [currentTab]);

  const handleTabClick = (key) => {
    
    setCurrentTab(key);
    // Perform additional actions based on the clicked tab
  };
  const indexOfLastItem = filter?.page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    tradingActivityList?.data?.tradeActivity?.length > 0 &&
    tradingActivityList?.data?.tradeActivity?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  const clearSerchFilter = () => {
    setfields({
      startDate: "",
      endDate: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <HistoryOrderDetailModal
        show={showDetailModal}
        close={() => setShowDetailModal(false)}
        type={currentTab}
        stockView={selectedItem}
      />
      <div className="py-2">
        <Tab.Container id="left-tabs-example" defaultActiveKey="Orders">
          <Nav
            variant="pills"
            className={` navPillsTab navPillsTab2 gap-10 mt-3`}
          >
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Orders"
                onClick={() => handleTabClick("Orders")}
              >
                Orders
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="TradingActivities"
                onClick={() => handleTabClick("TradingActivities")}
              >
                Trading Activities
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="TradingActivities">
              {tradingActivityList?.data?.tradeActivity?.length > 0 ? (
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    {currentItems?.length > 0 &&
                      currentItems?.map((item, i) => {
                        return (
                          <li className="py-3" key={i}>
                            <div
                              className="box rounded cardCstm"
                              style={{ fontSize: 10, lineHeight: "12px" }}
                              onClick={() => {
                                setSelectedItem(item);
                                setShowDetailModal(true);
                              }}
                            >
                              <div
                                className=" p-2 top d-flex align-items-center justify-content-between gap-10"
                                style={{
                                  borderBottom:
                                    " 1px solid var(--darkLightGray)",
                                }}
                              >
                                <div className="left d-flex align-items-center gap-10">
                                  <div
                                    className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                                    style={{ height: 40, width: 40 }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="24"
                                      viewBox="0 0 20 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_2630)">
                                        <path
                                          d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2630">
                                          <rect
                                            width="20"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <img
                                      src={item?.imgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="content ">
                                    <h6 className="m-0">{item?.symbol}</h6>
                                    <p className="m-0 text-muted m-0">
                                      {item?.shortName}
                                    </p>
                                  </div>
                                </div>
                                <div className="right text-end">
                                  <h6 className="m-0">Order Id</h6>
                                  <p className="m-0 text-muted fw-sbold">
                                    {item?.order_id}
                                  </p>
                                </div>
                              </div>
                              <div className="bottom p-2">
                                <Row>
                                  {/* <Col lg="3" md="4" sm="6" className="my-2">
                                <div className="text-center">
                                <h6 className="m-0  fw-sbold">${item.amount ? parseFloat(item.amount).toFixed(2): 0}</h6>
                                  <p className="m-0 text-muted">Amount</p>
                                </div>
                              </Col> */}
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0  fw-sbold">
                                        {item.qty
                                          ? quantityToSpecificDecimal(
                                              item?.qty,
                                              5
                                            )
                                          : 0}
                                      </h6>
                                      <p className="m-0 text-muted">Quantity</p>
                                    </div>
                                  </Col>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-end">
                                      <h6 className="m-0  fw-sbold">
                                        $
                                        {item?.price
                                          ? handleDecimalToViewOnly(item?.price)
                                          : 0}
                                      </h6>
                                      <p className="m-0 text-muted">Price</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                <div className="py-2">
                  {" "}
                  <div
                    className="box rounded cardCstm my-3"
                    style={{ fontSize: 10, lineHeight: "12px" }}
                  >
                    <div className="py-5 text-center">
                      <h6 className="m-0 fw-sbold">No Data available</h6>
                    </div>
                  </div>
                </div>
              )}
              {tradingActivityList?.data?.tradeActivity?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={
                      tradingActivityList?.data?.tradeActivity?.length
                    }
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="Orders">
              {" "}
              <div className="py-2">
                <p className="m-0 fw-sbold">Select your date Range</p>
                <Row>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    {/* <input
                      type="date"
                      className="form-control box"
                      style={{ fontSize: 12 }}
                    /> */}
                    <Datetime
                      key={key}
                      value={field.startDate || ""}
                      inputProps={{
                        placeholder: "dd/mm/yyyy",
                        readOnly: true,
                        className: "form-control box",
                      }}
                      name="startDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      isValidDate={(current) => {
                        return current.isSameOrBefore(moment(), "day");
                      }}
                      onChange={(selectedDate) =>
                        handleChangePicker(selectedDate, "startDate")
                      }
                      closeOnSelect={true}
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <Datetime
                      key={key}
                      value={field.endDate || ""}
                      inputProps={{
                        placeholder: "dd/mm/yyyy",
                        readOnly: true,
                        className: "form-control box",
                      }}
                      name="endDate"
                      dateFormat="DD/MM/YYYY"
                      timeFormat={false}
                      isValidDate={(current) => {
                        // Parse the current date string in DD/MM/YYYY format to a moment object
                        const currentDate = moment(current, "DD/MM/YYYY", true);
                        // Parse the start date string in DD/MM/YYYY format to a moment object
                        const startDate = moment(
                          field?.startDate,
                          "DD/MM/YYYY",
                          true
                        );
                        // Check if the current date is the same as or after the start date
                        return (
                          currentDate.isValid() &&
                          startDate.isValid() &&
                          currentDate.isSameOrAfter(startDate, "day")
                        );
                      }}
                      onChange={(selectedDate) =>
                        handleChangePicker(selectedDate, "endDate")
                      }
                      closeOnSelect={true}
                    />
                  </Col>
                  <Col lg="3" md="4" sm="6" className="my-2">
                    <Button
                      style={{ backgroundColor: "red", borderColor: "red" }}
                      className="d-flex align-items-center justify-content-center fw-sbold"
                      onClick={clearSerchFilter}
                    >
                      Clear filter
                    </Button>
                  </Col>
                </Row>
              </div>
              {historyOrderList?.data?.length > 0 ? (
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    {historyOrderList?.data?.length > 0 &&
                      historyOrderList?.data?.map((item, i) => {
                        return (
                          <li
                            className="py-3"
                            key={i}
                            onClick={() => {
                              setSelectedItem(item);
                              setShowDetailModal(true);
                            }}
                          >
                            <div
                              className="box rounded cardCstm"
                              style={{ fontSize: 10, lineHeight: "12px" }}
                            >
                              <div
                                className=" p-2 top d-flex align-items-center justify-content-between gap-10"
                                style={{
                                  borderBottom:
                                    " 1px solid var(--darkLightGray)",
                                }}
                              >
                                <div className="left d-flex align-items-center gap-10">
                                  <div
                                    className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                                    style={{ height: 40, width: 40 }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="24"
                                      viewBox="0 0 20 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_2630)">
                                        <path
                                          d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2630">
                                          <rect
                                            width="20"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <img
                                      src={item?.imgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="content ">
                                    <h6 className="m-0">{item?.symbol}</h6>
                                    <p className="m-0 text-muted m-0">
                                      {item?.shortName}
                                    </p>
                                  </div>
                                </div>
                                <div className="right text-end">
                                  <h6 className="m-0">Order Id</h6>
                                  <p className="m-0 text-muted fw-sbold">
                                    {item?.id}
                                  </p>
                                </div>
                              </div>
                              <div className="bottom p-2">
                                <Row>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0  fw-sbold">
                                        $
                                        {item?.requestAmount
                                          ? handleDecimalToViewOnly(
                                              item?.requestAmount,
                                              2
                                            )
                                          : 0}
                                      </h6>
                                      <p className="m-0 text-muted">Amount</p>
                                    </div>
                                  </Col>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0  fw-sbold">
                                        {item?.qty
                                          ? quantityToSpecificDecimal(
                                              item?.qty,
                                              5
                                            )
                                          : 0}
                                      </h6>
                                      <p className="m-0 text-muted">Quantity</p>
                                    </div>
                                  </Col>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0  fw-sbold">
                                        $
                                        {item.requestPrice
                                          ? handleDecimalToViewOnly(
                                              item?.requestPrice,
                                              2
                                            )
                                          : 0}
                                      </h6>
                                      <p className="m-0 text-muted">Price</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                <div className="py-2">
                  {" "}
                  <div
                    className="box rounded cardCstm my-3"
                    style={{ fontSize: 10, lineHeight: "12px" }}
                  >
                    <div className="py-5 text-center">
                      <h6 className="m-0 fw-sbold">No Data available</h6>
                    </div>
                  </div>
                </div>
              )}
              {historyOrderList?.data?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={historyOrderList?.pageData?.totalItems}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default HistoricalTab;

import { CONSTANT } from "./constant";

export const limitOrderTypesListStatic = [
  {
    label: "GTC",
    value: "gtc",
  },
  {
    label: "DAY",
    value: "day",
  },
];

export const purchaseTypesStatic = [
  {
    _id: 1,
    label: 'Share',
    value: 'Share',
  },
  {
    _id: 2,
    label: 'Money',
    value: 'Money',
  },
];

export const orderTypesStatic = [
  {
    _id: 1,
    label: 'Market',
    value: 'market',
  },
  {
    _id: 2,
    label: 'Limit',
    value: 'limit',
  },
];


export const stockDetailsStatic = {
  _id: 1,
  name: 'Tesla',
  symbol: 'TSLA',
  lastPrice: 620.35,
  currentPrice: 315,
  percent_change: -0.72,
  open: 119.3,
  high: 120.36,
  low: 116.29,
  '52WkHigh': 274.29,
  '52WkLow': 108.7,
  Volume: '9.25M',
  AvgVol: '25.25M',
  MarketCap: '341.80B',
  PERatio: '17.61',
  DivYield: '',
  news: {
    _id: 1,
    // image: IMAGES.DEMO_PIC_RANDOM,

    name: 'Benzinga',
    headline:
      'Why Tasla, JD and Other Tech  Stocks are Crashing in Hong Kong Today',
  },
  // image: IMAGES.demoStock2,
  price: 620.35,
  fiveYearData: {
    _id: 1,
    buyPrice: 525.0,
    holdPrice: 525.0,
    sellPrice: 960,
  },
  buyDate: '23/11/2023',
  sellDate: '23/11/2023',
};


export const buyItEveryDayListStatic = [
  {
    _id: 1,
    label: '10 AM',
    time: '10:00',
  },
  {
    _id: 2,
    label: '11 AM',
    time: '11:00',
  },
  {
    _id: 3,
    label: '12 PM',
    time: '12:00',
  },
  {
    _id: 4,
    label: '1 PM',
    time: '13:00',
  },
  {
    _id: 5,
    label: '2 PM',
    time: '14:00',
  },
  {
    _id: 6,
    label: '3 PM',
    time: '15:00',
  },
  // {
  //   _id: 7,
  //   label: '4 PM',
  //   time: '16:00',
  // },
];

export const buyItWeeklyListStatic = [
  {
    _id: 1,
    label: 'Monday - 10 AM',
    day: 'Monday',
    time: '10:00',
  },
  {
    _id: 2,
    label: 'Monday - 11 AM',
    day: 'Monday',
    time: '11:00',
  },
  {
    _id: 3,
    label: 'Monday - 12 PM',
    day: 'Monday',
    time: '12:00',
  },
  {
    _id: 4,
    label: 'Monday - 1 PM',
    day: 'Monday',
    time: '13:00',
  },
  {
    _id: 5,
    label: 'Monday - 2 PM',
    day: 'Monday',
    time: '14:00',
  },
  {
    _id: 6,
    label: 'Monday - 3 PM',
    day: 'Monday',
    time: '15:00',
  },
  // {
  //   _id: 7,
  //   label: 'Monday - 4 PM',
  //   day: 'Monday',
  //   time: '16:00',
  // },
  {
    _id: 8,
    label: 'Tuesday - 10 AM',
    day: 'Tuesday',
    time: '10:00',
  },
  {
    _id: 9,
    label: 'Tuesday - 11 AM',
    day: 'Tuesday',
    time: '11:00',
  },
  {
    _id: 10,
    label: 'Tuesday - 12 PM',
    day: 'Tuesday',
    time: '12:00',
  },
  {
    _id: 11,
    label: 'Tuesday - 1 PM',
    day: 'Tuesday',
    time: '13:00',
  },
  {
    _id: 12,
    label: 'Tuesday - 2 PM',
    day: 'Tuesday',
    time: '14:00',
  },
  {
    _id: 13,
    label: 'Tuesday - 3 PM',
    day: 'Tuesday',
    time: '15:00',
  },
  // {
  //   _id: 14,
  //   label: 'Tuesday - 4 PM',
  //   day: 'Tuesday',
  //   time: '16:00',
  // },
  {
    _id: 15,
    label: 'Wednesday - 10 AM',
    day: 'Wednesday',
    time: '10:00',
  },
  {
    _id: 16,
    label: 'Wednesday - 11 AM',
    day: 'Wednesday',
    time: '11:00',
  },
  {
    _id: 17,
    label: 'Wednesday - 12 PM',
    day: 'Wednesday',
    time: '12:00',
  },
  {
    _id: 18,
    label: 'Wednesday - 1 PM',
    day: 'Wednesday',
    time: '13:00',
  },
  {
    _id: 19,
    label: 'Wednesday - 2 PM',
    day: 'Wednesday',
    time: '14:00',
  },
  {
    _id: 20,
    label: 'Wednesday - 3 PM',
    day: 'Wednesday',
    time: '15:00',
  },
  // {
  //   _id: 21,
  //   label: 'Wednesday - 4 PM',
  //   day: 'Wednesday',
  //   time: '16:00',
  // },
  {
    _id: 22,
    label: 'Thursday - 10 AM',
    day: 'Thursday',
    time: '10:00',
  },
  {
    _id: 23,
    label: 'Thursday - 11 AM',
    day: 'Thursday',
    time: '11:00',
  },
  {
    _id: 24,
    label: 'Thursday - 12 PM',
    day: 'Thursday',
    time: '12:00',
  },
  {
    _id: 25,
    label: 'Thursday - 1 PM',
    day: 'Thursday',
    time: '13:00',
  },
  {
    _id: 26,
    label: 'Thursday - 2 PM',
    day: 'Thursday',
    time: '14:00',
  },
  {
    _id: 27,
    label: 'Thursday - 3 PM',
    day: 'Thursday',
    time: '15:00',
  },
  // {
  //   _id: 28,
  //   label: 'Thursday - 4 PM',
  //   day: 'Thursday',
  //   time: '16:00',
  // },
  {
    _id: 29,
    label: 'Friday - 10 AM',
    day: 'Friday',
    time: '10:00',
  },
  {
    _id: 30,
    label: 'Friday - 11 AM',
    day: 'Friday',
    time: '11:00',
  },
  {
    _id: 31,
    label: 'Friday - 12 PM',
    day: 'Friday',
    time: '12:00',
  },
  {
    _id: 32,
    label: 'Friday - 1 PM',
    day: 'Friday',
    time: '13:00',
  },
  {
    _id: 33,
    label: 'Friday - 2 PM',
    day: 'Friday',
    time: '14:00',
  },
  {
    _id: 34,
    label: 'Friday - 3 PM',
    day: 'Friday',
    time: '15:00',
  },
  // {
  //   _id: 35,
  //   label: 'Friday - 4 PM',
  //   day: 'Friday',
  //   time: '16:00',
  // },
];
export const buyItMonthlyListStatic = [
  {
    _id: 1,
    label: 'Every First Monday of the Month',
    day: 'Monday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 2,
    label: 'Every First Wednesday of the Month',
    day: 'Wednesday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 3,
    label: 'Every First Friday of the Month',
    day: 'Friday',
    time: '16:00',
    timeType: 'First',
  },
  {
    _id: 4,
    label: 'Every Last Monday of the Month',
    day: 'Monday',
    time: '16:00',
    timeType: 'Last',
  },
  {
    _id: 5,
    label: 'Every Last Wednesday of the Month',
    day: 'Wednesday',
    time: '16:00',
    timeType: 'Last',
  },
  {
    _id: 6,
    label: 'Every Last Friday of the Month',
    day: 'Friday',
    time: '16:00',
    timeType: 'Last',
  },
];



export const scheduleOrderListStatic = [
  {
    _id: 1,
    label: 'Buy it every Day',
    value: CONSTANT.DAILY,
    isSelected: false,
  },
  {
    _id: 2,
    label: 'Buy it every Week',
    value: CONSTANT.WEEKLY,
    isSelected: false,
  },
  {
    _id: 3,
    label: 'Buy it every Month',
    value: CONSTANT.MONTHLY,
    isSelected: false,
  },
];

export const countriesStatic = [
  {label: 'Afghanistan', value: 'Afghanistan'},
  {label: 'Albania', value: 'Albania'},
  {label: 'Algeria', value: 'Algeria'},
  {label: 'Andorra', value: 'Andorra'},
  {label: 'Angola', value: 'Angola'},
  {label: 'Antigua and Barbuda', value: 'Antigua and Barbuda'},
  {label: 'Argentina', value: 'Argentina'},
  {label: 'Armenia', value: 'Armenia'},
  {label: 'Australia', value: 'Australia'},
  {label: 'Austria', value: 'Austria'},
  {label: 'Azerbaijan', value: 'Azerbaijan'},
  {label: 'Bahamas', value: 'Bahamas'},
  {label: 'Bahrain', value: 'Bahrain'},
  {label: 'Bangladesh', value: 'Bangladesh'},
  {label: 'Barbados', value: 'Barbados'},
  {label: 'Belarus', value: 'Belarus'},
  {label: 'Belgium', value: 'Belgium'},
  {label: 'Belize', value: 'Belize'},
  {label: 'Benin', value: 'Benin'},
  {label: 'Bhutan', value: 'Bhutan'},
  {label: 'Bolivia', value: 'Bolivia'},
  {label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
  {label: 'Botswana', value: 'Botswana'},
  {label: 'Brazil', value: 'Brazil'},
  {label: 'Brunei', value: 'Brunei'},
  {label: 'Bulgaria', value: 'Bulgaria'},
  {label: 'Burkina Faso', value: 'Burkina Faso'},
  {label: 'Burundi', value: 'Burundi'},
  {label: 'Cabo Verde', value: 'Cabo Verde'},
  {label: 'Cambodia', value: 'Cambodia'},
  {label: 'Cameroon', value: 'Cameroon'},
  {label: 'Canada', value: 'Canada'},
  {label: 'Central African Republic', value: 'Central African Republic'},
  {label: 'Chad', value: 'Chad'},
  {label: 'Chile', value: 'Chile'},
  {label: 'China', value: 'China'},
  {label: 'Colombia', value: 'Colombia'},
  {label: 'Comoros', value: 'Comoros'},
  {label: 'Congo (Congo-Brazzaville)', value: 'Congo (Congo-Brazzaville)'},
  {label: 'Costa Rica', value: 'Costa Rica'},
  {label: 'Croatia', value: 'Croatia'},
  {label: 'Cuba', value: 'Cuba'},
  {label: 'Cyprus', value: 'Cyprus'},
  {label: 'Czech Republic', value: 'Czech Republic'},
  {
    label: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo',
  },
  {label: 'Denmark', value: 'Denmark'},
  {label: 'Djibouti', value: 'Djibouti'},
  {label: 'Dominica', value: 'Dominica'},
  {label: 'Dominican Republic', value: 'Dominican Republic'},
  {label: 'Ecuador', value: 'Ecuador'},
  {label: 'Egypt', value: 'Egypt'},
  {label: 'El Salvador', value: 'El Salvador'},
  {label: 'Equatorial Guinea', value: 'Equatorial Guinea'},
  {label: 'Eritrea', value: 'Eritrea'},
  {label: 'Estonia', value: 'Estonia'},
  {label: 'Eswatini', value: 'Eswatini'},
  {label: 'Ethiopia', value: 'Ethiopia'},
  {label: 'Fiji', value: 'Fiji'},
  {label: 'Finland', value: 'Finland'},
  {label: 'France', value: 'France'},
  {label: 'Gabon', value: 'Gabon'},
  {label: 'Gambia', value: 'Gambia'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Germany', value: 'Germany'},
  {label: 'Ghana', value: 'Ghana'},
  {label: 'Greece', value: 'Greece'},
  {label: 'Grenada', value: 'Grenada'},
  {label: 'Guatemala', value: 'Guatemala'},
  {label: 'Guinea', value: 'Guinea'},
  {label: 'Guinea-Bissau', value: 'Guinea-Bissau'},
  {label: 'Guyana', value: 'Guyana'},
  {label: 'Haiti', value: 'Haiti'},
  {label: 'Honduras', value: 'Honduras'},
  {label: 'Hungary', value: 'Hungary'},
  {label: 'Iceland', value: 'Iceland'},
  {label: 'India', value: 'India'},
  {label: 'Indonesia', value: 'Indonesia'},
  {label: 'Iran', value: 'Iran'},
  {label: 'Iraq', value: 'Iraq'},
  {label: 'Ireland', value: 'Ireland'},
  {label: 'Israel', value: 'Israel'},
  {label: 'Italy', value: 'Italy'},
  {label: 'Jamaica', value: 'Jamaica'},
  {label: 'Japan', value: 'Japan'},
  {label: 'Jordan', value: 'Jordan'},
  {label: 'Kazakhstan', value: 'Kazakhstan'},
  {label: 'Kenya', value: 'Kenya'},
  {label: 'Kiribati', value: 'Kiribati'},
  {label: 'Kuwait', value: 'Kuwait'},
  {label: 'Kyrgyzstan', value: 'Kyrgyzstan'},
  {label: 'Laos', value: 'Laos'},
  {label: 'Latvia', value: 'Latvia'},
  {label: 'Lebanon', value: 'Lebanon'},
  {label: 'Lesotho', value: 'Lesotho'},
  {label: 'Liberia', value: 'Liberia'},
  {label: 'Libya', value: 'Libya'},
  {label: 'Liechtenstein', value: 'Liechtenstein'},
  {label: 'Lithuania', value: 'Lithuania'},
  {label: 'Luxembourg', value: 'Luxembourg'},
  {label: 'Madagascar', value: 'Madagascar'},
  {label: 'Malawi', value: 'Malawi'},
  {label: 'Malaysia', value: 'Malaysia'},
  {label: 'Maldives', value: 'Maldives'},
  {label: 'Mali', value: 'Mali'},
  {label: 'Malta', value: 'Malta'},
  {label: 'Marshall Islands', value: 'Marshall Islands'},
  {label: 'Mauritania', value: 'Mauritania'},
  {label: 'Mauritius', value: 'Mauritius'},
  {label: 'Mexico', value: 'Mexico'},
  {label: 'Micronesia', value: 'Micronesia'},
  {label: 'Moldova', value: 'Moldova'},
  {label: 'Monaco', value: 'Monaco'},
  {label: 'Mongolia', value: 'Mongolia'},
  {label: 'Montenegro', value: 'Montenegro'},
  {label: 'Morocco', value: 'Morocco'},
  {label: 'Mozambique', value: 'Mozambique'},
  {label: 'Myanmar (formerly Burma)', value: 'Myanmar (formerly Burma)'},
  {label: 'Namibia', value: 'Namibia'},
  {label: 'Nauru', value: 'Nauru'},
  {label: 'Nepal', value: 'Nepal'},
  {label: 'Netherlands', value: 'Netherlands'},
  {label: 'New Zealand', value: 'New Zealand'},
  {label: 'Nicaragua', value: 'Nicaragua'},
  {label: 'Niger', value: 'Niger'},
  {label: 'Nigeria', value: 'Nigeria'},
  {label: 'North Korea', value: 'North Korea'},
  {
    label: 'North Macedonia (formerly Macedonia)',
    value: 'North Macedonia (formerly Macedonia)',
  },
  {label: 'Norway', value: 'Norway'},
  {label: 'Oman', value: 'Oman'},
  {label: 'Pakistan', value: 'Pakistan'},
  {label: 'Palau', value: 'Palau'},
  {label: 'Palestine State', value: 'Palestine State'},
  {label: 'Panama', value: 'Panama'},
  {label: 'Papua New Guinea', value: 'Papua New Guinea'},
  {label: 'Paraguay', value: 'Paraguay'},
  {label: 'Peru', value: 'Peru'},
  {label: 'Philippines', value: 'Philippines'},
  {label: 'Poland', value: 'Poland'},
  {label: 'Portugal', value: 'Portugal'},
  {label: 'Qatar', value: 'Qatar'},
  {label: 'Romania', value: 'Romania'},
  {label: 'Russia', value: 'Russia'},
  {label: 'Rwanda', value: 'Rwanda'},
  {label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis'},
  {label: 'Saint Lucia', value: 'Saint Lucia'},
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {label: 'Samoa', value: 'Samoa'},
  {label: 'San Marino', value: 'San Marino'},
  {label: 'Sao Tome and Principe', value: 'Sao Tome and Principe'},
  {label: 'Saudi Arabia', value: 'Saudi Arabia'},
  {label: 'Senegal', value: 'Senegal'},
  {label: 'Serbia', value: 'Serbia'},
  {label: 'Seychelles', value: 'Seychelles'},
  {label: 'Sierra Leone', value: 'Sierra Leone'},
  {label: 'Singapore', value: 'Singapore'},
  {label: 'Slovakia', value: 'Slovakia'},
  {label: 'Slovenia', value: 'Slovenia'},
  {label: 'Solomon Islands', value: 'Solomon Islands'},
  {label: 'Somalia', value: 'Somalia'},
  {label: 'South Africa', value: 'South Africa'},
  {label: 'South Korea', value: 'South Korea'},
  {label: 'South Sudan', value: 'South Sudan'},
  {label: 'Spain', value: 'Spain'},
  {label: 'Sri Lanka', value: 'Sri Lanka'},
  {label: 'Sudan', value: 'Sudan'},
  {label: 'Suriname', value: 'Suriname'},
  {label: 'Sweden', value: 'Sweden'},
  {label: 'Switzerland', value: 'Switzerland'},
  {label: 'Syria', value: 'Syria'},
  {label: 'Tajikistan', value: 'Tajikistan'},
  {label: 'Tanzania', value: 'Tanzania'},
  {label: 'Thailand', value: 'Thailand'},
  {label: 'Timor-Leste', value: 'Timor-Leste'},
  {label: 'Togo', value: 'Togo'},
  {label: 'Tonga', value: 'Tonga'},
  {label: 'Trinidad and Tobago', value: 'Trinidad and Tobago'},
  {label: 'Tunisia', value: 'Tunisia'},
  {label: 'Turkey', value: 'Turkey'},
  {label: 'Turkmenistan', value: 'Turkmenistan'},
  {label: 'Tuvalu', value: 'Tuvalu'},
  {label: 'Uganda', value: 'Uganda'},
  {label: 'Ukraine', value: 'Ukraine'},
  {label: 'United Arab Emirates', value: 'United Arab Emirates'},
  {label: 'United Kingdom', value: 'United Kingdom'},
  {label: 'United States of America', value: 'United States of America'},
  {label: 'Uruguay', value: 'Uruguay'},
  {label: 'Uzbekistan', value: 'Uzbekistan'},
  {label: 'Vanuatu', value: 'Vanuatu'},
  {label: 'Vatican City (Holy See)', value: 'Vatican City (Holy See)'},
  {label: 'Venezuela', value: 'Venezuela'},
  {label: 'Vietnam', value: 'Vietnam'},
  {label: 'Yemen', value: 'Yemen'},
  {label: 'Zambia', value: 'Zambia'},
  {label: 'Zimbabwe', value: 'Zimbabwe'},
];


export const currenciesStatic = [
  {label: 'Indonesian Rupiah (IDR)', value: 'IDR'},
  {label: 'United States Dollar (USD)', value: 'USD'},
  {label: 'Malaysian Ringgit (MYR)', value: 'MYR'},
  {label: 'Euro (EUR)', value: 'EUR'},
  {label: 'Australian Dollar (AUD)', value: 'AUD'},
  {label: 'British Pound Sterling (GBP)', value: 'GBP'},
  {label: 'Japanese Yen (JPY)', value: 'JPY'},
  {label: 'Afghan Afghani (AFN)', value: 'AFN'},
  {label: 'Albanian Lek (ALL)', value: 'ALL'},
  {label: 'Algerian Dinar (DZD)', value: 'DZD'},
  {label: 'Argentine Peso (ARS)', value: 'ARS'},
  {label: 'Armenian Dram (AMD)', value: 'AMD'},
  {label: 'Aruban Florin (AWG)', value: 'AWG'},
  {label: 'Azerbaijani Manat (AZN)', value: 'AZN'},
  {label: 'Bahamian Dollar (BSD)', value: 'BSD'},
  {label: 'Bahraini Dinar (BHD)', value: 'BHD'},
  {label: 'Bangladeshi Taka (BDT)', value: 'BDT'},
  {label: 'Barbadian Dollar (BBD)', value: 'BBD'},
  {label: 'Belarusian Ruble (BYN)', value: 'BYN'},
  {label: 'Belize Dollar (BZD)', value: 'BZD'},
  {label: 'Bermudian Dollar (BMD)', value: 'BMD'},
  {label: 'Bhutanese Ngultrum (BTN)', value: 'BTN'},
  {label: 'Bolivian Boliviano (BOB)', value: 'BOB'},
  {label: 'Bosnia and Herzegovina Convertible Mark (BAM)', value: 'BAM'},
  {label: 'Botswana Pula (BWP)', value: 'BWP'},
  {label: 'Brazilian Real (BRL)', value: 'BRL'},
  {label: 'Brunei Dollar (BND)', value: 'BND'},
  {label: 'Bulgarian Lev (BGN)', value: 'BGN'},
  {label: 'Burundian Franc (BIF)', value: 'BIF'},
  {label: 'Cape Verdean Escudo (CVE)', value: 'CVE'},
  {label: 'Cambodian Riel (KHR)', value: 'KHR'},
  {label: 'Canadian Dollar (CAD)', value: 'CAD'},
  {label: 'Cayman Islands Dollar (KYD)', value: 'KYD'},
  {label: 'Chilean Peso (CLP)', value: 'CLP'},
  {label: 'Chinese Yuan (CNY)', value: 'CNY'},
  {label: 'Colombian Peso (COP)', value: 'COP'},
  {label: 'Comorian Franc (KMF)', value: 'KMF'},
  {label: 'Congolese Franc (CDF)', value: 'CDF'},
  {label: 'Costa Rican Colón (CRC)', value: 'CRC'},
  {label: 'Croatian Kuna (HRK)', value: 'HRK'},
  {label: 'Cuban Peso (CUP)', value: 'CUP'},
  {label: 'Czech Koruna (CZK)', value: 'CZK'},
  {label: 'Danish Krone (DKK)', value: 'DKK'},
  {label: 'Djiboutian Franc (DJF)', value: 'DJF'},
  {label: 'Dominican Peso (DOP)', value: 'DOP'},
  {label: 'East Caribbean Dollar (XCD)', value: 'XCD'},
  {label: 'Egyptian Pound (EGP)', value: 'EGP'},
  {label: 'Eritrean Nakfa (ERN)', value: 'ERN'},
  {label: 'Ethiopian Birr (ETB)', value: 'ETB'},
  {label: 'Fijian Dollar (FJD)', value: 'FJD'},
  {label: 'Gambian Dalasi (GMD)', value: 'GMD'},
  {label: 'Georgian Lari (GEL)', value: 'GEL'},
  {label: 'Ghanaian Cedi (GHS)', value: 'GHS'},
  {label: 'Gibraltar Pound (GIP)', value: 'GIP'},
  {label: 'Guatemalan Quetzal (GTQ)', value: 'GTQ'},
  {label: 'Guinean Franc (GNF)', value: 'GNF'},
  {label: 'Guyanese Dollar (GYD)', value: 'GYD'},
  {label: 'Haitian Gourde (HTG)', value: 'HTG'},
  {label: 'Honduran Lempira (HNL)', value: 'HNL'},
  {label: 'Hong Kong Dollar (HKD)', value: 'HKD'},
  {label: 'Hungarian Forint (HUF)', value: 'HUF'},
  {label: 'Icelandic Króna (ISK)', value: 'ISK'},
  {label: 'Indian Rupee (INR)', value: 'INR'},
  {label: 'Indonesian Rupiah (IDR)', value: 'IDR'},
  {label: 'Iranian Rial (IRR)', value: 'IRR'},
  {label: 'Iraqi Dinar (IQD)', value: 'IQD'},
  {label: 'Israeli New Shekel (ILS)', value: 'ILS'},
  {label: 'Jamaican Dollar (JMD)', value: 'JMD'},
  {label: 'Japanese Yen (JPY)', value: 'JPY'},
  {label: 'Jordanian Dinar (JOD)', value: 'JOD'},
  {label: 'Kazakhstani Tenge (KZT)', value: 'KZT'},
  {label: 'Kenyan Shilling (KES)', value: 'KES'},
  {label: 'Kuwaiti Dinar (KWD)', value: 'KWD'},
  {label: 'Kyrgyzstani Som (KGS)', value: 'KGS'},
  {label: 'Lao Kip (LAK)', value: 'LAK'},
  {label: 'Lebanese Pound (LBP)', value: 'LBP'},
  {label: 'Lesotho Loti (LSL)', value: 'LSL'},
  {label: 'Liberian Dollar (LRD)', value: 'LRD'},
  {label: 'Libyan Dinar (LYD)', value: 'LYD'},
  {label: 'Macanese Pataca (MOP)', value: 'MOP'},
  {label: 'Macedonian Denar (MKD)', value: 'MKD'},
  {label: 'Malagasy Ariary (MGA)', value: 'MGA'},
  {label: 'Malawian Kwacha (MWK)', value: 'MWK'},
  {label: 'Malaysian Ringgit (MYR)', value: 'MYR'},
  {label: 'Maldivian Rufiyaa (MVR)', value: 'MVR'},
  {label: 'Mauritanian Ouguiya (MRU)', value: 'MRU'},
  {label: 'Mauritian Rupee (MUR)', value: 'MUR'},
  {label: 'Mexican Peso (MXN)', value: 'MXN'},
  {label: 'Moldovan Leu (MDL)', value: 'MDL'},
  {label: 'Mongolian Tögrög (MNT)', value: 'MNT'},
  {label: 'Moroccan Dirham (MAD)', value: 'MAD'},
  {label: 'Mozambican Metical (MZN)', value: 'MZN'},
  {label: 'Myanmar Kyat (MMK)', value: 'MMK'},
  {label: 'Namibian Dollar (NAD)', value: 'NAD'},
  {label: 'Nepalese Rupee (NPR)', value: 'NPR'},
  {label: 'Netherlands Antillean Guilder (ANG)', value: 'ANG'},
  {label: 'New Zealand Dollar (NZD)', value: 'NZD'},
  {label: 'Nicaraguan Córdoba (NIO)', value: 'NIO'},
  {label: 'Nigerian Naira (NGN)', value: 'NGN'},
  {label: 'North Korean Won (KPW)', value: 'KPW'},
  {label: 'Norwegian Krone (NOK)', value: 'NOK'},
  {label: 'Omani Rial (OMR)', value: 'OMR'},
  {label: 'Pakistani Rupee (PKR)', value: 'PKR'},
  {label: 'Panamanian Balboa (PAB)', value: 'PAB'},
  {label: 'Papua New Guinean Kina (PGK)', value: 'PGK'},
  {label: 'Paraguayan Guaraní (PYG)', value: 'PYG'},
  {label: 'Peruvian Sol (PEN)', value: 'PEN'},
  {label: 'Philippine Peso (PHP)', value: 'PHP'},
  {label: 'Polish Złoty (PLN)', value: 'PLN'},
  {label: 'Qatari Riyal (QAR)', value: 'QAR'},
  {label: 'Romanian Leu (RON)', value: 'RON'},
  {label: 'Russian Ruble (RUB)', value: 'RUB'},
  {label: 'Rwandan Franc (RWF)', value: 'RWF'},
  {label: 'Saint Helena Pound (SHP)', value: 'SHP'},
  {label: 'Samoan Tālā (WST)', value: 'WST'},
  {label: 'São Tomé and Príncipe Dobra (STN)', value: 'STN'},
  {label: 'Saudi Riyal (SAR)', value: 'SAR'},
  {label: 'Serbian Dinar (RSD)', value: 'RSD'},
  {label: 'Seychellois Rupee (SCR)', value: 'SCR'},
  {label: 'Sierra Leonean Leone (SLL)', value: 'SLL'},
  {label: 'Singapore Dollar (SGD)', value: 'SGD'},
  {label: 'Solomon Islands Dollar (SBD)', value: 'SBD'},
  {label: 'Somali Shilling (SOS)', value: 'SOS'},
  {label: 'South African Rand (ZAR)', value: 'ZAR'},
  {label: 'South Korean Won (KRW)', value: 'KRW'},
  {label: 'South Sudanese Pound (SSP)', value: 'SSP'},
  {label: 'Sri Lankan Rupee (LKR)', value: 'LKR'},
  {label: 'Sudanese Pound (SDG)', value: 'SDG'},
  {label: 'Surinamese Dollar (SRD)', value: 'SRD'},
  {label: 'Swazi Lilangeni (SZL)', value: 'SZL'},
  {label: 'Swedish Krona (SEK)', value: 'SEK'},
  {label: 'Swiss Franc (CHF)', value: 'CHF'},
  {label: 'Syrian Pound (SYP)', value: 'SYP'},
  {label: 'Tajikistani Somoni (TJS)', value: 'TJS'},
  {label: 'Tanzanian Shilling (TZS)', value: 'TZS'},
  {label: 'Thai Baht (THB)', value: 'THB'},
  {label: 'Tongan Paʻanga (TOP)', value: 'TOP'},
  {label: 'Trinidad and Tobago Dollar (TTD)', value: 'TTD'},
  {label: 'Tunisian Dinar (TND)', value: 'TND'},
  {label: 'Turkish Lira (TRY)', value: 'TRY'},
  {label: 'Turkmenistani Manat (TMT)', value: 'TMT'},
  {label: 'Ugandan Shilling (UGX)', value: 'UGX'},
  {label: 'Ukrainian Hryvnia (UAH)', value: 'UAH'},
  {label: 'United Arab Emirates Dirham (AED)', value: 'AED'},
  {label: 'United States Dollar (USD)', value: 'USD'},
  {label: 'Uruguayan Peso (UYU)', value: 'UYU'},
  {label: 'Uzbekistani Som (UZS)', value: 'UZS'},
  {label: 'Vanuatu Vatu (VUV)', value: 'VUV'},
  {label: 'Venezuelan Bolívar Soberano (VES)', value: 'VES'},
  {label: 'Vietnamese Đồng (VND)', value: 'VND'},
  {label: 'Yemeni Rial (YER)', value: 'YER'},
  {label: 'Zambian Kwacha (ZMW)', value: 'ZMW'},
  {label: 'Zimbabwean Dollar (ZWL)', value: 'ZWL'}    
];

const moment = require("moment");

export const itemsPerPage = 10;

export const dateFormat = (date) => {
  const formattedDate = moment(date).format("DD MMMM YYYY");
  return formattedDate;
};
export const formatDate = (date) => {
  const formattedDate = moment(date).format("D MMMM YYYY, hh:mm A");
  return formattedDate;
};


export const formatStandardNumberWithMinNMaxDecimal = (
  number = 0,
  minDigits = 2,
  maxDigits = 2,
) => {
  return number.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
};


export const formatStandardNumberWithMaxDecimal = (
  number = 0,
  maxdigits = 2,
) => {
  return number.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: maxdigits,
  });
};





export const handleDecimal = (number, decimalPlaces = 2) => {
  // Check if the input is not a number
  if (isNaN(number)) return '0';

  // Convert the input to a number and fix it to the specified decimal places
  const fixedNumber = Number(number).toFixed(decimalPlaces);

  // Remove unnecessary trailing zeros and return
  return parseFloat(fixedNumber).toString();
};

export const handleDecimalToViewOnly = (number, decimalPlaces = 2) => {
  // Check if the input is not a number
  if (isNaN(number)) return '0.00';

  // Convert the input to a number and fix it to the specified decimal places
  const fixedNumber = Number(number).toFixed(decimalPlaces);

  // Return the number with specified decimal places (retaining trailing zeros)
  return fixedNumber;
};

export const isDecimal = num => {
  const number = Number(num);
  // Check if it's a number and if its floating point representation is different from its integer part
  return !isNaN(number) && number % 1 !== 0;
};



export const quantityToSpecificDecimal = (value = 0, decimals) => {
  {
    
    let valToSend = value ?? 0;
    // Convert value to a number if it's a string
    let numericValue = parseFloat(valToSend);

    // Calculate the truncation factor (e.g., for 5 decimals, this is 10^5 = 100000)
    let factor = Math.pow(10, decimals);

    // Truncate the number by multiplying, flooring, and dividing again
    let truncatedValue = Math.floor(numericValue * factor) / factor;

    return truncatedValue;
  }
};



import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

// css
import styles from "./stockDetailPop.module.scss";

// img
import s1 from "../../../Assets/images/appleStock.png";

const StockDetailPop = ({ stockDetail, setStockDetail }) => {
  const handleSDetail = () => setStockDetail(!stockDetail);

  useEffect(() => {
    setTimeout(() => {
      setStockDetail(null);
    }, 3000);
  }, [stockDetail]);

  console.log(stockDetail , 'open')

  return (
    <>
      <Modal
        show={stockDetail}
        className={`${styles.StockDetailPop}`}
        onHide={handleSDetail}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Button
            onClick={handleSDetail}
            className="border-0 p-0 position-absolute"
            style={{ right: -10, top: -10 }}
            variant="transparent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <circle cx="15" cy="15" r="15" fill="white" />
              <path
                d="M10.2 21L9 19.8L13.8 15L9 10.2L10.2 9L15 13.8L19.8 9L21 10.2L16.2 15L21 19.8L19.8 21L15 16.2L10.2 21Z"
                fill="black"
              />
            </svg>
          </Button>
          <div className="content text-center py-5">
            <div className="imgWrp mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 140 140"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 70C0 51.4348 7.37498 33.6301 20.5025 20.5025C33.6301 7.37498 51.4348 0 70 0C88.5652 0 106.37 7.37498 119.497 20.5025C132.625 33.6301 140 51.4348 140 70C140 88.5652 132.625 106.37 119.497 119.497C106.37 132.625 88.5652 140 70 140C51.4348 140 33.6301 132.625 20.5025 119.497C7.37498 106.37 0 88.5652 0 70H0ZM66.0053 99.96L106.307 49.5787L99.0267 43.7547L64.6613 86.6973L40.32 66.416L34.3467 73.584L66.0053 99.9693V99.96Z"
                  fill="#CF0A10"
                />
              </svg>
            </div>
            <div className="py-2 mt-2">
              <p className="text-white m-0">Your Order is Placed Successful</p>
              <h5 className="m-0 text-white fw-bold">
                Order no. {stockDetail?.client_order_id}
              </h5>
              <p className="m-0 py-2 text-muted">
                “Thanks for placed an order you want to buy more!”
              </p>
            </div>
            <div className={`${styles.btnWrpper} text-center btnWrpper mt-3`}>
              <Button className="d-flex align-items-center w-100 mx-auto justify-content-center rounded fw-sbold commonBtn">
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StockDetailPop;

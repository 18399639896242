import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

// css
import styles from "./WalletPage.module.scss";

// img
import user from "../../../../Assets/images/user.png";
import WalletSidebar from "../../../../components/WalletSidebar";

const WalletPage = () => {
  const data = [
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "complete",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "canceled",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "finished",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "warning",
    },
  ];
  const [wSidebar, setWsidebar] = useState("");
  const [type, setType] = useState("");
  const handleWSidebar = (type) => {
    setType(type);
    setWsidebar(!wSidebar);
  };
  return (
    <>
      <section
        className={`${styles.walletPage} walletPage py-2 position-relative`}
      >
        <WalletSidebar
          wSidebar={wSidebar}
          setWsidebar={setWsidebar}
          type={type}
        />
        <Container>
          <Row>
            <Col lg="6" className="my-2">
              <div className="py-2">
                <div className="px-3 position-relative d-flex align-items-center justify-content-between pb-3">
                  <h5 className="m-0 text-white">Market Close P&L</h5>
                  <h4 className="btn rounded text-white border-white">
                    + $22%
                  </h4>
                </div>
                <div className="box cardCstm p-2 px-3  pb-3 position-relative">
                  <div className="top py-2 d-flex align-items-start justify-content-between pb-3">
                    <div className="left">
                      <h5 className="m-0 fw-sbold text-white">Total Cash</h5>
                      <p className="text-muted m-0">All Segments</p>
                    </div>
                    <div className="right">
                      <h5 className="m-0 fw-sbold text-white">$0.00</h5>
                    </div>
                  </div>
                  <div className="contentBody">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                        <p className="text-white m-0">Equity</p>
                        <p className="text-white fw-bold m-0">$0.00 {`>`}</p>
                      </li>
                      <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                        <p className="text-white m-0">Cash Used</p>
                        <p className="text-white fw-bold m-0">$0.00 {`>`}</p>
                      </li>
                      <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                        <p className="text-white m-0">Unavailable to trade</p>
                        <p className="text-white fw-bold m-0">$0.00 {`>`}</p>
                      </li>
                    </ul>
                    <div
                      className={`${styles.formBtnWrpper} btnWrpper formBtnWrpper d-flex align-items-center justify-content-center gap-10 mt-5`}
                    >
                      <Button
                        onClick={() => handleWSidebar("deposit")}
                        className="d-flex align-items-center justify-content-center w-100 rounded commonBtn"
                      >
                        Deposit
                      </Button>
                      <Button
                        onClick={() => handleWSidebar("withdraw")}
                        className="d-flex align-items-center justify-content-center rounded w-100 commonBtn whiteBordered"
                      >
                        Withdraw
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="box cardCstm px-3  py-3 position-relative">
                  <p className="text-muted m-0">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="6" className="my-2">
              <div className="py-2">
                <div className="px-3 position-relative pb-3">
                  <h5 className="m-0 text-white">Transaction History</h5>
                </div>
                <div className="box cardCstm px-3  py-3 position-relative">
                  <div className="table-responsive commonTable">
                    <table className="table m-0">
                      <thead>
                        <tr>
                          <th className="border-0 bg-transparent fw-normal">
                            Name
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Types
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Amount
                          </th>
                          <th className="border-0 bg-transparent fw-normal">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.length > 0 &&
                          data.map((item, key) => (
                            <tr>
                              <td className="border-0 py-2">
                                <div className="d-flex align-items-center gap-10">
                                  <div className="imgWrp">
                                    <img
                                      src={user}
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                  <div className="content">
                                    <p className="text-white m-0 fw-bold">
                                      {item.name}
                                    </p>
                                    <p className="text-muted m-0">
                                      {item.date}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className="border-0 py-2">
                                <p className="text-muted m-0">{item.types}</p>
                              </td>
                              <td className="border-0 py-2">
                                <p className="text-muted m-0">{item.amount}</p>
                              </td>
                              <td className="border-0 py-2">
                                <p
                                  className={`${
                                    item.status == "complete" && "greenLabel"
                                  } ${
                                    item.status == "warning" && "yellowLabel"
                                  } ${
                                    item.status == "finished" && "blueLabel"
                                  }  ${
                                    item.status == "canceled" && "redLabel"
                                  } px-2 d-inline-block text-capitalize rounded text-center labelTag  m-0`}
                                >
                                  {item.status}
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="box cardCstm px-3  py-3 position-relative">
                  <div className="d-flex align-items-start justify-content-between gap-10">
                    <div className="left">
                      <h6 className="m-0 text-white fw-bold">
                        Available to Withdraw
                      </h6>
                      <p className="text-muted m-0">All Segments</p>
                    </div>
                    <div className="right">
                      <h4 className="m-0 fw-bold text-white">$0.00</h4>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WalletPage;

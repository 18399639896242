import React, { useEffect } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
// css
import styles from "./Header.module.scss";
import { useSelector, useDispatch } from "react-redux";

// img
import logo from "../../Assets/images/logo.png";
import user from "../../Assets/images/user.png";
import { getNotification, getProfile } from "store/actions";
import { useTheme } from "Contexts/ThemeContexts";
import { formatDate } from "helpers/helper";
import Search from "./Search";
import p1 from "../../Assets/images/logo-placeholder.png";

const Header = ({ sidebar, setSidebar }) => {
  const { userProfile } = useSelector((state) => state?.Profile);
  const navigate = useNavigate();

  const { isDarkMode } = useTheme();

  const location = useLocation();
  const pageName = location.pathname;
  let lastSlashIndex = pageName.lastIndexOf("/");
  const { notificationList, loading } = useSelector((state) => state?.Basic);

  let heading;
  if (lastSlashIndex !== -1) {
    const afterLastSlash = pageName.substring(lastSlashIndex + 1);
    heading = afterLastSlash;
  }
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };


  const dispatch = useDispatch();

  useEffect(() => {
    let getToken = localStorage.getItem("token");
    if (getToken) {
      dispatch(getNotification());
      dispatch(getProfile());
    }
  }, []);

  return (
    <>
      <header
        className={`${styles.siteHeader}  siteHeader sticky-top py-lg-3 py-1 w-100`}
        style={{ zIndex: 99, background: "var(--backgroundColor)" }}
      >
        <Container>
          <Navbar expand="lg" className="bg-body-tertiary">
            <div className="d-flex align-items-center">
              <Button
                onClick={handleSidebar}
                className="d-lg-none border-0 p-0 currentColor"
                variant="transparent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3.75 10.5C3.55109 10.5 3.36032 10.4209 3.21967 10.2803C3.07902 10.1396 3 9.94887 3 9.74996V3.75146C3 3.55255 3.07902 3.36179 3.21967 3.22113C3.36032 3.08048 3.55109 3.00146 3.75 3.00146H9.75C9.94891 3.00146 10.1397 3.08048 10.2803 3.22113C10.421 3.36179 10.5 3.55255 10.5 3.75146V9.74996C10.5 9.94887 10.421 10.1396 10.2803 10.2803C10.1397 10.4209 9.94891 10.5 9.75 10.5H3.75ZM14.25 10.5C14.0511 10.5 13.8603 10.4209 13.7197 10.2803C13.579 10.1396 13.5 9.94887 13.5 9.74996V3.75146C13.5 3.55255 13.579 3.36179 13.7197 3.22113C13.8603 3.08048 14.0511 3.00146 14.25 3.00146H20.2485C20.4474 3.00146 20.6382 3.08048 20.7788 3.22113C20.9195 3.36179 20.9985 3.55255 20.9985 3.75146V9.74996C20.9985 9.94887 20.9195 10.1396 20.7788 10.2803C20.6382 10.4209 20.4474 10.5 20.2485 10.5H14.25ZM3.75 21C3.55109 21 3.36032 20.9209 3.21967 20.7803C3.07902 20.6396 3 20.4489 3 20.25V14.25C3 14.051 3.07902 13.8603 3.21967 13.7196C3.36032 13.579 3.55109 13.5 3.75 13.5H9.75C9.94891 13.5 10.1397 13.579 10.2803 13.7196C10.421 13.8603 10.5 14.051 10.5 14.25V20.25C10.5 20.4489 10.421 20.6396 10.2803 20.7803C10.1397 20.9209 9.94891 21 9.75 21H3.75ZM14.25 21C14.0511 21 13.8603 20.9209 13.7197 20.7803C13.579 20.6396 13.5 20.4489 13.5 20.25V14.25C13.5 14.051 13.579 13.8603 13.7197 13.7196C13.8603 13.579 14.0511 13.5 14.25 13.5H20.2485C20.4474 13.5 20.6382 13.579 20.7788 13.7196C20.9195 13.8603 20.9985 14.051 20.9985 14.25V20.25C20.9985 20.4489 20.9195 20.6396 20.7788 20.7803C20.6382 20.9209 20.4474 21 20.2485 21H14.25Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
              <Navbar.Brand className={`${styles.logo} d-lg-none`} href="#">
                <img src={logo} alt="" className={`img-fluid`} />
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Search />
              <Nav
                className="ms-auto my-2 my-lg-0 align-items-center gap-10"
                navbarScroll
              >
                {/* <Nav.Link
                  className="rounded-circle p-0 d-flex align-items-center justify-content-center darkLightRed"
                  style={{
                    height: 40,
                    width: 40,
                    border: "1px solid var(--darkLightGray)",
                  }}
                  href="#action1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                  >
                    <mask
                      id="mask0_1_1382"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="19"
                      height="18"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 0H18.4969V17.348H0V0Z"
                        fill="currentColor"
                      />
                    </mask>
                    <g mask="url(#mask0_1_1382)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.24609 1.5C5.75109 1.5 3.31509 4.238 3.31509 6.695C3.31509 8.774 2.73809 9.735 2.22809 10.583C1.81909 11.264 1.49609 11.802 1.49609 12.971C1.66309 14.857 2.90809 15.848 9.24609 15.848C15.5491 15.848 16.8331 14.813 16.9991 12.906C16.9961 11.802 16.6731 11.264 16.2641 10.583C15.7541 9.735 15.1771 8.774 15.1771 6.695C15.1771 4.238 12.7411 1.5 9.24609 1.5ZM9.24701 17.348C4.57101 17.348 0.345013 17.018 1.33547e-05 13.035C-0.00298665 11.387 0.500013 10.549 0.944013 9.811C1.39301 9.063 1.81601 8.358 1.81601 6.695C1.81601 3.462 4.80201 0 9.24701 0C13.692 0 16.678 3.462 16.678 6.695C16.678 8.358 17.101 9.063 17.55 9.811C17.994 10.549 18.497 11.387 18.497 12.971C18.148 17.018 13.923 17.348 9.24701 17.348Z"
                        fill="currentColor"
                      />
                    </g>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.199 21.4996H9.197C8.076 21.4986 7.015 21.0046 6.21 20.1076C5.933 19.8006 5.958 19.3256 6.266 19.0496C6.574 18.7716 7.048 18.7966 7.325 19.1056C7.843 19.6826 8.508 19.9996 9.198 19.9996H9.199C9.892 19.9996 10.56 19.6826 11.079 19.1046C11.357 18.7976 11.831 18.7726 12.138 19.0496C12.446 19.3266 12.471 19.8016 12.194 20.1086C11.386 21.0056 10.323 21.4996 9.199 21.4996Z"
                      fill="currentColor"
                    />
                  </svg>
                </Nav.Link> */}

                <Dropdown
                  className={`${styles.notificationDropdown} noAfter px-2`}
                >
                  {/* <div
                    className="position-fixed h-100 w-100 bg-dark d-none"
                    style={{ left: 0, top: 0, opacity: 0.5 }}
                  ></div> */}
                  <Dropdown.Toggle
                    className={`border-0 p-0`}
                    variant="transparent"
                    id="dropdown-basic"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="24"
                      viewBox="0 0 29 38"
                      fill="none"
                    >
                      <path
                        d="M26.2695 23.8668V16.6152C26.2695 10.9429 22.3648 6.16588 17.1023 4.82965C17.3262 4.38129 17.4529 3.87616 17.4529 3.34185C17.4531 1.49915 15.9539 0 14.1113 0C12.2687 0 10.7695 1.49915 10.7695 3.34178C10.7695 3.87608 10.8963 4.38122 11.1202 4.82957C5.8578 6.16581 1.95307 10.9429 1.95307 16.6151V23.8667C1.95307 26.6651 1.47517 29.4199 0.532446 32.0547L0 33.5431H8.65303C9.17026 36.0829 11.4211 38 14.1112 38C16.8014 38 19.0522 36.0829 19.5694 33.5431H28.2225L27.6901 32.0548C26.7474 29.42 26.2695 26.6651 26.2695 23.8668ZM14.1113 2.22664C14.7261 2.22664 15.2264 2.72688 15.2264 3.34178C15.2264 3.95661 14.7262 4.45684 14.1113 4.45684C13.4965 4.45684 12.9962 3.95676 12.9962 3.34178C12.9962 2.72695 13.4964 2.22664 14.1113 2.22664ZM14.1113 35.7734C12.658 35.7734 11.4188 34.841 10.9591 33.5432H17.2636C16.8037 34.841 15.5647 35.7734 14.1113 35.7734ZM3.11289 31.3165C3.82138 28.8976 4.17978 26.3985 4.17978 23.8668V16.6152C4.17978 11.1389 8.63507 6.68356 14.1114 6.68356C19.5877 6.68356 24.043 11.1388 24.043 16.6152V23.8668C24.043 26.3985 24.4013 28.8976 25.1099 31.3165H3.11289Z"
                        fill="#909090"
                      />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    className={`${styles.menu} ${
                      isDarkMode && styles.darkMode
                    } rounded pt-0 shadow-sm border-0`}
                  >
                    <div
                      className={`${styles.top} top py-3 px-3 d-flex justify-content-between align-items-center mb-2`}
                    >
                      <h4 className="m-0 fw-sbold">
                        Notifications{" "}
                        {/* <span
                          className={`${styles.count} text-white rounded-circle fw-normal d-inline-flex align-items-center justify-content-center`}
                        >
                          2
                        </span> */}
                      </h4>
                    </div>
                    <div
                      className="ContentBody"
                      style={{
                        height: "60vh",
                      }}
                    >
                      <ul
                        className={`${styles.notificationList} list-unstyled ps-0 mb-0 overflow-scroll overflow-hidden`}
                        style={{
                          height: "60vh",
                        }}
                      >
                        {!!notificationList?.data?.length &&
                          notificationList?.data?.map((list, i) => {
                            return (
                              <li className="py-3 d-flex align-items-start gap-10 px-3">
                                <div className="icn me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.5 27.5C12.948 28.942 14.338 30 16 30C17.663 30 19.052 28.942 19.5 27.5H12.5Z"
                                      fill="#FF822D"
                                    />
                                    <path
                                      d="M16 6.55C15.31 6.55 14.75 5.99 14.75 5.3V3.25C14.75 2.91848 14.8817 2.60054 15.1161 2.36612C15.3505 2.1317 15.6685 2 16 2C16.3315 2 16.6495 2.1317 16.8839 2.36612C17.1183 2.60054 17.25 2.91848 17.25 3.25V5.3C17.25 5.99 16.69 6.55 16 6.55Z"
                                      fill="#FFB02E"
                                    />
                                    <path
                                      d="M27.6 22.843C26.64 22.07 26.06 20.916 25.82 19.693L24.09 10.793C23.32 6.85 19.94 4.01 16 4C12.06 4.01 8.68002 6.85 7.91002 10.793L6.18002 19.693C5.94002 20.916 5.36002 22.07 4.40002 22.843C3.96116 23.1989 3.60761 23.6487 3.36534 24.1592C3.12307 24.6697 2.99824 25.2279 3.00002 25.793V27.027C3.00002 27.569 3.43002 28 3.95002 28H28.05C28.58 28 29 27.569 29 27.027V25.793C29 24.589 28.45 23.535 27.6 22.843Z"
                                      fill="#F9C23C"
                                    />
                                  </svg>
                                </div>
                                <div className="content">
                                  <h6 className="fw-bold text-dark m-0">
                                    {list?.title}
                                  </h6>
                                  <p className=" text-muted m-0">
                                    {list?.message}
                                  </p>

                                  <p className="m-0 text-muted">
                                    {formatDate(list?.createdAt)}
                                  </p>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className={`${styles.ProfileDropdown}`}>
                  <Dropdown.Toggle
                    variant="transparent"
                    className="border-0 p-0 d-flex align-items-center gap-10 currentColor fw-bold"
                    id="dropdown-basic"
                  >
                    <span className="ImgWrp">
                      <img
                        src={
                          userProfile?.profileImage
                            ? userProfile?.profileImage
                            : p1
                        }
                        alt=""
                        className="img-fluid rounded-circle mr-2 img-profile"
                      />
                    </span>
                    {userProfile?.first_name
                      ? userProfile?.first_name
                      : userProfile?.name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={`${styles.Menu} px-2 text-center`}>
                    <Button
                      onClick={() => navigate("/logout")}
                      className="rounded-pill bg-transparent d-inline-flex border-secondary align-items-center justify-content-center currentColor"
                      style={{
                        color: isDarkMode ?  "white" : "black",
                      }}
                    >
                      <span className="icn me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M2.11383 11.9926L6.5973 11.9926C6.75616 11.9926 6.90851 11.9295 7.02085 11.8171C7.13318 11.7048 7.19629 11.5524 7.19629 11.3936C7.19629 11.2347 7.13318 11.0824 7.02085 10.97C6.90851 10.8577 6.75616 10.7946 6.5973 10.7946L2.11383 10.7946C1.60887 10.7946 1.19796 10.3855 1.19796 9.88231L1.19797 2.11096C1.19797 1.60781 1.60887 1.19809 2.11383 1.19809L6.5973 1.1981C6.75616 1.1981 6.90852 1.13499 7.02085 1.02265C7.13318 0.91032 7.19629 0.757964 7.19629 0.599101C7.19629 0.440238 7.13318 0.287882 7.02085 0.175549C6.90852 0.0632152 6.75616 0.000107727 6.5973 0.000107713L2.11383 0.000107321C1.55377 0.000264628 1.01665 0.222614 0.620343 0.618355C0.22404 1.0141 0.000929315 1.5509 -2.25011e-05 2.11096L-2.31805e-05 9.88231C0.000928538 10.4424 0.224039 10.9792 0.620342 11.3749C1.01664 11.7707 1.55376 11.993 2.11383 11.9932L2.11383 11.9926Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.0251 5.39644L4.22266 5.39644C4.0638 5.39644 3.91144 5.45955 3.79911 5.57188C3.68678 5.68421 3.62367 5.83657 3.62367 5.99543C3.62367 6.1543 3.68678 6.30665 3.79911 6.41899C3.91144 6.53132 4.0638 6.59443 4.22266 6.59443L10.0131 6.59443L8.17902 8.55314C8.12287 8.61006 8.07869 8.67765 8.04908 8.75192C8.01947 8.82619 8.00503 8.90563 8.0066 8.98557C8.00818 9.06551 8.02574 9.14432 8.05826 9.21737C8.09078 9.29041 8.13759 9.3562 8.19593 9.41087C8.25428 9.46554 8.32298 9.50797 8.39798 9.53567C8.47299 9.56336 8.55277 9.57576 8.63265 9.57214C8.71252 9.56851 8.79085 9.54893 8.86304 9.51455C8.93522 9.48017 8.9998 9.43169 9.05295 9.37196L11.8077 6.4303C11.8734 6.36887 11.9242 6.29328 11.9563 6.20928C11.9718 6.17034 11.9742 6.12901 11.9808 6.08828C11.9862 6.05653 12 6.02838 12 5.99543L11.9988 5.99184C12.0002 5.83848 11.9425 5.69047 11.8377 5.57854L9.05235 2.60513C8.99879 2.54754 8.93437 2.5011 8.8628 2.4685C8.79123 2.43589 8.71392 2.41776 8.63532 2.41514C8.55671 2.41252 8.47837 2.42548 8.40479 2.45325C8.3312 2.48102 8.26384 2.52307 8.20657 2.57698C8.14912 2.63077 8.10284 2.69535 8.07036 2.76703C8.03788 2.83872 8.01984 2.9161 8.01729 2.99476C8.01473 3.07341 8.02769 3.1518 8.05544 3.22545C8.08319 3.29909 8.12519 3.36655 8.17902 3.42395L10.0251 5.39644Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      Log Out
                    </Button>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    </>
  );
};

export default Header;

import React, {useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";
import logo from "../../../../Assets/images/logo.png";
// actions
import {  getCMS } from "store/actions"
import { useSelector, useDispatch } from "react-redux"

// css
import styles from "../../../../layout/Auth/Auth.module.scss";

const TermsCondition = ({ step, setStep }) => {
  const optionsCountry = [{ value: "country", label: "Country" }];
  const optionsLA = [{ value: "liquid_asset", label: "Liquid Asset" }];
  const optionsOM = [{ value: "origin_of_money", label: "Origin of Money" }];
  const navigate = useNavigate();
  const [pass, setPass] = useState();
  const handlePass = () => {
    setPass(!pass);
  };
  const dispatch = useDispatch()
  const { content } = useSelector(state => state.CMS)

  useEffect(() => {
      dispatch(getCMS())
  }, [])

  return (
    <>
      {/* <Col md="9" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
          style={{ background: "#010101" }}
        >
          <div className="formInner">
            <div
              className={`${styles.top} top pt-2 mt-3 text-center  position-relative`}
            >
              <h5 className="m-0 pb-2 fw-sbold head text-white">
                Accept Terms And Conditions
              </h5>
            </div>
            <Form className="mx-auto" style={{ maxWidth: 350 }}>
              <Row className="justify-content-between">
                <Col lg="12" className="my-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    <li className="py-1 d-flex align-items-start">
                      <input
                        type="checkbox"
                        className="form-check me-2"
                        style={{ flexShrink: 0 }}
                      />
                      <p className="text-white m-0">
                        I am not affiliated or work with a US registered
                        broker-dealer or FINRA.
                      </p>
                    </li>
                    <li className="py-1 d-flex align-items-start">
                      <input
                        type="checkbox"
                        className="form-check me-2"
                        style={{ flexShrink: 0 }}
                      />
                      <p className="text-white">
                        I am not Senior executive at or a 10% or greater
                        shareholder of a publicly traded company.
                      </p>
                    </li>
                    <li className="py-1 d-flex align-items-start">
                      <input
                        type="checkbox"
                        className="form-check me-2"
                        style={{ flexShrink: 0 }}
                      />
                      <p className="text-white">
                        I am not a senior political figure.
                      </p>
                    </li>
                    <li className="py-1 d-flex align-items-start">
                      <input
                        type="checkbox"
                        className="form-check me-2"
                        style={{ flexShrink: 0 }}
                      />
                      <p className="text-white">
                        I am not a family member or relative of a senior
                        political figure.
                      </p>
                    </li>
                    <li className="py-1 d-flex align-items-start">
                      <input
                        type="checkbox"
                        className="form-check me-2"
                        style={{ flexShrink: 0 }}
                      />
                      <p className="text-white">
                        None of the above apply to me or my family.
                      </p>
                    </li>
                  </ul>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper mt-3`}>
                    <Button
                      onClick={() => navigate("/home")}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col> */}
      <div className="cms-content" dangerouslySetInnerHTML={{ __html: content }} />

    </>
  );
};

export default TermsCondition;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
const ChooseYourMethod = ({ setStep }) => {
  const path = window.location.href;

  const arr = path.split("/");
  const type = arr[arr.length - 1];
  return (
    <section className="Withdrawal position-relative py-3">
      <Container>
        <Row>
          <Col lg="12" className="my-2">
            <div className="pageHead mb-2">
              <h5 className="m-0 fw-sbold ">
                {" "}
                {type === "deposit" ? "Deposit" : "Withdrawal"} Money
              </h5>
            </div>
          </Col>
          <Col lg="12" className="my-2">
            <div className="box cursor-pointer" onClick={() => setStep(2)}>
              <div
                className="top px-3 py-2"
                style={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  background: "var(--ThemeDarkGray)",
                }}
              >
                <p className="m-0 fw-sbold darkLightRed">
                  Choose your Withdawal method
                </p>
              </div>
              <div className="contentBody px-3 py-3">
                <div className="d-flex align-items-start gap-10">
                  <div className="icnWrp flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <circle
                        cx="20"
                        cy="20"
                        r="19"
                        stroke="#CF0A10"
                        stroke-width="2"
                      />
                      <path
                        d="M21.2043 10.1672L30.1293 15.1828C30.6629 15.4511 31 15.9995 31 16.5995V18.121C31 18.85 30.4124 19.441 29.6875 19.441H28.9V27.8882H29.95C30.5299 27.8882 31 28.3609 31 28.9441C31 29.5273 30.5299 30 29.95 30H11.05C10.4701 30 10 29.5273 10 28.9441C10 28.3609 10.4701 27.8882 11.05 27.8882H12.1V19.441H11.3125C10.5876 19.441 10 18.85 10 18.121V16.5995C10 16.0495 10.2832 15.5429 10.7414 15.2556L19.7957 10.1672C20.2391 9.94426 20.7609 9.94426 21.2043 10.1672ZM26.8 19.441H14.2V27.8882H17.35V21.5527H19.45V27.8882H21.55V21.5527H23.65V27.8882H26.8V19.441ZM20.5 12.1741L12.1 16.9258V17.3291H28.9V16.9258L20.5 12.1741ZM20.5 14.1613C21.0799 14.1613 21.55 14.634 21.55 15.2172C21.55 15.8003 21.0799 16.2731 20.5 16.2731C19.9201 16.2731 19.45 15.8003 19.45 15.2172C19.45 14.634 19.9201 14.1613 20.5 14.1613Z"
                        fill="#CF0A10"
                      />
                    </svg>
                  </div>
                  <div className="content">
                    <p className="m-0  fw-sbold">Bank Transfer</p>
                    <p className="m-0  text-muted">
                      A bank transfer is a direct transaction in which money is
                      moved electronically from one bank account to another,
                      often used for bill payments or transfers between
                      individuals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseYourMethod;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";
// css
import styles from "../../../layout/Auth/Auth.module.scss";

// img
import logo from "../../../Assets/images/logo.png";
import MoneyWork from "./Component/MoneyWork";
import AuthDocument from "./Component/AuthDocument";
import PhotoID from "./Component/PhotoId";
import ValidId from "./Component/ValidId";
import TermsCondition from "./Component/TermsCondition";

const CustomerProfile = () => {
  const [step, setStep] = useState(1);
  return (
    <>
      {step == 1 ? (
        <>
          <Col md="6" sm="10" className="my-2">
            <div className="logo mb-3 text-center">
              <Link to="" className="">
                <img
                  src={logo}
                  alt=""
                  className="img-fluid object-fit-contain"
                  style={{ height: 60 }}
                />
              </Link>
            </div>
            <div
              className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
            >
              <span
                className="position-absolute graphic"
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  overflow: "hidden",
                }}
              >
                <svg
                  className="w-100 h-100"
                  xmlns="http://www.w3.org/2000/svg"
                  width="702"
                  height="702"
                  viewBox="0 0 702 702"
                  fill="none"
                >
                  <g opacity="0.3" filter="url(#filter0_f_0_599)">
                    <circle cx="351" cy="351" r="117" fill="#CF0A10" />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_0_599"
                      x="0"
                      y="0"
                      width="702"
                      height="702"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="117"
                        result="effect1_foregroundBlur_0_599"
                      />
                    </filter>
                  </defs>
                </svg>
              </span>
              <div className="formInner">
                <div
                  className={`${styles.top} top py-2 text-center  position-relative`}
                >
                  <h3 className="m-0 pb-2 fw-sbold head text-white">
                    Customer Profile
                  </h3>
                  <p className="m-0 text-white">Please enter your details.</p>
                </div>
                <Form className="mx-auto py-3" style={{ maxWidth: 350 }}>
                  <Row>
                    <Col lg="12" className="my-2">
                      <input
                        type="text"
                        className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                        placeholder="Pin Code*"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <input
                        type="text"
                        className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                        placeholder="Nationality*"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <input
                        type="date"
                        className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                        placeholder="Date of Birth*"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <input
                        type="text"
                        className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                        placeholder="Country of Tax Residence*"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <input
                        type="text"
                        className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                        placeholder="Referral Token"
                      />
                    </Col>
                    <Col lg="12" className="my-2">
                      <div className={`${styles.btnWrpper} btnWrpper`}>
                        <Button
                          onClick={() => setStep(2)}
                          className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                        >
                          Next
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </>
      ) : step == 2 ? (
        <>
          <MoneyWork step={step} setStep={setStep} />
        </>
      ) : step == 3 ? (
        <>
          <AuthDocument step={step} setStep={setStep} />
        </>
      ) : step == 4 ? (
        <>
          <PhotoID step={step} setStep={setStep} />
        </>
      ) : step == 5 ? (
        <ValidId step={step} setStep={setStep} />
      ) : step == 6 ? (
        <>
          <TermsCondition />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomerProfile;

import React,{useEffect}from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
// actions
import {  getCMS } from "store/actions"

const PolicyDisclosure = ({}) => {
  const dispatch = useDispatch()
  const { userProfile } = useSelector(state => state.Profile)

  useEffect(() => {
      dispatch(getCMS())
  }, [])

  const handleDownload = (filename, file) => {
    const link = document.createElement('a');
    link.href = file;
    link.download = filename;
    link.click();
  }

  return (
    <>
      <div className="py-2">
        <ul className="list-unstyled ps-0 mb-0">
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">HFX Company Download</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('company_profile',userProfile?.documents?.company_profile)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Risk Disclosure</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('risk_disclosure', userProfile?.documents?.risk_disclosure)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Mandate Agreement</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('mandate_agreement', userProfile?.documents?.mandate_agreement)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Statement of Simulations</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('statement_simulations', userProfile?.documents?.statement_simulations)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Statement of Disclosure</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('statement_general_disclosure', userProfile?.documents?.statement_general_disclosure)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Statement of Experience</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('statement_experience', userProfile?.documents?.statement_experience)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Statement of Funds Ownership</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('statement_funds', userProfile?.documents?.statement_funds)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Statement of Responsability</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('statement_responsability', userProfile?.documents?.statement_responsability
                  )}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Trading Rules</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('trading_rules', userProfile?.documents?.trading_rules)}
              >
                Download
              </Button>
            </div>
          </li>
          <li className="py-2">
            <div className="box p-3 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">W8BEN Form</p>
              <Button
                className="border-0 p-0 fw-sbold themeClr"
                variant="transparent"
                onClick={() => handleDownload('wbenForm', userProfile?.documents?.wbenForm)}
              >
                Download
              </Button>
            </div>
          </li>
        </ul>
      </div>
      <div className="py-2">
        <h6 className="m-0  fw-sbold">Terms And Conditions</h6>
        <div className="py-2">
          <p className="m-0 ">
            Visit our page for all terms and conditions applied to your account
          </p>
          <Link className="themeClr" to="/terms-conditions" target="_blank">
          http://www.deviden.com/legal
          </Link>
        </div>
      </div>
    </>
  );
};

export default PolicyDisclosure;

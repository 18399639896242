import { Axios } from "axios";
import {
  buyItEveryDayListStatic,
  buyItMonthlyListStatic,
  buyItWeeklyListStatic,
  scheduleOrderListStatic,
  stockDetailsStatic,
} from "helpers/Mocks";
import { STRINGS } from "helpers/String";
import { STOCK_BUY_SELL } from "helpers/backend_helper";
import { CONSTANT } from "helpers/constant";
import { handleDecimal, handleDecimalToViewOnly } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import momentTimeZone from "moment-timezone";
import Select from "react-select";
import StockDetailPop from "components/Modals/StockDetailPop";
import { IoMdArrowBack } from "react-icons/io";
import { useTheme } from "Contexts/ThemeContexts";

const StockPaymentBox = ({
  paymentScreenDetails,
  setStep,
  setSuccessPop,
  setMarketTab,
}) => {
  const { isDarkMode } = useTheme();

  let type = paymentScreenDetails?.type;
  let amount = paymentScreenDetails?.amount;
  let qty = paymentScreenDetails?.qty;
  let timeInForce = paymentScreenDetails?.timeInForce;
  let orderType = paymentScreenDetails?.orderType;
  let estimatedAmount = paymentScreenDetails?.estimatedAmount;
  let limitOrderPrice = paymentScreenDetails?.limitOrderPrice;
  let isOngoingOrder = paymentScreenDetails?.isOngoingOrder;
  let commission;

  const { userProfile } = useSelector((state) => state?.Profile);
  let tradeCommission = userProfile?.tradeCommission || 0;
  let commissionTemp = (tradeCommission / 100) * estimatedAmount;
  if (commissionTemp < 0.05) {
    commission = 0.05;
  } else {
    commission = commissionTemp;
  }
  let timezone = momentTimeZone.tz.guess();
  const [state, setState] = useState({
    stockDetails: stockDetailsStatic,
    amountInUsd: amount,
    quantity: qty,
    totalAmount: amount * qty,
    // new
    buyItEveryDayList: buyItEveryDayListStatic,
    buyItEveryWeekList: buyItWeeklyListStatic,
    buyItEveryMonthList: buyItMonthlyListStatic,
    scheduleOrderList: scheduleOrderListStatic,
    selectedScheduling: "",
    selectedScheduleDateAndTime: "",
  });
  const [loading, setLoading] = useState(false);

  const { stockView: stockDetails } = useSelector((state) => state?.Basic);

  const {
    amountInUsd,
    quantity,
    buyItEveryDayList,
    buyItEveryWeekList,
    buyItEveryMonthList,
    selectedScheduling,
    scheduleOrderList,
    selectedScheduleDateAndTime,
  } = state;

  const isDecimal = (num) => num !== Math.floor(num);

  const getTimeInForceString = () => {
    if (orderType == CONSTANT.Market || isDecimal(quantity)) {
      // check if stock is fractionable and quantity is in fractions
      // Fractionable orders must be day orders
      // All market orders are day orders
      return "day";
    } else return timeInForce;
  };

  const handleBuySell = async () => {
    // return console.log("quantity % 1 : ", quantity % 1);
    // let commission;
    // let commissionTemp = (userData?.tradeCommission / 100) * estimatedAmount;
    // if (commissionTemp < 0.05) {
    //   commission = 0.05;
    // } else {
    //   commission = commissionTemp;
    // }

    if (selectedScheduling && !selectedScheduleDateAndTime) {
      toast.error(
        selectedScheduling === CONSTANT.DAILY
          ? `Please Select ${STRINGS.GMTHours}`
          : selectedScheduling === CONSTANT.WEEKLY
          ? `Please Select ${STRINGS.GMTWeek}`
          : selectedScheduling === CONSTANT.MONTHLY
          ? `Please ${STRINGS.choseDayOfMonth}`
          : "",
        {
          type: "danger",
        }
      );
    } else {
      setLoading(true);

      let body = {
        // Neww
        timezone: timezone,
        scheduled: selectedScheduling ? "scheduled" : "",
        scheduledType: selectedScheduling, //everyday, everymonth
        time: selectedScheduleDateAndTime?.time ?? "", //send if scheduledType everyday and every week
        day: selectedScheduleDateAndTime?.day ?? "", //send if scheduledType everyweek and everymonth
        timeType: selectedScheduleDateAndTime?.timeType || "",
        // Old
        symbol: stockDetails?.symbol,
        qty: quantity,
        side: type === CONSTANT.BUY ? "buy" : "sell", // sell
        type: orderType, // limit,stop, stop_limit, trailing_stop
        time_in_force: getTimeInForceString(),
        totalStockPrice:
          orderType === CONSTANT.Limit
            ? handleDecimal(quantity * limitOrderPrice)
            : handleDecimal(quantity * stockDetails?.price),
        /* 
      TYPES : day, gtc, opg, cls, ioc, fok 
      For market order always use "fok".
    */
        stockPrice: stockDetails?.price,
        commission: handleDecimal(commission),
        stockName: stockDetails?.name,
        orderPrice: handleDecimal(Number(estimatedAmount) + Number(commission)),
        fractionable: stockDetails?.fractionable,
      };


      if (orderType === CONSTANT.Limit) {
        if (limitOrderPrice > stockDetails?.price) {
          body["stopPrice"] = limitOrderPrice;
        } else {
          body["limitPrice"] = limitOrderPrice;
        }
      }


      console.log(body, "body");


      // return ;

      const response = await STOCK_BUY_SELL(body);

      if (response && response?.status == "success") {
        toast.success(response?.message);
        setLoading(false);
        setStep(1);
        setSuccessPop(response?.data);
        setMarketTab();
      } else {
        setLoading(false);
        toast.error(response?.message || response?.error_description);
      }
    }
  };

  const handleRadioIconPress = (item, index) => {
    console.log("item");
    const clonedScheduleOrderList = scheduleOrderList.map((itm) => ({
      ...itm,
      isSelected: itm._id === item?._id ? !itm.isSelected : false,
    }));

    const selectedSchedulingNew =
      clonedScheduleOrderList.find((itm) => itm.isSelected)?.value || "";

    console.log(clonedScheduleOrderList, "clonedScheduleOrderList");

    setState((prevState) => ({
      ...prevState,
      scheduleOrderList: clonedScheduleOrderList,
      selectedScheduling: selectedSchedulingNew,
      selectedScheduleDateAndTime: "",
    }));
  };

  const onSelectListItem = (item) => {
    console.log("onSelectListItem___", item);
    setState((prevState) => ({
      ...prevState,
      selectedScheduleDateAndTime: item,
    }));
  };

  return (
    <>
      <div onClick={() => setStep(1)}>
        <IoMdArrowBack size={30} />
      </div>

      {isOngoingOrder && (
        <>
          <ul className="ps-0 list-unstyled d-flex  flex-column gap-10">
            {scheduleOrderList.map((item) => {
              return (
                <li className="">
                  <label
                    htmlFor={item?._id}
                    className="d-flex align-items-center border border-dark gap-10 p-2 rounded"
                  >
                    <input
                      type="checkbox"
                      id={item?._id}
                      name="scheduleOrder"
                      className="p-4 bg-black"
                      checked={item?.isSelected}
                      onChange={() => handleRadioIconPress(item)}
                    />
                    <p className="mb-0 fw-sbold">{item?.label}</p>
                  </label>
                  <div className="scheduleSelect mt-2">
                    {item.isSelected && (
                      <Select
                        classNamePrefix="diego"
                        // menuIsOpen={true}
                        options={
                          item.isSelected && item.value === CONSTANT.DAILY
                            ? buyItEveryDayList
                            : item.value === CONSTANT.WEEKLY
                            ? buyItEveryWeekList
                            : buyItEveryMonthList
                        }
                        onChange={(option) => {
                          onSelectListItem(option);
                        }}
                      />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
      <div className="top py-2">
        <p className="m-0 fw-sbold  ">Market Order Confirmation</p>
      </div>
      <div className="contentBody py-2">
        <ul className="list-unstyled ps-0 mb-0 notLastBorder">
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold  ">Amount in USD</p>
            <p className="m-0 fw-bold text-muted">
              ${handleDecimalToViewOnly(amountInUsd)}
            </p>
          </li>
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold  ">EST. Quantity/Stock</p>
            <p className="m-0 fw-bold text-muted">
              {" "}
              {handleDecimal(quantity, 7)}
            </p>
          </li>
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold  ">Commissions paid</p>
            <p className="m-0 fw-bold text-muted">
              $ {handleDecimalToViewOnly(commission, 2)}
            </p>
          </li>
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold  ">Time in force</p>
            <p className="m-0 fw-bold text-muted">{getTimeInForceString()}</p>
          </li>
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold  ">Progressing Fee</p>
            <p className="m-0 fw-bold text-muted">$0</p>
          </li>
        </ul>
      </div>
      <div className="btnWrpper mt-3 py-2">
        <div className="py-2">
          <div
            className="rounded d-flex align-items-center justify-content-between py-1 px-2"
            style={{ background: isDarkMode ? "#222222" : "white" }}
          >
            <p className="m-0 text-muted fw-sbold">Total</p>
            <p className="m-0 text-muted fw-sbold">
              ${handleDecimal(Number(estimatedAmount) + Number(commission))}
            </p>
          </div>
        </div>
        <Button
          onClick={handleBuySell}
          disabled={loading}
          className="d-flex w-100 rounded-pill align-items-center justify-content-center commonBtn fw-sbold"
        >
          {loading ? "Loading..." : "Buy"}
        </Button>
      </div>
    </>
  );
};

export default StockPaymentBox;

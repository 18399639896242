export const GET_HOME = "GET_HOME";
export const GET_HOME_FAIL = "GET_HOME_FAIL";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";

export const TOP_GAINER = "TOP_GAINER";
export const TOP_GAINER_FAIL = "TOP_GAINER_FAIL";
export const TOP_GAINER_SUCCESS = "TOP_GAINER_SUCCESS";

export const TOP_LOOSER = "TOP_LOOSER";
export const TOP_LOOSER_FAIL = "TOP_LOOSER_FAIL";
export const TOP_LOOSER_SUCCESS = "TOP_LOOSER_SUCCESS";

export const INDUSTRIES = "INDUSTRIES";
export const INDUSTRIES_FAIL = "INDUSTRIES_FAIL";
export const INDUSTRIES_SUCCESS = "INDUSTRIES_SUCCESS";

export const POPULARSTOCK = "POPULARSTOCK";
export const POPULARSTOCK_FAIL = "POPULARSTOCK_FAIL";
export const POPULARSTOCK_SUCCESS = "POPULARSTOCK_SUCCESS";

export const FAVOURITE_STOCK = "FAVOURITE_STOCK";
export const FAVOURITE_STOCK_FAIL = "FAVOURITE_STOCK_FAIL";
export const FAVOURITE_STOCK_SUCCESS = "FAVOURITE_STOCK_SUCCESS";

export const ADD_REMOVE_FAVOURITE_STOCK = "ADD_REMOVE_FAVOURITE_STOCK";
export const ADD_REMOVE_FAVOURITE_STOCK_FAIL =
  "ADD_REMOVE_FAVOURITE_STOCK_FAIL";
export const ADD_REMOVE_FAVOURITE_STOCK_SUCCESS =
  "ADD_REMOVE_FAVOURITE_STOCK_SUCCESS";

import React, { useEffect, useState } from "react";

// img
import c1 from "../../../../Assets/images/c1.png";
import { Button, Col, Modal, Nav, Row, Tab } from "react-bootstrap";

// css
import styles from "../MyPortFolio.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { pendingPositions, scheduleOrder } from "store/actions";
//pagination
import ReactPagination from "../../../../components/Common/ReactPagination";
import {
  handleDecimalToViewOnly,
  itemsPerPage,
  quantityToSpecificDecimal,
} from "helpers/helper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CANCEL_ORDER, cancelScheduleOrder } from "helpers/backend_helper";
import OrderDetailModal from "./OrderDetailModal";
import { CONSTANT } from "helpers/constant";

const PendingTab = ({ pendingPositionList, filter, setFilter }) => {
  const dispatch = useDispatch();
  const { scheduleOrderList, loading } = useSelector((state) => state?.Basic);

  const [currentTab, setCurrentTab] = useState("Pending");
  const [showModal, setShowModal] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentTab === "Scheduled") {
      dispatch(scheduleOrder());
    }
  }, [currentTab]);

  const handleTabClick = (key) => {
    setCurrentTab(key);
    // Perform additional actions based on the clicked tab
  };

  const indexOfLastItem = filter?.page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    pendingPositionList?.orderLists?.length > 0 &&
    pendingPositionList?.orderLists?.slice(indexOfFirstItem, indexOfLastItem);

  console.log(pendingPositionList, "876b");

  const currentItems1 =
    scheduleOrderList?.length > 0 &&
    scheduleOrderList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const cancelHandler = async (orderId) => {
    try {
      const res = await cancelScheduleOrder({ orderId });

      if (res?.status === "success") {
        toast.success(res.message);
        dispatch(scheduleOrder());
      } else {
        toast.error(res.message || "something went wrong");
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };
  const cancelPendingHandler = async (orderId) => {
    try {
      const res = await CANCEL_ORDER({ orderId });

      if (res?.status === "success") {
        handleCloseModal();
        toast.success(res.message);

        dispatch(
          pendingPositions({
            forAllOrders: true,
          })
        );
      } else {
        toast.error(res.message || "something went wrong");
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };
  const handleShowModal = (orderId) => {
    setOrderIdToCancel(orderId); // Set the order ID to cancel
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setOrderIdToCancel(null); // Reset order ID
  };

  const handleConfirmDelete = () => {
    if (orderIdToCancel) {
      cancelPendingHandler(orderIdToCancel); // Call the cancellation handler
    }
    // Close the modal after confirming
  };
  return (
    <>
      <div className="py-2">
        <p className="m-0  fw-sbold py-2">Pending Orders</p>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Pending">
          <Nav variant="pills" className={` navPillsTab gap-10`}>
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Pending"
                onClick={() => handleTabClick("Pending")}
              >
                Pending
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Scheduled"
                onClick={() => handleTabClick("Scheduled")}
              >
                Scheduled
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Scheduled">
              {scheduleOrderList?.length > 0 ? (
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    {currentItems1?.length > 0 &&
                      currentItems1.map((item, i) => {
                        return (
                          <li className="py-3" key={i}>
                            <div
                              className="box rounded cardCstm"
                              style={{ fontSize: 10, lineHeight: "12px" }}
                              onClick={() => {
                                setSelectedItem(item);
                                setShowDetailModal(true);
                              }}
                            >
                              <div
                                className=" p-2 top d-flex align-items-center justify-content-between gap-10"
                                style={{
                                  borderBottom:
                                    " 1px solid var(--darkLightGray)",
                                }}
                              >
                                <div className="left d-flex align-items-center gap-10">
                                  <div
                                    className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                                    style={{ height: 40, width: 40 }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="24"
                                      viewBox="0 0 20 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_0_2630)">
                                        <path
                                          d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2630">
                                          <rect
                                            width="20"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <img
                                      src={item?.imgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="content ">
                                    <h6 className="m-0 ">{item?.symbol}</h6>
                                    <p className="m-0 text-muted m-0">
                                      {item?.shortName}
                                    </p>
                                  </div>
                                </div>
                                <div className="">
                                  <h6 className="m-0 themeClr fw-sbold">
                                    {item?.scheduledType === "everyday"
                                      ? "Daily Order"
                                      : item?.scheduledType === "everyweek"
                                      ? "Weekly Order"
                                      : "Monthly Order"}
                                  </h6>
                                </div>
                                <div className="right text-end">
                                  <Button
                                    style={{
                                      backgroundColor: "red",
                                      borderColor: "red",
                                    }}
                                    onClick={() => cancelHandler(item?._id)}
                                    className="rounded-pill d-flex align-items-center justify-content-center fw-sbold"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                <div className="py-2">
                  {" "}
                  <div
                    className="box rounded cardCstm my-3"
                    style={{ fontSize: 10, lineHeight: "12px" }}
                  >
                    <div className="py-5 text-center">
                      <h6 className="m-0 fw-sbold">No Data available</h6>
                    </div>
                  </div>
                </div>
              )}

              {scheduleOrderList?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={scheduleOrderList?.length}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="Pending">
              {" "}
              {pendingPositionList?.orderLists?.length > 0 ? (
                <div className="py-2">
                  <ul className="list-unstyled ps-0 mb-0">
                    {currentItems?.length > 0 &&
                      currentItems?.map((item, i) => {
                        return (
                          <li className="py-3" key={i}>
                            <div
                              className="box rounded cardCstm"
                              style={{ fontSize: 10, lineHeight: "12px" }}
                              onClick={() =>
                                navigate(`/stock-details/${item?.symbol}`, {
                                  state: { item, type: "pending" },
                                })
                              }
                            >
                              <div
                                className="p-2 top d-flex align-items-center justify-content-between gap-10"
                                style={{
                                  borderBottom:
                                    "1px solid var(--darkLightGray)",
                                }}
                              >
                                <div className="left d-flex align-items-center gap-10">
                                  <div
                                    className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0 border border-secondary rounded"
                                    style={{ height: 40, width: 40 }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="24"
                                      viewBox="0 0 20 24"
                                      fill="none"
                                    >
                                      <g clipPath="url(#clip0_0_2630)">
                                        <path
                                          d="M19.3636 8.1816C19.2211 8.2896 16.7052 9.6744 16.7052 12.7536C16.7052 16.3152 19.9066 17.5752 20.0025 17.6064C19.9877 17.6832 19.4939 19.332 18.3145 21.012C17.2629 22.4904 16.1646 23.9664 14.4939 23.9664C12.8231 23.9664 12.3931 23.0184 10.4644 23.0184C8.58477 23.0184 7.91646 23.9976 6.38821 23.9976C4.85995 23.9976 3.79361 22.6296 2.56757 20.9496C1.14742 18.9768 0 15.912 0 13.0032C0 8.3376 3.10565 5.8632 6.16216 5.8632C7.78624 5.8632 9.14005 6.9048 10.1597 6.9048C11.1302 6.9048 12.6437 5.8008 14.4914 5.8008C15.1916 5.8008 17.7076 5.8632 19.3636 8.1816ZM13.6143 3.8256C14.3784 2.94 14.9189 1.7112 14.9189 0.4824C14.9189 0.312 14.9042 0.1392 14.8722 0C13.629 0.0456 12.1499 0.8088 11.258 1.8192C10.5577 2.5968 9.90418 3.8256 9.90418 5.0712C9.90418 5.2584 9.93612 5.4456 9.95086 5.5056C10.0295 5.52 10.1572 5.5368 10.285 5.5368C11.4005 5.5368 12.8034 4.8072 13.6143 3.8256Z"
                                          fill="white"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_0_2630">
                                          <rect
                                            width="20"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <img
                                      src={item?.imgUrl}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="content">
                                    <h6 className="m-0">{item?.symbol}</h6>
                                    <p className="m-0 text-muted">
                                      {item?.shortName}
                                    </p>
                                  </div>
                                </div>
                                <div className="right  d-flex text-end">
                                  {/* <p 
                                    className="m-0 text-muted fw-sbold px-2 py-1 rounded-pill border me-2"
                                    style={{
                                      borderColor: "white",
                                      borderWidth: "2px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item?.side}
                                  </p> */}
                                  <p
                                    className="m-0 text-muted fw-sbold px-2 py-1 rounded-pill border me-2 "
                                    style={{
                                      borderColor: "white",
                                      borderWidth: "2px",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item?.order_type}
                                  </p>
                                </div>
                              </div>
                              <div className="bottom p-2">
                                <Row>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0 fw-sbold">
                                        $
                                        {handleDecimalToViewOnly(
                                          item?.currentPrice ?? 0,
                                          2
                                        )}
                                      </h6>
                                      <p className="m-0 text-muted">
                                        Current Price
                                      </p>
                                    </div>
                                  </Col>
                                  <Col lg="2" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0 fw-sbold">
                                        {item?.order_type === CONSTANT.Limit
                                          ? `$${handleDecimalToViewOnly(
                                              item?.limit_price,
                                              2
                                            )}`
                                          : item?.order_type === CONSTANT.Stop
                                          ? `$${handleDecimalToViewOnly(
                                              item?.stop_price,
                                              2
                                            )}`
                                          : `$${handleDecimalToViewOnly(
                                              item?.requestPrice,
                                              2
                                            )}`}
                                      </h6>
                                      <p className="m-0 text-muted">
                                        {item?.order_type === CONSTANT.Limit
                                          ? "Limit Price"
                                          : item?.order_type === CONSTANT.Stop
                                          ? "Stop Price"
                                          : item?.order_type === CONSTANT.Market
                                          ? "Req. Price"
                                          : "Order Price"}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col lg="2" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0 fw-sbold">
                                        {item?.qty &&
                                          quantityToSpecificDecimal(
                                            item?.qty,
                                            5
                                          )}
                                      </h6>
                                      <p className="m-0 text-muted">Shares</p>
                                    </div>
                                  </Col>
                                  <Col lg="3" md="4" sm="6" className="my-2">
                                    <div className="text-center">
                                      <h6 className="m-0 fw-sbold">
                                        $
                                        {item?.requestAmount &&
                                          handleDecimalToViewOnly(
                                            item?.requestAmount
                                          )}
                                      </h6>
                                      <p className="m-0 text-muted">Amount</p>
                                    </div>
                                  </Col>
                                  <Col lg="2" md="4" sm="6">
                                    <Button
                                      style={{
                                        backgroundColor: "red",
                                        borderColor: "red",
                                      }}
                                      onClick={() =>
                                        handleShowModal(item?.order_id)
                                      }
                                      className="rounded-pill d-flex align-items-center justify-content-center fw-sbold"
                                    >
                                      Cancel
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              ) : (
                <div className="py-2">
                  {" "}
                  <div
                    className="box rounded cardCstm my-3"
                    style={{ fontSize: 10, lineHeight: "12px" }}
                  >
                    <div className="py-5 text-center">
                      <h6 className="m-0 fw-sbold">No Data available</h6>
                    </div>
                  </div>
                </div>
              )}
              {pendingPositionList?.buyArry?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={itemsPerPage}
                    totalItemsCount={pendingPositionList?.buyArry?.length}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm to Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this order?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <OrderDetailModal
        show={showDetailModal}
        close={() => setShowDetailModal(false)}
        type={currentTab}
        stockView={selectedItem}
      />
    </>
  );
};

export default PendingTab;

import React, { useEffect, useRef, useState} from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

// img
import s1 from "../../../Assets/images/s1.png";
import { useSelector, useDispatch } from "react-redux";
//store
import {  favouriteStock} from "store/actions";
import Loader from "components/Loader";
//pagination
import ReactPagination from "../../../components/Common/ReactPagination";
import { itemsPerPage } from "helpers/helper";
import { useNavigate } from "react-router-dom";


const FavouriteStock = () => {
  const dispatch = useDispatch()

  const {favouriteStockList, loading} = useSelector((state) => state?.Home);
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const indexOfLastItem = filter?.page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
  favouriteStockList?.length > 0 &&
  favouriteStockList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  
  useEffect(() => {
      dispatch(favouriteStock())
  }, [filter])

  return (
    <>
    {loading && 
      <Loader /> 
    }
      <section className="favouriteStock py-2 position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="pageHead">
                <h4 className="m-0 fw-sbold ">Favorite Stock</h4>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="box p-0 tableCard">
                <div className="table-responsive commonTable">
                  <table className="table m-0">
                    <thead>
                      <tr>
                        <th className="  fw-sbold">Currency</th>
                        <th className="  fw-sbold">Price</th>
                        <th className="  fw-sbold">24 Change</th>
                        <th className="  fw-sbold"></th>
                      </tr>
                    </thead>
                    <tbody>
                    {currentItems?.length>0 && currentItems?.map((list, i) => { 
                      return(
                      <tr key={i} onClick={() =>   navigate("/stock-complete-details/" + list?.symbol)}>
                        <td className="border-0 py-3 ">
                          <div className="d-flex align-items-center gap-10">
                            <div className="imgWrp">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                              >
                                <rect
                                  width="40"
                                  height="40"
                                  rx="5"
                                  fill="white"
                                />
                                <g clip-path="url(#clip0_18_68)">
                                  <path
                                    d="M6 25.627H12.7408V27.6212H10.807V32.414H7.99815V27.6212H6V25.627ZM16.1547 28.3692L14.7355 32.414H12.038L14.6836 25.627H17.6131L20.3225 32.414H17.5918L16.1547 28.3692ZM19.6781 25.627H26.4186V27.6212H24.4854V32.414H21.6767V27.6212H19.6781V25.627ZM29.8337 28.3692L28.4139 32.414H25.7164L28.3629 25.627H31.2916L34 32.414H31.2703L29.8337 28.3692Z"
                                    fill="#4C66FF"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M29.9331 13.1605C29.6814 12.6793 29.35 12.2181 28.9376 11.7857C28.0109 10.8133 26.7035 10.0042 25.1562 9.44612C23.5962 8.88375 21.8169 8.58618 20.0088 8.58618C18.2006 8.58618 16.4213 8.88375 14.8621 9.44612C13.3141 10.0042 12.0065 10.8134 11.0799 11.7857C10.6678 12.2181 10.3352 12.6799 10.0836 13.1613C12.098 12.6878 15.5436 12.0625 18.7429 11.9964C19.0515 11.99 19.264 12.0861 19.4039 12.2584C19.5743 12.4684 19.5615 13.2167 19.5573 13.5515L19.4664 22.2044C19.6466 22.2102 19.8276 22.214 20.0088 22.214C20.1915 22.214 20.3735 22.2111 20.5537 22.2053L20.4627 13.5514C20.458 13.2167 20.4448 12.4683 20.6158 12.2584C20.7561 12.0861 20.9678 11.99 21.2763 11.9964C24.4743 12.0619 27.9192 12.6873 29.9331 13.1605Z"
                                    fill="#4C66FF"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M30.3632 14.2857C27.415 13.6377 25.4462 13.5179 23.5036 13.4131C21.8102 13.3216 21.7881 13.9094 21.9572 15.0328C21.9682 15.1014 21.9818 15.1878 21.998 15.2863C22.5659 18.5569 23.2732 21.3713 23.3948 21.849C27.523 20.9316 30.5001 18.3881 30.5001 15.4002C30.5001 15.0239 30.4533 14.6512 30.3632 14.2857ZM18.062 15.0328C18.2312 13.9095 18.2095 13.3216 16.5164 13.4131C14.5731 13.5179 12.6038 13.6377 9.65448 14.2861C9.56439 14.6515 9.51703 15.0239 9.51703 15.4001C9.51703 16.6915 10.0574 17.9411 11.0799 19.0147C12.0066 19.9871 13.3141 20.7962 14.8622 21.3546C15.4225 21.5563 16.0137 21.7216 16.6228 21.8538C16.7371 21.4063 17.4606 18.5333 18.0348 15.2042C18.0454 15.1405 18.0552 15.0816 18.062 15.0328Z"
                                    fill="#4C66FF"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_18_68">
                                    <rect
                                      width="28"
                                      height="25"
                                      fill="white"
                                      transform="translate(6 8)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg> */}
                               <img src={list?.imgUrlDark} className="stock-list-size" />

                            </div>
                            <div className="content">
                              <h6 className=" m-0 fw-bold pb-2">{list?.symbol}</h6>
                              <p className="text-muted m-0">{list?.shortName}</p>
                            </div>
                          </div>
                        </td>
                        <td className="border-0 py-3 ">$ {list?.price ? parseFloat(list?.price).toFixed(2) : '0'}</td>
                        <td className="border-0 py-3 ">
                          <p className="m-0 themeClr fw-sbold">{list?.changePercentage ? parseFloat(list?.changePercentage).toFixed(2) : '0'}%</p>
                        </td>
                        <td className="border-0 py-3 ">
                          <Button
                            className="border-0 p-0"
                            variant="transparent"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <path
                                d="M29.5 15C29.5 22.9854 22.9854 29.5 15 29.5C7.0146 29.5 0.5 22.9854 0.5 15C0.5 7.0146 7.0146 0.5 15 0.5C22.9854 0.5 29.5 7.0146 29.5 15Z"
                                fill=""
                                stroke="var(--darkLightGray)"
                              />
                              <path
                                d="M22.4735 14.0753L19.3095 16.8056L20.2735 20.8886C20.3266 21.1103 20.3129 21.3427 20.2341 21.5566C20.1552 21.7704 20.0148 21.9561 19.8304 22.0902C19.6461 22.2243 19.4262 22.3007 19.1984 22.3099C18.9706 22.319 18.7453 22.2604 18.5508 22.1416L15 19.9563L11.4471 22.1416C11.2527 22.2598 11.0276 22.3178 10.8002 22.3083C10.5728 22.2988 10.3534 22.2223 10.1694 22.0883C9.98546 21.9543 9.84525 21.769 9.76645 21.5555C9.68765 21.342 9.67378 21.11 9.72658 20.8886L10.6941 16.8056L7.53002 14.0753C7.35796 13.9266 7.23353 13.7305 7.17225 13.5115C7.11098 13.2925 7.11559 13.0603 7.1855 12.8439C7.2554 12.6275 7.38752 12.4365 7.56533 12.2947C7.74315 12.153 7.95879 12.0667 8.18533 12.0468L12.3338 11.7121L13.9341 7.83931C14.0207 7.62824 14.1681 7.4477 14.3576 7.32064C14.5471 7.19358 14.7701 7.12573 14.9983 7.12573C15.2264 7.12573 15.4494 7.19358 15.6389 7.32064C15.8284 7.4477 15.9758 7.62824 16.0624 7.83931L17.662 11.7121L21.8105 12.0468C22.0375 12.066 22.2537 12.1518 22.4322 12.2933C22.6107 12.4349 22.7434 12.626 22.8137 12.8426C22.8841 13.0593 22.8889 13.2919 22.8277 13.5113C22.7664 13.7307 22.6417 13.9272 22.4693 14.076L22.4735 14.0753Z"
                                fill="#CF0A10"
                              />
                            </svg>



                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<path d="M29.5 15C29.5 23.0081 23.0081 29.5 15 29.5C6.99187 29.5 0.5 23.0081 0.5 15C0.5 6.99187 6.99187 0.5 15 0.5C23.0081 0.5 29.5 6.99187 29.5 15Z" stroke="var(--darkLightGray)"/>
<g clip-path="url(#clip0_1_8)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.92 5.868C14.03 5.67916 14.1877 5.52248 14.3772 5.41358C14.5667 5.30468 14.7814 5.24737 15 5.24737C15.2186 5.24737 15.4333 5.30468 15.6228 5.41358C15.8123 5.52248 15.9699 5.67916 16.08 5.868L18.875 10.666L24.303 11.842C24.5165 11.8884 24.7141 11.99 24.8761 12.1365C25.0382 12.2831 25.159 12.4696 25.2264 12.6774C25.2939 12.8852 25.3058 13.1071 25.2608 13.3209C25.2158 13.5347 25.1155 13.733 24.97 13.896L21.27 18.037L21.83 23.562C21.8521 23.7796 21.8167 23.9991 21.7274 24.1987C21.6381 24.3983 21.498 24.571 21.3211 24.6996C21.1442 24.8281 20.9367 24.908 20.7192 24.9312C20.5018 24.9544 20.2821 24.9202 20.082 24.832L15 22.592L9.91799 24.832C9.7179 24.9202 9.49818 24.9544 9.28074 24.9312C9.0633 24.908 8.85577 24.8281 8.67886 24.6996C8.50195 24.571 8.36185 24.3983 8.27255 24.1987C8.18326 23.9991 8.14789 23.7796 8.16999 23.562L8.72999 18.037L5.02999 13.897C4.88421 13.734 4.78374 13.5357 4.7386 13.3217C4.69346 13.1078 4.70523 12.8857 4.77273 12.6778C4.84023 12.4698 4.96111 12.2832 5.12329 12.1365C5.28547 11.9898 5.48329 11.8883 5.69699 11.842L11.125 10.666L13.92 5.868ZM15 7.987L12.687 11.959C12.5995 12.1089 12.4818 12.239 12.3413 12.3409C12.2008 12.4428 12.0406 12.5144 11.871 12.551L7.37899 13.524L10.441 16.951C10.675 17.213 10.788 17.561 10.753 17.91L10.29 22.483L14.496 20.629C14.6548 20.559 14.8264 20.5229 15 20.5229C15.1735 20.5229 15.3452 20.559 15.504 20.629L19.71 22.483L19.247 17.91C19.2294 17.7373 19.248 17.5629 19.3015 17.3978C19.3551 17.2326 19.4424 17.0805 19.558 16.951L22.621 13.524L18.129 12.551C17.9593 12.5144 17.7991 12.4428 17.6587 12.3409C17.5182 12.239 17.4004 12.1089 17.313 11.959L15 7.987Z" fill="#CF0A10"/>
</g>
<defs>
<clipPath id="clip0_1_8">
<rect width="24" height="24" fill="white" transform="translate(3 3)"/>
</clipPath>
</defs>
</svg> */}
                          </Button>
                        </td>
                      </tr>
                      )
                    })}
                    </tbody>
                  </table>
                  {favouriteStockList?.length>0 &&
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={favouriteStockList?.length}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  }
                </div>
              </div>
             
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FavouriteStock;

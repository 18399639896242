import CopyToClip from "components/Common/CopyToClip";
import ReactPagination from "components/Common/ReactPagination";
import { AFFILIATE_CLIENT_LIST } from "helpers/backend_helper";
import {
  dateFormat,
  formatStandardNumberWithMinNMaxDecimal,
  itemsPerPage,
} from "helpers/helper";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";

const ClientsTab = ({ setTotalUsers }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  const getData = async () => {
    try {
      const response = await AFFILIATE_CLIENT_LIST({
        ...filter,
      });
      if (response.status === "failure") {
        setLoading(false);
        return toast.error(response?.message);
      }
      setData(response);
      setTotalUsers(response.totalcount);
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    // window.scrollTo(0, 0);
  };

  useEffect(() => {
    getData();
  }, [filter.page]);
  return (
    <div
      style={{
        minHeight: 300,
      }}
    >
      <Col lg="12" className="my-2">
        <div className="box p-0 tableCard">
          <div className="table-responsive commonTable">
            <table className="table m-0">
              {/* <thead>
                  <tr>
                    <th className="  fw-sbold">Name</th>
                    <th className="  fw-sbold">Withdrawal Amount</th>
                    <th className="  fw-sbold"></th>
                  </tr>
                </thead> */}
              <tbody>
                {data?.data?.length > 0 &&
                  data?.data?.map((list, i) => {
                    return (
                      <tr key={i}>
                        <td className="border-0 py-3 ">
                          <div className="d-flex align-items-center gap-10">
                            {/* <img
                              src={list?.imgUrlDark}
                              className="stock-list-size"
                            /> */}
                            <div className="content">
                              <Row className="d-flex gap-10">
                                <Col>
                                  <p className=" m-0 fw-bold text-muted  mb-0">
                                    Account id{" "}
                                    <CopyToClip
                                      value={list?.accountData?.account_number}
                                    />
                                  </p>
                                  <p className=" m-0 fw-bold   mb-0">
                                    {list?.accountData?.account_number}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </td>
                        <td className="border-0 py-3 ">
                          {/* <p className="mb-0 fw-sbold text-muted">
                            ${" "}
                            {list?.treadAmount
                              ? parseFloat(list?.treadAmount).toFixed(2)
                              : "0"}
                          </p> */}

                          <p className="mb-0 fw-sbold  text-warning ">
                            + ${" "}
                            {formatStandardNumberWithMinNMaxDecimal(
                              list?.totalCommission
                            )}
                          </p>

                          <p className="mb-0 fw-sbold text-muted">
                            {dateFormat(list.createdAt)}
                          </p>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {data?.data?.length > 0 && (
              <div className="pagination-box-review">
                <ReactPagination
                  activePage={filter?.page}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={data?.totalcount}
                  handlePageChange={handlePageChange}
                />
              </div>
            )}
          </div>
        </div>
      </Col>
    </div>
  );
};

export default ClientsTab;

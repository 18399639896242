import Loader from "components/Loader";
import { useFormik } from "formik";
import { getBankAccountsApi, transactionUpdate } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { FaCloudUploadAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "store/actions";

// const schema =

const TransactionUpdate = ({ successTransactionDetails, exchangeData }) => {
  const navigate = useNavigate();

  const { userProfile } = useSelector((state) => state?.Profile);

  const exchangeRate = userProfile?.exchangeRate;
  const exchangeRatePercentage = 0.3;
  let exchangeFee = exchangeData?.dollaramount * (exchangeRatePercentage / 100);
  let amountCharged = Number(exchangeData?.dollaramount) + Number(exchangeFee);

  const [loader, setLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();

  const finishedHandler = async () => {
    setLoader(true);

    const body = {
      _id: successTransactionDetails?._id,
      file: imageUrl, // optionalsuccessTransactionDetails?.trana
    };

    try {
      const res = await transactionUpdate(body);
      if (res.status === "success") {
        toast.success(res?.message);
        navigate(-1);
        setLoader(false);
      } else {
        setLoader(false);

        toast.error(res.message || "something went wrong");
      }
    } catch (error) {
      setLoader(false);

      toast.error("something went wrong");
    }
  };

  const fileUploadHandler = (e) => {
    setImageLoader(true);
    const file = e.target.files[0];

    const callback = (response) => {
      if (response?.status === "success") {
        const { data } = response;
        setImageUrl((p) => data?.url);
        setImageLoader(false);
      }
      setImageLoader(false);
    };
    dispatch(uploadFile(file, callback));
  };

  return (
    <>
      {loader && <Loader />}
      <section className="bankTransfer position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap  gap-10">
                <div
                  className="left d-flex align-items-center gap-10"
                  onClick={() => navigate(-1)}
                >
                  {/* <Link to={"/my-wallet/withdrawal"}> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M15.4141 8.086L3.82806 8.086L8.32806 12.586L6.91406 14L6.07329e-05 7.086L6.91406 0.172001L8.32806 1.586L3.82806 6.086L15.4141 6.086L15.4141 8.086Z"
                      fill="var(--darkLightRed)"
                    />
                  </svg>
                  {/* </Link> */}
                  <h5 className="m-0 fw-sbold ">Transfer Details</h5>
                </div>
                <div className="right">
                  <Link
                    to="/my-wallet/bank-transfer/add"
                    className="border-0 p-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 50 50"
                      fill="none"
                    >
                      <circle cx="25" cy="25" r="25" fill="#D20A0C" />
                      <path
                        d="M34.9999 26.4261H26.4285V34.9976H23.5714V26.4261H14.9999V23.569H23.5714V14.9976H26.4285V23.569H34.9999V26.4261Z"
                        fill="white"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <p className="m-0 text-muted px-4">
                  Please proceed and complete your deposit by transferring the
                  total amount to the below bank account.
                </p>
                <p className="m-0 fw-sbold px-4 ">
                  Total Amount : Rp {Number(amountCharged * exchangeRate).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                </p>
              </div>
            </Col>
            {/* {banks?.map((item) => {
              return ( */}
            <Row className="d-flex justify-content-center">
              <Col
                md="6"
                className="my-2  "
                //   onClick={() => setCurrentBankSelected(item)}
              >
                <div className="cardCstm box">
                  <div
                    className="top px-3 py-2 d-flex align-items-center gap-10"
                    style={{
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      background: "var(--ThemeDarkGray)",
                    }}
                  >
                    <div className="icn flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <circle cx="20" cy="20" r="20" fill="#225F29" />
                        <path
                          d="M21.2043 10.1672L30.1293 15.1828C30.6629 15.4511 31 15.9995 31 16.5995V18.121C31 18.85 30.4124 19.441 29.6875 19.441H28.9V27.8882H29.95C30.5299 27.8882 31 28.3609 31 28.9441C31 29.5273 30.5299 30 29.95 30H11.05C10.4701 30 10 29.5273 10 28.9441C10 28.3609 10.4701 27.8882 11.05 27.8882H12.1V19.441H11.3125C10.5876 19.441 10 18.85 10 18.121V16.5995C10 16.0495 10.2832 15.5429 10.7414 15.2556L19.7957 10.1672C20.2391 9.94426 20.7609 9.94426 21.2043 10.1672ZM26.8 19.441H14.2V27.8882H17.35V21.5527H19.45V27.8882H21.55V21.5527H23.65V27.8882H26.8V19.441ZM20.5 12.1741L12.1 16.9258V17.3291H28.9V16.9258L20.5 12.1741ZM20.5 14.1613C21.0799 14.1613 21.55 14.634 21.55 15.2172C21.55 15.8003 21.0799 16.2731 20.5 16.2731C19.9201 16.2731 19.45 15.8003 19.45 15.2172C19.45 14.634 19.9201 14.1613 20.5 14.1613Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <p className="m-0 fw-sbold darkLightRed">Bank Name</p>
                      BANK BCA
                    </div>
                  </div>
                  <div className="contentBody p-3">
                    <ul className="list-unstyled sp-0 mb-0">
                      <li className="py-2">
                        <p className="m-0 darkLightRed">Account Number</p>
                        <h5 className="m-0 fw-sbold ">0353533999</h5>
                      </li>
                      <li className="py-2">
                        <p className="m-0 darkLightRed">Currency</p>
                        USD
                      </li>
                      <li className="py-2">
                        <p className="m-0 darkLightRed">Account Holder Name </p>
                        <h5 className="m-0 fw-sbold ">
                          HFX INTERNASIONAL BERJANGKA PT
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col md="6" className="my-2  ">
                <label
                  htmlFor="image"
                  style={{
                    height: 200,
                    width: "100%",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    id="image"
                    className="d-none"
                    onChange={fileUploadHandler}
                  />
                  <div
                    className="cardCstm box d-flex flex-column justify-content-between "
                    style={{
                      height: 200,
                      width: "100%",
                      border: "dashed white 1px",
                    }}
                  >
                    <div className="d-flex justify-content-center pt-2">
                      {imageLoader ? (
                        <Spinner />
                      ) : (
                        <>
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt=""
                              style={{
                                height: 100,
                                width: 100,
                              }}
                            />
                          ) : (
                            <FaCloudUploadAlt size={50} />
                          )}
                        </>
                      )}
                    </div>
                    <p className="text-center">Upload Screen shot</p>
                  </div>
                </label>
              </Col>
            </Row>
            {/* );
            })} */}

            <Col lg="12" className="my-2 d-flex flex-column align-items-center">
              <div className="btnWrpper text-end mt-4">
                <Button
                  onClick={() => finishedHandler()}
                  className="d-inline-flex align-items-center justify-content-center commonBtn"
                  style={{ height: 50, minWidth: 150 }}
                  disabled={loader}
                >
                  {loader ? <Spinner /> : "I've finished"}
                </Button>
              </div>
              <div className="btnWrpper text-end mt-4">
                <Button
                  onClick={() => navigate(-1)}
                  className="d-inline-flex align-items-center justify-content-center commonBtn bg-transparent"
                  style={{ height: 50, minWidth: 150 }}
                >
                  Go back
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TransactionUpdate;

import React, { useState } from "react";
import img from "../../../../../Assets/images/becomeAffiliateImg.gif";
import { getProfile, hideAlert } from "store/actions";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { BECOME_AFFILIATE_REQUEST } from "helpers/backend_helper";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANT } from "helpers/constant";
import { STRINGS } from "helpers/String";
import Loader from "components/Loader";

const BecomeAnAffiliate = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { loadingProfile, userProfile } = useSelector((state) => state.Profile);
  let isAffiliatedRequestSent = userProfile?.affiliatedRequest?._id;

  let affiliatedRequestStatus = userProfile?.affiliatedRequest?.status;
  let affiliatedCode = userProfile?.affiliatedCode;

  const requestHandler = async () => {
    setLoading(true);
    try {
      const response = await BECOME_AFFILIATE_REQUEST();
      if (response.status === "failure") {
        setLoading(false);
        return toast.error(response?.message);
      }
      toast.success(response?.message);
      dispatch(getProfile());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  console.log("sdfsdfg324");
  return (
    <>
      {loadingProfile ? (
        <Loader />
      ) : (
        <div>
          <div className="d-flex justify-content-center">
            <img
              src={img}
              alt=""
              style={{
                width: 150,
                height: 150,
              }}
              className="mx-auto"
            />
          </div>
          <div className="d-flex align-items-center flex-column">
            {!isAffiliatedRequestSent ? (
              <>
                <h4 className="w-50 px-2 text-center">
                  At Deviden you can become an Affiliate, introduce your clients
                  and start earning commissions for their trades.
                </h4>
                <p className="w-50 px-2 text-center">
                  if you would like to become an Affiliate, please submit your
                  application by clicking on the processed bottom below and we
                  will get in contact with you for further details
                </p>
              </>
            ) : (
              <h4 className="w-50 px-2 text-center">
                {affiliatedRequestStatus === CONSTANT.PendingAffiliate
                  ? STRINGS.PendingAffiliateDes
                  : affiliatedRequestStatus === CONSTANT.ApprovedAffiliate
                  ? STRINGS.ApprovedAffiliateDes
                  : affiliatedRequestStatus === CONSTANT.RejectedAffiliate
                  ? STRINGS.RejectedAffiliateDes
                  : ""}
              </h4>
            )}

            {affiliatedRequestStatus === CONSTANT.ApprovedAffiliate ? (
              <p>
                {STRINGS.AffiliatedCodeIs} {affiliatedCode}
              </p>
            ) : null}
          </div>

          <div className="btnWrpper text-center py-3">
            {!isAffiliatedRequestSent && (
              <Button
                className="d-inline-flex align-items-center justify-content-center commonBtn rounded"
                type="button"
                disabled={loading}
                onClick={requestHandler}
              >
                {loading ? "Loading…" : "processed"}
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BecomeAnAffiliate;

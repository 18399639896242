export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const PUT_PROFILE = "PUT_PROFILE";
export const PUT_PROFILE_SUCCESS = "PUT_PROFILE_SUCCESS";
export const PUT_PROFILE_FAIL = "PUT_PROFILE_FAIL";

export const PUT_PROFILE_IMAGE = "PUT_PROFILE_IMAGE";
export const PUT_PROFILE_IMAGE_SUCCESS = "PUT_PROFILE_IMAGE_SUCCESS";
export const PUT_PROFILE_IMAGE_FAIL = "PUT_PROFILE_IMAGE_FAIL";

export const GET_USER_BY_WALLET = "GET_USER_BY_WALLET";
export const GET_USER_BY_WALLET_SUCCESS = "GET_USER_BY_WALLET_SUCCESS";
export const GET_USER_BY_WALLET_FAIL = "GET_USER_BY_WALLET_FAIL";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";

export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// market details 

export const GET_MARKET_DETAILS = "GET_MARKET_DETAILS";
export const GET_MARKET_DETAILS_SUCCESS = "GET_MARKET_SUCCESS";
export const GET_MARKET_DETAILS_ERROR = "GET_MARKET_ERROR";

import React from "react";

const Item = ({ isHeader, value, isDollar, isNumber }) =>
  isHeader ? (
    <th className="m-0 text-white border-0 py-3 px-3">{value}</th>
  ) : (
    <td className="m-0 text-white border-0 py-3 px-3">{`${isDollar ? "$" : ""}${
      isNumber && !value ? 0 : value
    }`}</td>
  );

const RenderSingleResultItem = ({
  label,
  keyName,
  completeStockDetails,
  isHeader = false,
  isDollar,
  isNumber,
}) => {
  return (
    <tr
      style={{
        ...(isHeader && { background: "#181818" }),
      }}
    >
      <Item isHeader={isHeader} value={label} />
      {completeStockDetails &&
        Object.keys(completeStockDetails)?.map((item) => {
          const obj = completeStockDetails[item];
          return (
            <Item
              isHeader={isHeader}
              value={obj[keyName]}
              isDollar={isDollar}
              isNumber={isNumber}
            />
          );
        })}
    </tr>
  );
};

export default RenderSingleResultItem;

import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_PROFILE,
  GET_MARKET_DETAILS,
  GET_MARKET_DETAILS_ERROR,
  GET_MARKET_DETAILS_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_SUCCESS,
  GET_USER_BY_WALLET,
  GET_USER_BY_WALLET_FAIL,
  GET_USER_BY_WALLET_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_FAIL,
  PUT_PROFILE_IMAGE,
  PUT_PROFILE_IMAGE_FAIL,
  PUT_PROFILE_IMAGE_SUCCESS,
  PUT_PROFILE_SUCCESS,
  RESEND_OTP,
  RESEND_OTP_ERROR,
  RESEND_OTP_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS
} from "./actionTypes";

export const getProfile = (user, navigate) => {
  return {
    type: GET_PROFILE,
    payload: { user, navigate },
  };
};

export const getProfileSuccess = (msg) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const getProfileError = (error) => {
  return {
    type: GET_PROFILE_ERROR,
    payload: error,
  };
};

export const editProfile = (user, navigate) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, navigate },
  };
};

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const putChangePwd = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, callback },
  };
};

export const putChangePwdSuccess = (msg) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const putChangePwdError = (error) => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  };
};

export const putProfile = (data, callback) => ({
  type: PUT_PROFILE,
  payload: { data, callback },
});

export const putProfileSuccess = (data) => ({
  type: PUT_PROFILE_SUCCESS,
  payload: data,
});

export const putProfileFail = (error) => ({
  type: PUT_PROFILE_FAIL,
  payload: error,
});

export const putProfileImage = (data, callback) => ({
  type: PUT_PROFILE_IMAGE,
  payload: { data, callback },
});

export const putProfileImageSuccess = (data) => ({
  type: PUT_PROFILE_IMAGE_SUCCESS,
  payload: data,
});

export const putProfileImageFail = (error) => ({
  type: PUT_PROFILE_IMAGE_FAIL,
  payload: error,
});

export const getUserByWallet = (walletAddress) => ({
  type: GET_USER_BY_WALLET,
  payload: walletAddress,
});

export const getUserByWalletSuccess = (data) => ({
  type: GET_USER_BY_WALLET_SUCCESS,
  payload: data,
});

export const getUserByWalletFail = (error) => ({
  type: GET_USER_BY_WALLET_FAIL,
  payload: error,
});
//verify otp
export const verifyOtp = (data, callback) => {
  return {
    type: VERIFY_OTP,
    payload: { data, callback },
  };
};

export const verifyOtpSuccess = (msg) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: msg,
  };
};

export const verifyOtpError = (error) => {
  return {
    type: VERIFY_OTP_ERROR,
    payload: error,
  };
};
//resend otp
export const resendOtp = (data, callback) => {
  return {
    type: RESEND_OTP,
    payload: { data, callback },
  };
};

export const resendOtpSuccess = (msg) => {
  return {
    type: RESEND_OTP_SUCCESS,
    payload: msg,
  };
};

export const resendOtpError = (error) => {
  return {
    type: RESEND_OTP_ERROR,
    payload: error,
  };
};

//resetpassword
export const resetPassword = (data, callback) => {
  return {
    type: RESET_PASSWORD,
    payload: { data, callback },
  };
};

export const resetPasswordSuccess = (msg) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: msg,
  };
};

export const resetPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload: error,
  };
};

export const getMarketDetails = (data, navigate) => {
  return {
    type: GET_MARKET_DETAILS,
    payload: { data, navigate },
  };
};

export const getMarketDetailsSuccess = (msg) => {
  return {
    type: GET_MARKET_DETAILS_SUCCESS,
    payload: msg,
  };
};

export const getMarketDetailsError = (error) => {
  return {
    type: GET_MARKET_DETAILS_ERROR,
    payload: error,
  };
};

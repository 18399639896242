export default () => {
  const obj = JSON.parse(localStorage.getItem("authUser"));
  if (obj && obj.token) {
    return {
      headers: {
        token: `${obj.token}`, // Assuming you need to use Bearer token
        customerid: obj._id,
      },
    };
  } else {
    return {};
  }
};

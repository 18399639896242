import { Button, Form } from "react-bootstrap";
// css
import styles from "./Header.module.scss";

// img

import { getAllStock } from "helpers/backend_helper";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

let timeOutId = null;

const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const navigate = useNavigate();
  const searchHandler = async () => {
    try {
      const res = await getAllStock({ search: searchValue });

      console.log(res, "res123");

      if (res?.status === "success") {
        setSearchResult(res?.data);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const useDebounce = () => {
    if (!searchValue) {
      setSearchResult([]);
      return;
    }

    clearTimeout(timeOutId);

    timeOutId = setTimeout(() => {
      searchHandler();
    }, 400);
  };

  useEffect(() => {
    useDebounce();
  }, [searchValue]);

  useEffect(() => {
    const emptyHandler = () => {
      setSearchResult([]);
    };

    window.addEventListener("click", emptyHandler);

    return () => window.removeEventListener("click", emptyHandler);
  }, []);

  return (
    <div className="position-relative">
      <Form className={`${styles.searchForm} position-relative`}>
        <div className="iconWithText position-relative">
          <Form.Control
            type="search"
            placeholder="Search Company, Stock Name"
            className=" rounded-pill"
            aria-label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue((p) => e.target.value)}
          />
          <Button
            variant="transparent"
            className="border-0 icn p-0 position-absolute darkLightRed cursorPointer"
            style={{ left: 10 }}    
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M14.4563 13.4348L11.8345 10.7774C12.6963 9.69779 13.1701 8.35978 13.1797 6.97841C13.1898 5.74821 12.8349 4.54264 12.1598 3.51415C11.4848 2.48567 10.5199 1.68046 9.38719 1.20034C8.2545 0.720234 7.00487 0.586788 5.79634 0.81688C4.5878 1.04697 3.47464 1.63027 2.59761 2.49301C1.72059 3.35575 1.11909 4.45919 0.869178 5.66378C0.61927 6.86837 0.732177 8.12002 1.19362 9.26044C1.65507 10.4009 2.44432 11.3788 3.46159 12.0707C4.47885 12.7626 5.67843 13.1372 6.90863 13.1473C8.28997 13.1604 9.63558 12.7086 10.7292 11.8647L13.3432 14.5298C13.4149 14.6033 13.5004 14.6618 13.5948 14.7021C13.6892 14.7423 13.7907 14.7635 13.8933 14.7643C13.996 14.7652 14.0978 14.7457 14.1928 14.707C14.2879 14.6683 14.3744 14.6111 14.4472 14.5389C14.5207 14.4672 14.5793 14.3817 14.6195 14.2872C14.6598 14.1928 14.6809 14.0914 14.6818 13.9887C14.6826 13.8861 14.6631 13.7843 14.6244 13.6892C14.5857 13.5942 14.5286 13.5077 14.4563 13.4348V13.4348ZM2.2947 6.88903C2.30228 5.96638 2.58328 5.06669 3.10218 4.30375C3.62107 3.5408 4.35456 2.94886 5.20988 2.60277C6.06519 2.25669 7.00393 2.17201 7.90737 2.35944C8.81082 2.54687 9.63839 2.998 10.2854 3.65577C10.9325 4.31354 11.37 5.14841 11.5425 6.05481C11.7151 6.96121 11.615 7.89843 11.255 8.74795C10.8949 9.59747 10.291 10.3211 9.51959 10.8274C8.74823 11.3337 7.84405 11.5999 6.9214 11.5923C5.68416 11.5822 4.50164 11.0809 3.63396 10.1989C2.76629 9.31686 2.28454 8.12627 2.2947 6.88903Z"
                fill="currentColor"
              />
            </svg>
          </Button>
        </div>
      </Form>

      <div
        className="position-absolute top-100 bg-black w-100 "
        style={{
          zIndex: 1000,
        }}
      >
        <>
          {searchResult?.map((list) => {
            return (
              <div
                className="d-flex align-items-center gap-10 p-2 w-100 cursorPointer"
                onClick={() => {
                  navigate("/stock-complete-details/" + list?.symbol);
                }}
              >
                <div className="imgWrp"></div>
                <img src={list?.imgUrlDark} className="stock-list-size" />
                <div className="content">
                  <h6 className=" m-0 fw-bold pb-2">{list?.symbol}</h6>
                  <p className="text-muted m-0">{list?.shortName}</p>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default Search;

import { useTheme } from "Contexts/ThemeContexts";
import moment from "moment";
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { Form } from "react-bootstrap";

const DateFilter = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  showTypeFilter = false,
  selectedType,
  onChangeSelectType,
  loader,
}) => {
  const { isDarkMode } = useTheme();
  const onChangeType = (e) => {
    onChangeSelectType((p) => e.target.value);
  };
  return (
    <div className="py-2">
      <p className="m-0  fw-sbold">Select your date Range</p>
      <Row>
        <Col lg="3" md="4" sm="6" className="my-2">
          <input
            type="date"
            className={`form-control box ${isDarkMode && "bg-black "}`}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
            style={{ fontSize: 12 }}
          />
        </Col>
        <Col lg="3" md="4" sm="6" className="my-2">
          <input
            type="date"
            className={`form-control box ${isDarkMode && "bg-black "}`}
            style={{ fontSize: 12 }}
            value={endDate}
            max={moment().format("YYYY-MM-DD")}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </Col>
        {showTypeFilter && (
          <Col lg="3" md="4" sm="6" className="my-2">
            <Form.Select
              className={`form-control box ${isDarkMode && "bg-black "}`}
              aria-label="Default select example"
              name="currency"
              onChange={onChangeType}
              value={selectedType || ""}
            >
              {[
                { label: "Deposit", value: "incoming" },
                { label: "Withdrawals", value: "withdrawl" },
              ]?.map((item) => {
                return (
                  <option
                    className={` ${isDarkMode && "bg-black "}`}
                    value={item?.value}
                  >
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        )}
        <Col
          lg="3"
          md="4"
          sm="6"
          className="my-2 d-flex flex-column justify-content-center align-items-center"
        >
          {loader && <Spinner />}
        </Col>
      </Row>
    </div>
  );
};

export default DateFilter;

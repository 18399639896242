export const STRINGS = {
  marketOpen: "Market Open",
  marketClosed: "Market Closed",
  preMarket: "Pre Market",
  AfterMarket: "After Market",

  // become affiliate
  RejectedAffiliateDes: "Your Affiliated Request is rejected",
  ApprovedAffiliateDes: "Your Affiliated Request is Approved",
  PendingAffiliateDes:
    "Your request is pending. Please wait, and we will contact you shortly.",
  AffiliatedCodeIs: "Your Affiliated code is: ",

  GMTHours: 'New York Hours',
  GMTWeek: 'Day Of Week/New York Hours',
  choseDayOfMonth: 'Chose the day of the month',

};

import { all, fork } from "redux-saga/effects";
import Alert from "./Alert/saga";

// Authentication
import Login from "./auth/login/saga";
import ForgotPassword from "./auth/forgetpwd/saga";
import Profile from "./auth/profile/saga";
import Home from "./home/saga";
import File from "./File/saga";
import CMS from "./cms/saga";
import Basic from "./basic/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(Login),
    fork(ForgotPassword),
    fork(Profile),
    fork(Home),
    fork(File),
    fork(CMS),
    fork(Basic),
  ]);
}

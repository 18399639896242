import React from "react";
import { Button } from "react-bootstrap";
import styles from "./stockCompare.module.scss";

const StockDetailsBox = ({
  stockDetail,
  setData,
  showCross = true,
  SetShowResult,
}) => {
  return (
    <div
      className={`${styles.btn} btn d-flex align-items-center justify-content-between gap-10 px-2 commonBtn bg-black    `}
    >
      <div className="d-flex gap-10">
        <p className="m-0 text-white fw-sbold">{stockDetail?.symbol}</p>
        <img
          src={stockDetail?.imgUrlDark}
          style={{
            width: 20,
            height: 20,
            objectFit: "contain",
          }}
        />
      </div>
      {showCross && (
        <Button
          variant="transparnet"
          className="border-0 p-0"
          onClick={() => {
            setData(null);
            SetShowResult && SetShowResult(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
          >
            <path
              d="M8.28 2.94L10.36 -9.53674e-07H11.88V0.0399997L7.14 6.84L12.2 13.96V14H10.56L8.28 10.76L6.22 7.68L4.04 10.9L1.84 14H0.24V13.96L5.3 6.84L0.56 0.0399997V-9.53674e-07H2.12L4.24 3.02L6.22 5.98L8.28 2.94Z"
              fill="white"
            />
          </svg>
        </Button>
      )}
    </div>
  );
};

export default StockDetailsBox;

import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

// img
import s1 from "../../../../Assets/images/s1.png";
import user from "../../../../Assets/images/user.png";

const Transaction = () => {
  const data = [
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "complete",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "canceled",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "finished",
    },
    {
      name: "Karla Aguilera",
      types: "Statement",
      amount: "$220",
      date: "Aug 02, 2022",
      status: "warning",
    },
  ];
  return (
    <>
      <section className="transaction py-2 position-relativ2">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="top d-flex align-items-center justify-content-between gap-10 flex-wrap">
                <div className="left">
                  <h5 className="m-0 fw-bold text-white">
                    Transaction History
                  </h5>
                </div>
                <div className="right">
                  <ul className="list-unstyled ps-0 mb-0 d-flex align-items-start gap-10 flex-wrap tableFilters">
                    <li className="">
                      <Button className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="16"
                          viewBox="0 0 18 16"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.0803 13.5928H0.779297C0.365297 13.5928 0.0292969 13.2568 0.0292969 12.8428C0.0292969 12.4288 0.365297 12.0928 0.779297 12.0928H7.0803C7.4943 12.0928 7.8303 12.4288 7.8303 12.8428C7.8303 13.2568 7.4943 13.5928 7.0803 13.5928Z"
                            fill="#7A7A7A"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.1906 3.90039H9.89062C9.47662 3.90039 9.14062 3.56439 9.14062 3.15039C9.14062 2.73639 9.47662 2.40039 9.89062 2.40039H16.1906C16.6046 2.40039 16.9406 2.73639 16.9406 3.15039C16.9406 3.56439 16.6046 3.90039 16.1906 3.90039Z"
                            fill="#7A7A7A"
                          />
                          <mask
                            id="mask0_1_5008"
                            style={{ maskType: "luminance" }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="7"
                            height="7"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 0H6.2258V6.1916H0V0Z"
                              fill="white"
                            />
                          </mask>
                          <g mask="url(#mask0_1_5008)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M3.113 1.5C2.224 1.5 1.5 2.216 1.5 3.097C1.5 3.977 2.224 4.692 3.113 4.692C4.003 4.692 4.726 3.977 4.726 3.097C4.726 2.216 4.003 1.5 3.113 1.5ZM3.113 6.192C1.397 6.192 0 4.804 0 3.097C0 1.39 1.397 0 3.113 0C4.83 0 6.226 1.39 6.226 3.097C6.226 4.804 4.83 6.192 3.113 6.192Z"
                              fill="#7A7A7A"
                            />
                          </g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.3874 11.208C13.4974 11.208 12.7734 11.924 12.7734 12.804C12.7734 13.685 13.4974 14.4 14.3874 14.4C15.2764 14.4 15.9994 13.685 15.9994 12.804C15.9994 11.924 15.2764 11.208 14.3874 11.208ZM14.3874 15.9C12.6704 15.9 11.2734 14.511 11.2734 12.804C11.2734 11.097 12.6704 9.70801 14.3874 9.70801C16.1034 9.70801 17.4994 11.097 17.4994 12.804C17.4994 14.511 16.1034 15.9 14.3874 15.9Z"
                            fill="#7A7A7A"
                          />
                        </svg>
                      </Button>
                    </li>
                    <li className="">
                      <Form.Select
                        className="btn"
                        aria-label="Default select example"
                      >
                        <option>Last Month</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <div className="table-responsive commonTable">
                  <table className="table m-0">
                    <thead>
                      <tr>
                        <th className="border-0 bg-transparent fw-normal">
                          Name
                        </th>
                        <th className="border-0 bg-transparent fw-normal">
                          Types
                        </th>
                        <th className="border-0 bg-transparent fw-normal">
                          Amount
                        </th>
                        <th className="border-0 bg-transparent fw-normal">
                          Date
                        </th>
                        <th className="border-0 bg-transparent fw-normal">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length > 0 &&
                        data.map((item, key) => (
                          <tr>
                            <td className="border-0 py-2">
                              <div className="d-flex align-items-center gap-10">
                                <div className="imgWrp">
                                  <img
                                    src={user}
                                    alt=""
                                    className="img-fluid rounded-circle"
                                  />
                                </div>
                                <div className="content">
                                  <h6 className="text-white m-0 fw-bold">
                                    {item.name}
                                  </h6>
                                </div>
                              </div>
                            </td>
                            <td className="border-0 py-2">
                              <p className="text-muted m-0">{item.types}</p>
                            </td>
                            <td className="border-0 py-2">
                              <p className="text-muted m-0">{item.amount}</p>
                            </td>
                            <td className="border-0 py-2">
                              <p className="text-muted m-0">{item.date}</p>
                            </td>
                            <td className="border-0 py-2">
                              <p
                                className={`${
                                  item.status == "complete" && "greenLabel"
                                } ${
                                  item.status == "warning" && "yellowLabel"
                                } ${
                                  item.status == "finished" && "blueLabel"
                                }  ${
                                  item.status == "canceled" && "redLabel"
                                } px-2 d-inline-block text-capitalize rounded text-center labelTag  m-0`}
                              >
                                {item.status}
                              </p>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Transaction;

import { Button } from "react-bootstrap";
import { useState ,  useEffect } from "react";

const CopyToClip = ({ value }) => {
  const [isCopied, sertIsCopied] = useState(false);
  const copyToClipboard = (textToCopy) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    sertIsCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      sertIsCopied(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <Button
      style={{ right: 10 }}
      variant="transparent"
      className="border-0 p-0 icn"
      onClick={() => copyToClipboard(value)}
    >
      {!isCopied ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="15"
          viewBox="0 0 13 15"
          fill="none"
        >
          <path
            d="M0.999999 4.9L1 12.7C1 13.418 1.5794 14 2.29412 14L8.76471 14M4.88235 1L10.7059 1C11.4206 1 12 1.58201 12 2.3L12 10.1C12 10.818 11.4206 11.4 10.7059 11.4L4.88235 11.4C4.16763 11.4 3.58824 10.818 3.58824 10.1L3.58823 2.3C3.58823 1.58201 4.16763 1 4.88235 1Z"
            stroke="#919191"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        "copied"
      )}
    </Button>
  );
};

export default CopyToClip;

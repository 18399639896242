import React, { useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import {
  FormFeedback, Input,
} from "reactstrap";
import Switch from "react-switch";
// Formik validation
import * as Yup from "yup"
import { toast } from "react-toastify";
import { useFormik } from "formik"
// css
import styles from "../../setting.module.scss";
import { useTheme } from "Contexts/ThemeContexts";

import { putChangePwd, submitKyc } from "store/actions"
import { useSelector, useDispatch } from "react-redux"


const SettingTab = () => {
  const { loading } = useSelector(state => state.Profile)

  const dispatch = useDispatch()
  const { isDarkMode, toggleTheme } = useTheme();

  const [checked, setChecked] = useState(false);

  const handleChange = (checked) => {
    toggleTheme(checked);
  };
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setcPasswordType] = useState("password");

  // const togglePassword = () => {
  //   if (passwordType === "password") {
  //     setPasswordType("text");
  //     return;
  //   }
  //   setPasswordType("password");
  // };

  // const ctogglePassword = () => {
  //   if (cpasswordType === "password") {
  //     setcPasswordType("text");
  //     return;
  //   }
  //   setcPasswordType("password");
  // };
    // update password
    const validation = useFormik({
      // enableReinitialize 
      enableReinitialize: true,
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
  
      validationSchema: Yup.object({
        oldPassword: Yup.string().required("Please enter your password").min(6, "Password must be at most 6 characters long"),
        newPassword: Yup.string().required("Please enter your password").min(6, "Password must be at most 6 characters long").notOneOf([Yup.ref("old_password")], "New password must be different from the old password"),
        confirmPassword: Yup.string()
        .required("Please confirm your password")
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
      }),
  
      onSubmit: (values, { resetForm }) => {
        const callback = response => {
          const { message } = response
          if (response.status == "success") {
            toast.success(message)
            resetForm();
          }
        }
        dispatch(putChangePwd(values, callback))
      },
    })

  return (
    <>
      <div className="py-2">
        <ul className="list-unstyled ps-0 mb-0">
          <li className="py-2">
            <div className="box p-2 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Change Theme</p>
              <div className="right text-center">
                <Switch
                  onColor="#CF0A10"
                  onChange={handleChange}
                  checked={isDarkMode}
                  uncheckedIcon=""
                  checkedIcon=""
                />
                <p className="m-0  fw-sbold" style={{ lineHeight: "14px" }}>
                  {isDarkMode ? "Dark" : "Light"}
                  
                </p>
              </div>
            </div>
          </li>
          {/* <li className="py-2">
            <div className="box p-2 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Enable Password</p>
              <div className="right text-center">
                <Switch
                  onColor="#CF0A10"
                  onChange={handleChange}
                  checked={checked}
                  uncheckedIcon=""
                  checkedIcon=""
                />
              </div>
            </div>
          </li> */}
          <li className="py-2">
            <Accordion className={`${styles.accordion}`} defaultActiveKey="0">
              <Accordion.Item className="box p-2 rounded currentColor">
                <Accordion.Header className={`${styles.header}`}>
                  Change Password
                </Accordion.Header>
                <Accordion.Body>
                 <Form
                  className="setting_profile"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    validation.setTouched({
                      oldPassword: true,
                      newPassword: true,
                      confirmPassword: true,
                    });
                    return false
                  }}
                >
                    <Row>
                      <Col lg="4" sm="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 pb-1 ">
                          Current Password
                        </label>
                        <Input
                          className={`${styles.formControl} form-control rounded-pill px-4`}
                          name="oldPassword"
                          value={validation.values.oldPassword || ""}
                          type={passwordType}
                          placeholder="*********"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.oldPassword &&
                            validation.errors.oldPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.oldPassword &&
                          validation.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.oldPassword}
                            </FormFeedback>
                      ) : null}
                      </Col>
                      <Col lg="4" sm="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 pb-1 ">
                          New Password
                        </label>
                        <Input
                          className={`${styles.formControl} form-control rounded-pill px-4`}
                          value={validation.values.newPassword || ""}
                          type={passwordType}
                          name="newPassword"
                          placeholder="*********"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.newPassword &&
                            validation.errors.newPassword
                              ? true
                              : false
                          }
                        />
                            {validation.touched.newPassword &&
                            validation.errors.newPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.newPassword}
                              </FormFeedback>
                        ) : null}
                        
                      </Col>
                      <Col lg="4" sm="6" className="my-2">
                        <label htmlFor="" className="form-label m-0 pb-1 ">
                          Confirm Password
                        </label>
                        <Input
                          className={`${styles.formControl} form-control rounded-pill px-4`}
                          name="confirmPassword"
                          value={validation.values.confirmPassword || ""}
                          type={cpasswordType}
                          placeholder="*********"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                          {validation.touched.confirmPassword &&
                            validation.errors.confirmPassword ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirmPassword}
                              </FormFeedback>
                        ) : null}

                      </Col>
                      <Col lg="4" sm="6" className="my-2">
                        <Button
                          className="d-flex align-items-center w-100 fw-sbold justify-content-center commonBtn"
                          style={{ height: 50 }}
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Loading…" : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </li>
          {/* <li className="py-2">
            <div className="box p-2 rounded d-flex align-items-center justify-content-between">
              <p className="m-0 ">Set Passcode</p>
              <div className="right text-center">
                <Button className="border-0 p-2" variant="transparent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                  >
                    <path
                      d="M2 5.24537e-07L8 6L2 12L0.6 10.6L5.2 6L0.599999 1.4L2 5.24537e-07Z"
                      fill="white"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default SettingTab;

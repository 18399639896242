import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

// style
import styles from "./support.module.scss";

// img
import support from "../../../Assets/images/support.png";
import {
  FormFeedback,Input, Textarea
} from "reactstrap";
import { toast } from "react-toastify";
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux"
// actions
import {  addHelpSupport } from "store/actions"
import Loader from "components/Loader";

const Support = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.Basic)
  const { userProfile } = useSelector(state => state.Profile)
  const validation = useFormik({
    enableReinitialize: true,
      initialValues: {
        message:  "",
        email: "",
      },

      validationSchema: Yup.object({
        email: Yup.string().required("Please enter your email").email("Please enter a valid email address"),
        message: Yup.string().required("Required"),
      }),
      onSubmit: (values, {resetForm}) => {
      let finalObj= {
        ...values,
        byAdmin : false,
        message_type: "email",
        userId: userProfile?._id
      }
      const callback = response => {
          const { message } = response
          if (response.status == "success") {
              toast.success(message)
              resetForm();
              navigate("/support")
          }
        } 
    dispatch(addHelpSupport(finalObj, callback))
    },
  })
  return (
    <>
{loading && <Loader />}
      <section className={`${styles.support} Support position-relative py-2`}>
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="box p-3 px-lg-5 p-md-4 position-relative">
                {/* <Row>
                  <Col lg="4" sm="6" className="my-3">
                    <div className="cardCstm h-100">
                      <div className="icnWrp mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                        >
                          <rect
                            x="0.369141"
                            y="0.0681152"
                            width="35.3011"
                            height="35.3011"
                            rx="17.6506"
                            fill="black"
                          />
                          <g clip-path="url(#clip0_1_3425)">
                            <path
                              d="M25.3758 13.3083C25.3758 12.4993 24.7139 11.8374 23.9049 11.8374H12.1379C11.3289 11.8374 10.667 12.4993 10.667 13.3083M25.3758 13.3083V22.1336C25.3758 22.9425 24.7139 23.6044 23.9049 23.6044H12.1379C11.3289 23.6044 10.667 22.9425 10.667 22.1336V13.3083M25.3758 13.3083L18.0214 18.4564L10.667 13.3083"
                              stroke="#860000"
                              stroke-width="1.47088"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_3425">
                              <rect
                                width="17.6506"
                                height="17.6506"
                                fill="white"
                                transform="translate(9.19531 8.89502)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h6 className= m-0 pb-2">
                        Lorem Ipsum is simply dummy text of.
                      </h6>
                      <p className="m-0 fw-light">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer.
                      </p>
                    </div>
                  </Col>
                </Row> */}
                <Row className="justify-content-center">
                  <Col lg="4" md="6" sm="8" className="my-2">
                    <div className="py-2">
                      <div className="imgWrp text-center">
                        <img src={support} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="py-2">
                      <div className={`${styles.head} Head text-center`}>
                        <h6 className="m-0 py-2 fw-bold">Need Some Help?</h6>
                        <p className="m-0 py-2 fw-sbold">
                          Get Lost? Don’t Know How to use? Feel Free to get in
                          touch with us
                        </p>
                      </div>
                    </div>
                    <Form
                      className="setting_profile"
                        onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            validation.setTouched({
                              email: true,
                              message: true,
                            });
                            return false
                        }}
                      >
                      <div className="py-1">
                        <Input
                          type="email"
                          name="email"
                          placeholder="Your Email"
                          className={`${styles.formControl} form-control text-center rounded fw-sbold text-dark`}
                          value={validation.values.email || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                              validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                              <FormFeedback type="invalid">
                              {validation.errors.email}
                              </FormFeedback>
                        ) : null}
                      </div>
                      <div className="py-1">
                        <Input
                          name="message"
                          placeholder="Your message here..."
                          className={`${styles.formControl} textarea-field h-auto form-control text-center rounded fw-sbold text-dark`}
                          value={validation.values.message || ""}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                              validation.touched.message &&
                              validation.errors.message
                              ? true
                              : false
                          }
                        />
                        {validation.touched.message &&
                            validation.errors.message ? (
                              <FormFeedback type="invalid">
                              {validation.errors.message}
                              </FormFeedback>
                        ) : null}
                      </div>
                      <div className="py-1">
                        <Button className="d-flex w-100 align-items-center justify-content-center commonBtn rounded-pill" type="submit">
                        {loading ? "Loading…" : "Submit"}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Support;

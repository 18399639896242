import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

// css
import styles from "./stockDetailPop.module.scss";
import { stockDetailApi } from "helpers/backend_helper";
import c1 from "../../../Assets/images/c2.png";
import { toast } from "react-toastify";
import { SET_ALERT_PRICE } from "helpers/backend_helper";

// img

const PriceAlertModel = ({ model, setModel, stockView }) => {
  const [loader, setLoader] = useState(false);
  const handleSDetail = () =>
    setModel((p) => ({ stockDetails: null, open: false }));
  const [price, setPrice] = useState(stockView?.price || 0);

  useEffect(() => {
    setPrice(stockView?.price);
  }, [stockView]);

  const submitHandler = async () => {
    if (isNaN(price)) {
      return toast.error("price should numeric");
    }
    let body = {
      // symbol: stockDetails?.symbol,
      symbol: stockView?.symbol,
      price,
    };

    try {
      const res = await SET_ALERT_PRICE(body);
      if (res.status === "success") {
        toast.success(res.message);
        setModel((p) => ({ open: false, stockDetails: null }));
      } else {
        toast.error(
          res?.message || res?.error_description || "something went wrong"
        );
      }
      console.log();
    } catch (error) {
      console.log(error, "errro");
      toast.error("something went wrong");
    }
  };

  return (
    <>
      <Modal
        show={model?.open}
        className={`${styles.StockDetailPop}`}
        onHide={handleSDetail}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <Button
            onClick={handleSDetail}
            className="border-0 p-0 position-absolute"
            style={{ right: -10, top: -10 }}
            variant="transparent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <circle cx="15" cy="15" r="15" fill="white" />
              <path
                d="M10.2 21L9 19.8L13.8 15L9 10.2L10.2 9L15 13.8L19.8 9L21 10.2L16.2 15L21 19.8L19.8 21L15 16.2L10.2 21Z"
                fill="black"
              />
            </svg>
          </Button>
          <div className="content d-flex  flex-column justify-content-center gap-10  py-5">
            <div className="py-2 mt-2">
              <h5 className="m-0 text-white fw-bold">Price Alert</h5>
              <div className="d-flex gap-10 align-items-center">
                <img
                  src={
                    stockView?.companyOverview?.imgUrl
                      ? stockView?.companyOverview?.imgUrl
                      : c1
                  }
                  alt=""
                  style={{ height: 50, width: 50 }}
                  className="img-fluid rounded object-fit-contain"
                />
                <h5>{stockView?.symbol}</h5>
              </div>
            </div>
            <div className="py-2 mt-2">
              <h5 className="m-0 text-white fw-bold mb-2">Price</h5>
              <div
                className=" p-2 d-flex rounded d-flex align-items-center justify-content-center"
                style={{
                  borderColor: "white",
                  borderWidth: 2,
                  borderStyle: "solid",
                }}
              >
                <div
                  onClick={() => setPrice((p) => Number(p - 1))}
                  style={{ fontSize: 35, cursor: "pointer" }}
                  className="pointer-cursor"
                >
                  -
                </div>
                <input
                  type="text"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "black",
                    color: "white",
                    padding: "0 0 0 5px",
                  }}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <div
                  onClick={() => setPrice((p) => Number(p + 1))}
                  style={{ fontSize: 35, cursor: "pointer" }}
                  className="pointer-cursor"
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.btnWrpper} text-center btnWrpper mt-3`}>
            <Button
              className="d-flex align-items-center w-100 mx-auto justify-content-center rounded fw-sbold commonBtn"
              disabled={!stockView}
              onClick={submitHandler}
            >
              Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PriceAlertModel;

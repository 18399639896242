import React, { useState, useEffect} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  FormFeedback,Input
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import Dropzone from "components/Common/DropZone"
// css
import styles from "../../setting.module.scss";
import { toast } from "react-toastify";

// img
import user from "../../../../../Assets/images/user2.png";
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
//redux
import { useSelector, useDispatch } from "react-redux"
// actions
import { putProfile } from "store/actions"
const EditProfile = ({userProfile}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.Profile)
   //set phone number values
   const [phoneValue, setPhoneValue] = useState('')
   const [country, setCountry] = useState('');

   const validation = useFormik({
       enableReinitialize: true,
       initialValues: {
           first_name: userProfile?.first_name ? userProfile?.first_name : "",
           last_name: userProfile?.last_name ? userProfile?.last_name : "",
           email: userProfile?.email ? userProfile?.email : "",
           id_photo: userProfile?.id_photo ? userProfile?.id_photo : "",
           profileImage:userProfile?.profileImage ? userProfile?.profileImage : "",
           city:userProfile?.city ? userProfile?.city : "",
           country:userProfile?.country ? userProfile?.country : "",
           mobileNumber:userProfile?.mobileNumber ? userProfile?.countryCode+''+userProfile?.mobileNumber : "",
       },

       validationSchema: Yup.object({
           email: Yup.string().required("Please enter your email").email("Please enter a valid email address"),
       }),
       onSubmit: (values) => {
        const phoneNumberWithoutPlus =  phoneValue ? phoneValue.substring(country?.dialCode.length) : values.mobileNumber.substring(userProfile.mobileNumber.length) ;
        let finalObj= {
            ...values,
            mobileNumber: phoneNumberWithoutPlus,
            countryCode : country ? '+'+country?.dialCode : userProfile?.countryCode,
        }
        const callback = response => {
            const { message } = response
            if (response.status == "success") {
                toast.success(message)
                navigate("/setting")
            }
        } 
    dispatch(putProfile(finalObj, callback))
    },
})

const handlePhoneChange = (value, country) => {
    setPhoneValue(value);
    setCountry(country);
};
  return (
    <>
      <div className="py-2">
        <h6 className="m-0  fw-sbold">Personal Info</h6>
        <p className="m-0 text-muted">
          Update your photo and personal details here
        </p>
      </div>
      <div className="py-2">
          <Form
            className="setting_profile"
              onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
              }}
          >
          <Row>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Name</label>
                </Col>
                <Col lg="4" sm="6" className="my-2">
                  <Input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={validation.values.first_name || ""}
                    className={`${styles.formControl} form-control box bg-transparent text-white rounded`}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                        validation.touched.first_name &&
                        validation.errors.first_name
                        ? true
                        : false
                    }
                    disabled
                  />
                  {validation.touched.first_name &&
                      validation.errors.first_name ? (
                        <FormFeedback type="invalid">
                        {validation.errors.first_name}
                        </FormFeedback>
                  ) : null}
                  
                </Col>
                <Col lg="4" sm="6" className="my-2">
                  <Input
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    className={`${styles.formControl} form-control box bg-transparent text-white rounded`}
                    value={validation.values.last_name || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                        validation.touched.last_name &&
                        validation.errors.last_name
                        ? true
                        : false
                    }
                    disabled

                  />
                  {validation.touched.last_name &&
                      validation.errors.last_name ? (
                        <FormFeedback type="invalid">
                        {validation.errors.last_name}
                        </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {/* <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Id Proof</label>
                </Col>
                <Col lg="8" className="my-2">
                
                  <Dropzone
                      file={userProfile?.id_photo ? userProfile?.id_photo : validation.id_photo}
                      handleChange={validation.handleChange("id_photo")}
                      className="image-design"
                      
                  />
                  
                </Col>
              </Row>
            </Col> */}
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Email Address</label>
                </Col>
                <Col lg="8" className="my-2">
                  <Input
                    type="email"
                    placeholder="georgia.young@example.com"
                    className={`${styles.formControl} form-control box bg-transparent text-white rounded`}
                    name="email"
                    value={validation.values.email || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                        validation.touched.email &&
                        validation.errors.email
                        ? true
                        : false
                    }
                    disabled

                  />
                  {validation.touched.email &&
                      validation.errors.email ? (
                        <FormFeedback type="invalid">
                        {validation.errors.email}
                        </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Phone Number</label>
                </Col>
                <Col lg="8" className="my-2">
                  {/* <PhoneInput
                    inputProps={"ritesh"}
                    country={"us"}
                    value={phone}
                    onChange={setPhone}
                  /> */}
                   <PhoneInput
                        enableAreaCodes={true}
                        name="mobileNumber"
                        placeholder="Phone Number"
                        value={validation.values.mobileNumber || ""}
                        onChange={handlePhoneChange}
                        onBlur={validation.handleBlur}
                        maxLength={15}
                        className={`${phoneValue=='' && userProfile?.mobileNumber =='' ? 'is-invalid' : '' }`}
                        invalid={
                            validation.touched.mobileNumber &&
                            validation.errors.mobileNumber
                            ? true
                            : false
                        }
                    disabled

                    />
                    {validation.touched.mobileNumber && phoneValue=='' ? (
                      <FormFeedback type="invalid">
                          Please enter your phone number
                      </FormFeedback>
                    ) : null}
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">Country</label>
                </Col>
                {/* <Col lg="8" className="my-2">
                  <Form.Select
                    className={`${styles.formControl} form-control box bg-transparent  rounded`}
                    aria-label="Default select example"
                  >
                    <option className="text-dark">Select Country</option>
                    <option className="text-dark" value="1">
                      One
                    </option>
                    <option className="text-dark" value="2">
                      Two
                    </option>
                    <option className="text-dark" value="3">
                      Three
                    </option>
                  </Form.Select>
                </Col> */}
                <Col lg="8" className="my-2">
                   <Input
                    type="text"
                    name="country"
                    className={`${styles.formControl} form-control box bg-transparent text-white rounded`}
                    value={validation.values.country || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                        validation.touched.country &&
                        validation.errors.country
                        ? true
                        : false
                    }
                    disabled

                  />
                  {validation.touched.country &&
                    validation.errors.country ? (
                      <FormFeedback type="invalid">
                      {validation.errors.country}
                      </FormFeedback>
                  ) : null}
                  </Col>
              </Row>
            </Col>
            <Col lg="12" className="">
              <Row>
                <Col lg="4" className="my-2">
                  <label className="m-0  fw-sbold">City</label>
                </Col>
                <Col lg="8" className="my-2">
                  <Input
                    type="text"
                    name="city"
                    placeholder="San Mateo"
                    className={`${styles.formControl} form-control box bg-transparent text-white rounded`}
                    value={validation.values.city || ""}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                        validation.touched.city &&
                        validation.errors.city
                        ? true
                        : false
                    }
                    disabled

                  />
                  {validation.touched.city &&
                    validation.errors.city ? (
                      <FormFeedback type="invalid">
                      {validation.errors.city}
                      </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="btnWrpper text-end py-3">
            <Button className="d-inline-flex align-items-center justify-content-center commonBtn rounded" type="submit">
            {loading ? "Loading…" : "Update"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default EditProfile;

import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState, useCallback } from "react";

// css
import styles from "../../../layout/Auth/Auth.module.scss";
import OTPInput from "react-otp-input";

// img
import logo from "../../../Assets/images/logo.png";
import { toast } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux"
// actions
import { verifyOtp, resendOtp } from "store/actions";


const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.Profile)
  const [otpVerification, setOtpVerification] = useState(0)
  const location = useLocation();
  const receivedData = location?.state?.data?.data;
  const [otp, setOtp] = useState(receivedData?.OTP ? receivedData?.OTP : "");
  const resendOtp_ =  useCallback(
    evt => {
      evt.preventDefault()
      const callback = (response) => {
        const { message, data } = response
        if (response.status == "success") {
          toast.success(message)
          setOtp(data.OTP)
        }
      }
      let obj = {
        email : receivedData.email,
      }
    dispatch(resendOtp(obj, callback))
  },
  [receivedData, setOtp, dispatch, otpVerification]
)

const verify_otp =  useCallback(
  evt => {
    evt.preventDefault()
    if (loading) return
    const callback = (response) => {
      const { message, data } = response
      if (response.status == "success") {
        toast.success(message)
        // navigate(`/reset-password`)
        navigate("/reset-password", { state: { data:response } })

      }
    }
  let obj = {
    email : receivedData.email,
    OTP: otp,
  }
  dispatch(verifyOtp(obj, callback))
},
[otp, receivedData, loading, navigate, dispatch, otpVerification]

)
  return (
    <>
      <Col md="6" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
        >
          <span
            className="position-absolute graphic"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
          >
            <svg
              className="w-100 h-100"
              xmlns="http://www.w3.org/2000/svg"
              width="702"
              height="702"
              viewBox="0 0 702 702"
              fill="none"
            >
              <g opacity="0.3" filter="url(#filter0_f_0_599)">
                <circle cx="351" cy="351" r="117" fill="#CF0A10" />
              </g>
              <defs>
                <filter
                  id="filter0_f_0_599"
                  x="0"
                  y="0"
                  width="702"
                  height="702"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="117"
                    result="effect1_foregroundBlur_0_599"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <div className="formInner">
            <div
              className={`${styles.top} top py-2 text-center  position-relative`}
            >
              <h3 className="m-0 pb-2 fw-sbold head text-white">
                Email Verification
              </h3>
              <p className="m-0 text-white">
                Enter the 4-digit code sent to you at Email {receivedData && receivedData?.email}
              </p>
            </div>
            <Form className="mx-auto py-3" style={{ maxWidth: 350 }}>
              <Row>
                <Col lg="12" className="my-2">
                  <div className="otp">
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper text-center`}>
                    <Button
                    disabled={loading} 
                      // onClick={() => navigate("/reset-password")}
                      onClick={(e) => verify_otp(e)}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                    {loading ? "Loading…" : "Continue"}
                    </Button>
                    <Button
                      className="mt-3 themeClr fw-sbold border-0 p-0"
                      variant="transparent"
                      onClick={(e) => resendOtp_(e)}
                    >
                      Resend code
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </>
  );
};
export default EmailVerification;

import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
// Login Redux States
import {
  GET_PROFILE,
  PUT_PROFILE,
  PUT_PROFILE_IMAGE,
  GET_USER_BY_WALLET,
  CHANGE_PASSWORD,
  VERIFY_OTP,
  RESEND_OTP,
  RESET_PASSWORD,
  GET_MARKET_DETAILS,
} from "./actionTypes";
import {
  getProfileSuccess,
  getProfileError,
  putProfileSuccess,
  putProfileFail,
  putProfileImageSuccess,
  putProfileImageFail,
  getUserByWalletSuccess,
  getUserByWalletFail,
  putChangePwdSuccess,
  putChangePwdError,
  verifyOtpSuccess,
  verifyOtpError,
  resendOtpSuccess,
  resendOtpError,
  resetPasswordSuccess,
  resetPasswordError,
  getMarketDetailsError,
  getMarketDetailsSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import {
  putProfile,
  putProfileImage,
  getUserByWallet,
  putChangePassword,
  putProfileApi,
  getProfileApi,
  verifyOtpApi,
  resendOtpApi,
  resendPassApi,
  getMarketDetails,
} from "helpers/backend_helper";
import { getProfile } from "store/actions";
import { updateToken } from "helpers/api_helper";

function* onGetProfille({ payload: { data, callback } }) {
  try {
    const response = yield call(getProfileApi, data);
    if (response.status === "failure") {
      return yield put(getProfileError(response.message));
    }
    callback && callback(response);
    // yield put(getCurrentUser());

    yield put(getProfileSuccess(response.data));
  } catch (error) {
    yield put(getProfileError(error));
  }
}

function* onGetMarketDetails({ payload: { data, callback } }) {
  try {
    const response = yield call(getMarketDetails, data);
    if (response.status === "failure") {
      return yield put(getMarketDetailsError(response.message));
    }
    callback && callback(response);
    // yield put(getCurrentUser());

    yield put(getMarketDetailsSuccess(response.data));
  } catch (error) {
    yield put(getMarketDetailsError(error));
  }
}

function* editProfile({ payload: { data, callback } }) {
  try {
    const response = yield call(putProfileApi, data);
    callback(response);
    if (response.status === "failure") {
      return yield put(putProfileFail(response.message));
    }
    yield put(getProfile());
    yield put(putProfileSuccess(response));
  } catch (error) {
    yield put(putProfileFail("Internal Server Error"));
  }
}

function* editProfileImage({ payload: { data } }) {
  try {
    const response = yield call(putProfileImage, data);
    if (response.status === "failure") {
      return yield put(putProfileImageFail(response.message));
    }

    // yield put(getCurrentUser());
    yield put(putProfileImageSuccess(response));
  } catch (error) {
    yield put(putProfileImageFail("Internal Server Error"));
  }
}

function* onchangePassword({ payload: { data, callback } }) {
  try {
    const response = yield call(putChangePassword, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(putChangePwdError(response.message));
    }
    callback && callback(response);
    yield put(putChangePwdSuccess(response));
  } catch (error) {
    yield put(putChangePwdError("Internal Server Error"));
  }
}

function* onGetUserByWallet({ payload }) {
  try {
    const response = yield call(getUserByWallet, payload);

    if (response.status === "failure") {
      return yield put(getUserByWalletFail(response.message));
    }

    yield put(getUserByWalletSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(getUserByWalletFail("Internal Server Error"));
  }
}

function* onVerifyOtp({ payload: { data, callback } }) {
  try {
    const response = yield call(verifyOtpApi, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(verifyOtpError(response.message));
    }
    callback && callback(response);
    yield put(verifyOtpSuccess(response));
  } catch (error) {
    yield put(verifyOtpError("Internal Server Error"));
  }
}

function* onResendOtp({ payload: { data, callback } }) {
  try {
    const response = yield call(resendOtpApi, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(resendOtpError(response.message));
    }
    callback && callback(response);
    yield put(resendOtpSuccess(response));
  } catch (error) {
    yield put(resendOtpError("Internal Server Error"));
  }
}
function* onResendPassword({ payload: { data, callback } }) {
  try {
    const response = yield call(resendPassApi, data);
    if (response.status === "failure") {
      toast.error(response.message);
      return yield put(resetPasswordError(response.message));
    }
    callback && callback(response);
    yield put(resetPasswordSuccess(response));
  } catch (error) {
    yield put(resetPasswordError("Internal Server Error"));
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, onGetProfille);
  yield takeEvery(GET_MARKET_DETAILS, onGetMarketDetails);
  yield takeEvery(PUT_PROFILE, editProfile);
  yield takeEvery(PUT_PROFILE_IMAGE, editProfileImage);
  yield takeEvery(GET_USER_BY_WALLET, onGetUserByWallet);
  yield takeEvery(CHANGE_PASSWORD, onchangePassword);
  yield takeEvery(VERIFY_OTP, onVerifyOtp);
  yield takeEvery(RESEND_OTP, onResendOtp);
  yield takeEvery(RESET_PASSWORD, onResendPassword);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;

import { useTheme } from "Contexts/ThemeContexts";
import { transactionUpdate } from "helpers/backend_helper";
import { CONSTANT } from "helpers/constant";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { Col, Row } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function HistoryDetailModal({ show, setShow, type }) {
  const { userProfile } = useSelector((state) => state?.Profile);

  const [loading, setLoading] = useState(false);

  const close = () => {
    setShow((p) => ({
      ...p,
      show: false,
    }));
  };

  const details = show?.details;

  let userTimezone = userProfile?.timezone;

  const { isDarkMode } = useTheme();

  const handleCancelled = async () => {
    try {
      setLoading(true);

      let body = {
        _id: details?._id,
        status: "rejected", // optional
      };

      const res = await transactionUpdate(body);

      if (res.status === "failure") {
        setLoading(false);
        return toast.error(res?.message || "something went wrong!");
      } else {
        toast.success(res.message);
        setShow((p) => ({ ...p, show: false }));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      return toast.error("something went wrong!");
    }
  };

  return (
    <Modal
      show={show?.show}
      onHide={close}
      centered // Centers the modal
    >
      <div
        style={{
          backgroundColor: isDarkMode ? "#3A3A4750" : "white",
        }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div>Transaction Details</div>
            <div onClick={() => close()}>
              <IoIosCloseCircle size={20} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <RenderSingleItem
              label={"Date"}
              value={
                type === "history"
                  ? formatDateTime(details?.createdAt, userTimezone, "date")
                  : details?.date
              }
            />
            {type === "activity" && (
              <RenderSingleItem
                label={"Type"}
                value={details?.activity_type || "N/A"}
              />
            )}
            <RenderSingleItem label={"Status"} value={details?.status} />
            <RenderSingleItem
              label={"Amount"}
              value={type === "history" ? details?.amount : details?.net_amount}
            />
            {type === "history" && (
              <RenderSingleItem
                label={"Commission"}
                value={`$${details?.commission}`}
              />
            )}

            {type === "activity" && (
              <>
                <RenderSingleItem
                  label={"Symbol"}
                  value={details?.symbol || "N/A"}
                />
                <RenderSingleItem
                  label={"Quantity"}
                  value={details?.qty || "N/A"}
                />
                <RenderSingleItem
                  label={"Per Share Amount"}
                  value={
                    details?.per_share_amount
                      ? `$${details?.per_share_amount}`
                      : "N/A"
                  }
                />
              </>
            )}
          </Row>
          <Row className="">
            <RenderSingleItem
              label={"Description"}
              value={
                type === "history"
                  ? details?.text
                  : details?.description || "N/A"
              }
              size="12"
              textStyle={{
                textAlign: "start",
              }}
            />
            <RenderSingleItem
              label={"ID"}
              value={details?.transactionId || details?.id || "N/A"}
              size="12"
              textStyle={{
                textAlign: "start",
              }}
            />
          </Row>
          <Row>
            {type === "history" &&
            details?.type === "withdrawl" &&
            details?.status === "pending" ? (
              <>
                <Button
                  onClick={handleCancelled}
                  className="mt-4 d-inline-flex align-items-center justify-content-center commonBtn rounded"
                  type="button"
                >
                  {loading ? "Loading…" : "Cancel"}
                </Button>
              </>
            ) : null}
          </Row>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default HistoryDetailModal;

const RenderSingleItem = ({
  value,
  label,
  size = "6",
  textStyle = {
    textAlign: "center",
  },
}) => {
  const { isDarkMode } = useTheme();
  return (
    <Col xl={size} sm={size} className="my-2">
      <div
        className="box  h-100 cardCstm p-2  position-relative"
        style={{
          ...(!isDarkMode && {
            border: "black solid 1px",
          }),
        }}
      >
        <p
          className="m-0 fw-light  w-100"
          style={{
            ...textStyle,
          }}
        >
          {label}
        </p>
        <h6
          className="m-0 fw-sbold  w-100 "
          style={{
            ...textStyle,
            wordBreak : "break-all"
          }}
        >
          {value}
        </h6>
      </div>
    </Col>
  );
};

export const formatDateTime = (
  utcDateTime,
  timezone = "America/New_York",
  key
) => {
  // Define format strings based on the required key
  const formats = {
    date: "DD MMMM YYYY",
    date2: "DD-MM-YY",
    time: "hh:mm A",
    dateNTime: "DD MMMM YYYY hh:mm A",
  };

  // Validate if a correct format key is provided
  if (!formats[key]) {
    throw new Error(
      'Invalid format key provided. Use "date", "time", or "dateNTime".'
    );
  }

  // Perform the formatting
  return moment.utc(utcDateTime).tz(timezone).format(formats[key]);
};

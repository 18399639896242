import * as TYPES from "./actionTypes";

export const getNotification = (data, callback) => ({
  type: TYPES.GET_NOTIFICATION,
  payload: { data, callback },
});

export const getNotificationFail = (error) => ({
  type: TYPES.GET_NOTIFICATION_FAIL,
  payload: error,
});

export const getNotificationSuccess = (data) => ({
  type: TYPES.GET_NOTIFICATION_SUCCESS,
  payload: data,
});

export const getAppMessages = (data, callback) => ({
  type: TYPES.GET_MESSAGES,
  payload: { data, callback },
});

export const getAppMessagesFail = (error) => ({
  type: TYPES.GET_MESSAGES_FAIL,
  payload: error,
});

export const getAppMessagesSuccess = (data) => ({
  type: TYPES.GET_MESSAGES_SUCCESS,
  payload: data,
});

export const addHelpSupport = (data, callback) => ({
  type: TYPES.ADD_HELP_SUPPORT,
  payload: { data, callback },
});

export const addHelpSupportFail = (error) => ({
  type: TYPES.ADD_HELP_SUPPORT_FAIL,
  payload: error,
});

export const addHelpSupportSuccess = (data) => ({
  type: TYPES.ADD_HELP_SUPPORT_SUCCESS,
  payload: data,
});

export const allStockList = (data, callback) => ({
  type: TYPES.ALL_STOCK_LIST,
  payload: { data, callback },
});

export const allStockListFail = (error) => ({
  type: TYPES.ALL_STOCK_LIST_FAIL,
  payload: error,
});

export const allStockListSuccess = (data) => ({
  type: TYPES.ALL_STOCK_LIST_SUCCESS,
  payload: data,
});

export const openPositions = (data, callback) => ({
  type: TYPES.OPEN_POSITIONS,
  payload: { data, callback },
});

export const openPositionsFail = (error) => ({
  type: TYPES.OPEN_POSITIONS_FAIL,
  payload: error,
});

export const openPositionsSuccess = (data) => ({
  type: TYPES.OPEN_POSITIONS_SUCCESS,
  payload: data,
});

export const pendingPositions = (data, callback) => ({
  type: TYPES.PENDING_POSITIONS,
  payload: { data, callback },
});

export const pendingPositionsFail = (error) => ({
  type: TYPES.PENDING_POSITIONS_FAIL,
  payload: error,
});

export const pendingPositionsSuccess = (data) => ({
  type: TYPES.PENDING_POSITIONS_SUCCESS,
  payload: data,
});

export const scheduleOrder = (data, callback) => ({
  type: TYPES.SCHEDULE_ORDER,
  payload: { data, callback },
});

export const scheduleOrderFail = (error) => ({
  type: TYPES.SCHEDULE_ORDER_FAIL,
  payload: error,
});

export const scheduleOrderSuccess = (data) => ({
  type: TYPES.SCHEDULE_ORDER_SUCCESS,
  payload: data,
});

export const stockHistoryOrder = (data, callback) => ({
  type: TYPES.STOCK_HISTORY_ORDER,
  payload: { data, callback },
});

export const stockHistoryOrderFail = (error) => ({
  type: TYPES.STOCK_HISTORY_ORDER_FAIL,
  payload: error,
});

export const stockHistoryOrderSuccess = (data) => ({
  type: TYPES.STOCK_HISTORY_ORDER_SUCCESS,
  payload: data,
});

export const tradingActivities = (data, callback) => ({
  type: TYPES.TRADING_ACTIVITIES,
  payload: { data, callback },
});

export const tradingActivitiesFail = (error) => ({
  type: TYPES.TRADING_ACTIVITIES_FAIL,
  payload: error,
});

export const tradingActivitiesSuccess = (data) => ({
  type: TYPES.TRADING_ACTIVITIES_SUCCESS,
  payload: data,
});

export const stockSingleDetial = (data, callback) => ({
  type: TYPES.STOCK_SINGLE_DETAIL,
  payload: { data, callback },
});

export const stockSingleDetialFail = (error) => ({
  type: TYPES.STOCK_SINGLE_DETAIL_FAIL,
  payload: error,
});

export const stockSingleDetialFailSuccess = (data) => ({
  type: TYPES.STOCK_SINGLE_DETAIL_SUCCESS,
  payload: data,
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  handleDecimal,
  handleDecimalToViewOnly,
  isDecimal,
} from "helpers/helper"; // Adjust this import based on your project structure
import { CANCEL_ORDER, CLOSE_ORDER } from "helpers/backend_helper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { pendingPositions } from "store/actions";
import { CONSTANT } from "helpers/constant";

const StockCloseBox = ({ item, type }) => {
  const { stockView: stockDetails } = useSelector((state) => state.Basic);
  const stockPrice = handleDecimal(stockDetails?.price);
  const userData = useSelector((state) => state.Profile?.userProfile);
  const [percentage, setPercentage] = useState("100%");
  const [shareToSend, setShareToSend] = useState(item.qty);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 

  let orderType = item?.order_type;
  let timeInForce = item?.time_in_force;

  const PERCENTAGE_ARR = useMemo(
    () => [
      { _id: 1, label: "5%", value: "5%" },
      { _id: 2, label: "10%", value: "10%" },
      { _id: 3, label: "15%", value: "15%" },
      { _id: 4, label: "20%", value: "20%" },
      { _id: 5, label: "25%", value: "25%" },
      { _id: 6, label: "30%", value: "30%" },
      { _id: 7, label: "35%", value: "35%" },
      { _id: 8, label: "40%", value: "40%" },
      { _id: 9, label: "45%", value: "45%" },
      { _id: 10, label: "50%", value: "50%" },
      { _id: 11, label: "55%", value: "55%" },
      { _id: 12, label: "60%", value: "60%" },
      { _id: 13, label: "65%", value: "65%" },
      { _id: 14, label: "70%", value: "70%" },
      { _id: 15, label: "75%", value: "75%" },
      { _id: 16, label: "80%", value: "80%" },
      { _id: 17, label: "85%", value: "85%" },
      { _id: 18, label: "90%", value: "90%" },
      { _id: 19, label: "95%", value: "95%" },
      { _id: 20, label: "100%", value: "100%" },
    ],
    []
  );

  console.log(userData, "userData");

  const [state, setState] = useState({
    selectedOrderType: "Market", // Assuming "Market" for Full Closure
    shareNumber: item?.qty || 0,
    estimatedAmount: stockPrice,
    commission: 0.1 * stockPrice, // Example commission calculation
    closeBy: "SHARES", // Default option for Partial Closure
  });

  const {
    selectedOrderType,
    shareNumber,
    estimatedAmount,
    commission,
    closeBy,
  } = state;

  useEffect(() => {
    if (!selectedOrderType == "Market" && closeBy === "PERCENTAGE") {
      const newQty = (parseInt(percentage, 10) / 100) * Number(item.qty);
      setShareToSend(newQty);
    }
  }, [percentage, selectedOrderType, closeBy, item.qty]);

  const handleShareNumberChange = (value) => {
    const newAmount = value * stockPrice;
    setState((prev) => ({
      ...prev,
      shareNumber: value,
      estimatedAmount: handleDecimal(newAmount),
      commission: handleDecimal(0.1 * newAmount),
    }));
  };
  const getCurrentPrice = useCallback(() => {
    if (selectedOrderType == "Market")
      return item.qty * parseFloat(item.current_price);

    let amount = 0;
    if (state?.closeBy === "SHARES") {
      amount = shareNumber * item.current_price;
    } else {
      amount = (parseInt(percentage, 10) / 100) * item.qty * item.current_price;
    }

    return amount;
  }, [selectedOrderType, state, item.qty, item.current_price, percentage]);

  const getCommission = useCallback(() => {
    const estimatedAmount = getCurrentPrice() || 0; // Use 0 as default if getCurrentPrice() returns undefined
    let commission = (userData?.sellTradeCommission / 100) * estimatedAmount;

    if (commission < 0.05) {
      commission = 0.05;
    }

    return handleDecimal(commission, 2);
  }, [getCurrentPrice, userData?.sellTradeCommission]);

console.log(shareToSend , "share to send")


  const handleCloseOrder = async () => {
    try {
      setLoading(true);
      const estimatedAmount = getCurrentPrice();
      const body = {
        symbol: item.symbol,
        commission: getCommission(),
        avg_entry_price: item?.avg_entry_price,
        market_value: item?.market_value,
        cost_basis: item.cost_basis,
        unrealized_plpc: item.unrealized_plpc,
        current_price: item.current_price,
        lastday_price: item?.lastday_price,
        boughtPrice: item?.boughtPrice,
        executePrice: item?.executePrice,
        executeAmount: item?.executeAmount,
        // new key
        estimatedAmount: estimatedAmount,
      };
      if (selectedOrderType == "Market") {
        body["qty"] = item.qty;
      } else if (closeBy === "SHARES") {
        body["qty"] = parseFloat(shareNumber);
      // } else if (isDecimal(shareToSend)) {
      //   body["qty"] = parseFloat(shareToSend);
      } 
      else {
        body["percentage"] = percentage.replace("%", "");
      }

      console.log(body , "body")

      // setLoading(false);


      const response = await CLOSE_ORDER(body);

      if (response && response?.status == "success") {
        setLoading(false);

        toast.success(response?.message);
        navigate("/my-portfolio");
      } else {
        setLoading(false);
        toast.error(response?.message || response?.error_description);
      }
    } catch (error) {
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  const cancelPendingHandler = async (orderId) => {
    try {
      setLoading(true);
      const res = await CANCEL_ORDER({ orderId });

      if (res?.status === "success") {
        setLoading(false);
        toast.success(res.message);
        navigate("/my-portfolio");
      } else {
        setLoading(false);
        toast.error(res.message || "something went wrong");
      }
    } catch (error) {
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  return (
    <div className="py-2">
      {type == "open" ? (
        <div className="cardCstm box mt-3 px-3 py-3">
          <div className="top py-2">
            <p className="m-0 fw-sbold">
              {" "}
              {type == "open" ? "Close Order" : "Cancel Order"}
            </p>
            <div className="d-flex align-items-center gap-10 mt-3 navPillsTab navPillsTab2 ">
              <Button
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    selectedOrderType: "Market",
                  }))
                }
                className={`${
                  selectedOrderType === "Market" && "active"
                } nav-link w-50 rounded-pill`}
              >
                Full Closure
              </Button>
              <Button
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    selectedOrderType: "Limit",
                  }))
                }
                className={`${
                  selectedOrderType === "Limit" && "active"
                } nav-link w-50 rounded-pill`}
              >
                Partial Closure
              </Button>
            </div>
          </div>
          <div className="contentBody py-2">
            <Form>
              {selectedOrderType === "Limit" && (
                <div className="py-2">
                  <p className="fw-sbold pb-2">Close By</p>
                  <Form.Select
                    className="form-control bg-transparent"
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        closeBy: e.target.value,
                      }))
                    }
                    value={closeBy}
                  >
                    <option
                      value="SHARES"
                      style={{ color: "white", background: "black" }}
                    >
                      SHARES
                    </option>
                    <option
                      value="PERCENTAGE"
                      style={{ color: "white", background: "black" }}
                    >
                      PERCENTAGE
                    </option>
                  </Form.Select>
                </div>
              )}
              {state?.closeBy != "SHARES" && selectedOrderType === "Limit" && (
                <div className="py-2">
                  <p className="fw-sbold pb-2">PERCENTAGE</p>
                  <Form.Select
                    className="form-control bg-transparent"
                    onChange={(e) => setPercentage(e.target.value)}
                    style={{ color: "black" }}
                    value={percentage}
                  >
                    {PERCENTAGE_ARR?.map((item) => {
                      return (
                        <option
                          value={item?.label}
                          style={{ color: "white", background: "black" }}
                        >
                          {item?.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              )}
              <div className="py-2">
                <p className="fw-sbold pb-2">Share Number</p>
                <input
                  type="number"
                  value={shareNumber}
                  disabled={selectedOrderType == "Market"}
                  onChange={(e) => handleShareNumberChange(e.target.value)}
                  placeholder="Enter shares"
                  className="form-control bg-transparent"
                />
              </div>
              <Row className="pb-2">
                <Col>
                  <p className="fw-sbold">EST. Amount</p>
                  <div className="form-control bg-transparent">
                    ${handleDecimal(getCurrentPrice())}
                  </div>
                </Col>
                <Col>
                  <p className="fw-sbold">Commission</p>
                  <div className="form-control bg-transparent">
                    ${getCommission()}
                  </div>
                </Col>
                <div className="btnWrpper mt-5 py-2">
                  <Button
                    onClick={handleCloseOrder}
                    disabled={loading}
                    className="d-flex w-100 rounded-pill align-items-center justify-content-center commonBtn fw-sbold"
                  >
                    {type == "open" ? "Close Order" : "Cancel"}
                  </Button>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        <div className="cardCstm box  px-3 py-3" style={{ marginTop: "5rem" }}>
          <div className="top py-2">
            <p className="m-0 fw-sbold">Order Details</p>
          </div>
          <div className="contentBody py-2">
            <Row className="pb-2">
              <ul className="list-unstyled ps-0 mb-0 notLastBorder">
                <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                  <p className="m-0 fw-bold text-muted">
                    {type === "open"
                      ? "Avg Price"
                      : orderType === CONSTANT.Limit
                      ? "Limit Price"
                      : orderType === CONSTANT.Stop
                      ? "Stop Price"
                      : orderType === CONSTANT.Market
                      ? "Req. Price"
                      : "Entry Price"}
                  </p>
                  <p className="m-0 fw-bold  ">
                    {type === "open"
                      ? `$${
                          handleDecimalToViewOnly(item?.avg_entry_price, 2) ?? 0
                        }`
                      : item?.order_type === CONSTANT.Limit
                      ? `$${handleDecimalToViewOnly(item?.limit_price, 2)}`
                      : item?.order_type === CONSTANT.Stop
                      ? `$${handleDecimalToViewOnly(item?.stop_price, 2)}`
                      : `$${handleDecimalToViewOnly(item?.requestPrice, 2)}`}
                  </p>
                </li> 
                <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                  <p className="m-0 fw-bold text-muted">Current Price</p>
                  <p className="m-0 fw-bold  ">
                    {item?.unrealized_plpc
                      ? `$${handleDecimalToViewOnly(
                          item?.currentPrice ?? item?.current_price,
                          2
                        )}`
                      : `$${handleDecimalToViewOnly(
                          item?.currentPrice ?? item?.current_price,
                          2
                        )}`}
                  </p>
                </li>
                <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                  <p className="m-0 fw-bold text-muted">
                    {" "}
                    {orderType === CONSTANT.Limit || orderType === CONSTANT.Stop
                      ? "Type"
                      : "Side"}
                  </p>
                  <p className="m-0 fw-bold  ">
                    {" "}
                    {orderType === CONSTANT.Limit || orderType === CONSTANT.Stop
                      ? `${orderType}/${timeInForce}`
                      : item?.side}
                  </p>
                </li>
                <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                  <p className="m-0 fw-bold text-muted">Shares</p>
                  <p className="m-0 fw-bold  ">
                    {" "}
                    {handleDecimal(item?.qty, 9) ?? 0}
                  </p>
                </li>

                {type === "open" ? (
                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">P&L</p>
                    <p className="m-0 fw-bold  ">{`$${
                      handleDecimalToViewOnly(item?.unrealized_pl, 2) ?? 0
                    } (${Math.abs(
                      handleDecimal(item?.unrealized_plpc * 100)
                    )}%)`}</p>
                  </li>
                ) : (
                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Amount</p>
                    <p className="m-0 fw-bold  ">
                      {" "}
                      {`$${handleDecimalToViewOnly(
                        item?.requestAmount ?? 0,
                        2
                      )}`}
                    </p>
                  </li>
                )}

                <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                  <p className="m-0 fw-bold text-muted">
                    {" "}
                    {type == "open" ? "Amount" : "Commission"}
                  </p>
                  <p className="m-0 fw-bold  ">
                    $
                    {handleDecimalToViewOnly(
                      type === "open" ? item?.market_value : item?.commission,
                      2
                    )}
                  </p>
                </li>
              </ul>
              <div className="btnWrpper mt-5 py-2">
                <Button
                  onClick={() => cancelPendingHandler(item?.order_id)}
                  disabled={loading}
                  className="d-flex w-100 rounded-pill align-items-center justify-content-center commonBtn fw-sbold"
                >
                  {type == "open" ? "Close Order" : "Cancel"}
                </Button>
              </div>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockCloseBox;

import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_FAIL,
  PUT_PROFILE_SUCCESS,
  PUT_PROFILE_IMAGE,
  PUT_PROFILE_IMAGE_FAIL,
  PUT_PROFILE_IMAGE_SUCCESS,
  GET_USER_BY_WALLET,
  GET_USER_BY_WALLET_FAIL,
  GET_USER_BY_WALLET_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_MARKET_DETAILS,
  GET_MARKET_DETAILS_SUCCESS,
  GET_MARKET_DETAILS_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  loadingProfileProfile: false,
  userProfile: null,
  marketDetails: null,
  loadingMarketDetails: false,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state, loadingProfile: true };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload,
        userProfile: action.payload,
        loadingProfile: false,
      };
      break;
    case GET_PROFILE_ERROR:
      state = { ...state, error: action.payload, loadingProfile: false };
      break;
    case EDIT_PROFILE:
      state = { ...state, loadingProfile: true };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loadingProfile: false };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, loadingProfile: false };
      break;

    case CHANGE_PASSWORD:
      state = { ...state, loadingProfile: true };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload, loadingProfile: false };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, error: action.payload, loadingProfile: false };
      break;

    case PUT_PROFILE:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };
    case PUT_PROFILE_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
      };
    case PUT_PROFILE_FAIL:
      return {
        ...state,
        loadingProfile: false,
        error: action.payload,
      };

    case PUT_PROFILE_IMAGE:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };
    case PUT_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
      };
    case PUT_PROFILE_IMAGE_FAIL:
      return {
        ...state,
        loadingProfile: false,
        error: action.payload,
      };

    case GET_USER_BY_WALLET:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };

    case GET_USER_BY_WALLET_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
        userProfile: action.payload,
      };

    case GET_USER_BY_WALLET_FAIL:
      return {
        ...state,
        loadingProfile: false,
        userProfile: null,
        error: "",
      };
    case VERIFY_OTP:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
        userProfile: action.payload,
      };

    case VERIFY_OTP_ERROR:
      return {
        ...state,
        loadingProfile: false,
        userProfile: null,
        error: "",
      };
    case RESEND_OTP:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };

    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
        userProfile: action.payload,
      };

    case RESEND_OTP_ERROR:
      return {
        ...state,
        loadingProfile: false,
        userProfile: null,
        error: "",
      };

    case RESET_PASSWORD:
      return {
        ...state,
        loadingProfile: true,
        error: "",
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        error: "",
        userProfile: action.payload,
      };

    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loadingProfile: false,
        userProfile: null,
        error: "",
      };

    case GET_MARKET_DETAILS:
      state = { ...state, loadingMarketDetails: true };
      break;
    case GET_MARKET_DETAILS_SUCCESS:
      state = {
        ...state,
        marketDetails: action.payload,
        loadingMarketDetails: false,
      };
      break;
    case GET_MARKET_DETAILS_ERROR:
      state = { ...state, error: action.payload, loadingMarketDetails: false };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;

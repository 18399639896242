import { combineReducers } from "redux";
// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
//Forgot password
import ForgotPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Home from "./home/reducer";
import File from "./File/reducer";
import CMS from "./cms/reducer";
import Basic from "./basic/reducer";

const rootReducer = combineReducers({
  Alert,
  Login,
  ForgotPassword,
  Profile,
  Home,
  File,
  CMS,
  Basic,
});

export default rootReducer;

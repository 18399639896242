import React, { useEffect, useState, createContext, useContext } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBbemITengcsaa3T6g41Mtmgc5ZvJR6cMY",
  authDomain: "deviden-9324c.firebaseapp.com",
  projectId: "deviden-9324c",
  storageBucket: "deviden-9324c.appspot.com",
  messagingSenderId: "495811030637",
  appId: "1:495811030637:web:4ceea40208b007c0229964",
  measurementId: "G-C5YV8D52TG",
};

// // Firebase Initialization
// firebase.initializeApp(firebaseConfig);

// // Custom hook to use Firebase messaging
// const useFirebaseMessaging = () => {
//   const [token, setToken] = useState(null);
//   const [message, setMessage] = useState(null);
//   console.log(message, token, "checkfirebase");

//   useEffect(() => {
//     console.log("first");
//     const messaging = firebase.messaging();

//     // Get FCM token
//     const getToken = async () => {
//       try {
//         const currentToken = await messaging.getToken();
//         if (currentToken) {
//           setToken(currentToken);
//         } else {
//           console.log(
//             "No registration token available. Request permission to generate one."
//           );
//         }
//       } catch (error) {
//         console.log("An error occurred while retrieving token. ", error);
//       }
//     };

//     // Handle incoming messages
//     const onMessageListener = () => {
//       messaging.onMessage((payload) => {
//         console.log(payload, "firebaseContext");
//         setMessage(payload);
//       });
//     };

//     getToken();
//     onMessageListener();
//   }, []);

//   return { token, message };
// };

// // Context to provide Firebase messaging data
// export const FirebaseContext = createContext({ token: "", message: "" });

// export const FirebaseProvider = ({ children }) => {
//   const firebaseMessaging = useFirebaseMessaging();
//   console.log(firebaseMessaging.message, "firebaseContext");
//   return (
//     <FirebaseContext.Provider value={firebaseMessaging}>
//       {children}
//     </FirebaseContext.Provider>
//   );
// };

// export const useFirebase = () => {
//   return useContext(FirebaseContext);
// };

// Firebase Initialization
const firebaseApp = initializeApp(firebaseConfig);

// Custom hook to use Firebase messaging
const useFirebaseMessaging = () => {
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );

          const messaging = getMessaging(firebaseApp);

          // Request for FCM token
          getToken(messaging, {
            vapidKey:
              "BFiBFd0IwG7UFKMvN6XWp76RYHnpKsxyWhzwuS-Og3I-RADFBhmKlx6GzGq-neOJiDai7-6MsxryBoCUnPEfM2E",
          })
            .then((currentToken) => {
              if (currentToken) {
                console.log(currentToken, "token");
                console.log("FCM Token:", currentToken);
                setToken(currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token: ", err);
            });

          // Listen for foreground messages
          onMessage(messaging, (payload) => {
            console.log("Message received in foreground: ", payload);
            setMessage(payload);
          });
        })
        .catch((err) => {
          console.log("Service Worker registration failed: ", err);
        });
    }
  }, []);

  return { token, message };
};

// Context to provide Firebase messaging data
export const FirebaseContext = createContext({ token: "", message: "" });

export const FirebaseProvider = ({ children }) => {
  const firebaseMessaging = useFirebaseMessaging();
  return (
    <FirebaseContext.Provider value={firebaseMessaging}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};

import { formatDateTime } from "pages/SideTabPages/MyWallet/tabbed/ActivityList";
import React from "react";
import { Button, Modal, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

function OrderDetailModal({ show, close, type, stockView }) {
  const { userProfile } = useSelector((state) => state?.Profile);

  let userTimezone = userProfile?.timezone;
  return (
    <Modal
      show={show}
      onHide={close}
      centered // Centers the modal
    >
      <Modal.Header closeButton>Order Details</Modal.Header>
      <Modal.Body>
        <div className="py-2">
          <div
            className="d-flex align-items-center gap-10"
            style={{ fontSize: 10, lineHeight: "20px" }}
          >
            <div className="d-flex gap-10 align-items-center">
              <div className="imgWrp flex-shrink-0">
                <img
                  src={stockView?.imgUrl ? stockView?.imgUrl : ""}
                  alt=""
                  style={{ height: 50, width: 50 }}
                  className="img-fluid rounded object-fit-contain"
                />
              </div>
              <div className="content">
                <h5 className="m-0 fw-sbold  ">{stockView?.symbol}</h5>
                <p className="m-0  ">{stockView?.shortName}</p>
                <p>{stockView?.name}</p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="py-2">
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold text-muted">Date & Time</p>
            <p className="m-0 fw-bold  ">
              {formatDateTime(stockView?.createdAt, userTimezone, "date")}
            </p>
          </li>
          <div
            className="cardCstm box border-0 mt-3 px-3 py-1"
            style={{ background: "#222222" }}
          >
            <ul className="list-unstyled ps-0 mb-0 notLastBorder">
              <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                <p className="m-0 fw-bold text-muted">Type</p>
                <p className="m-0 fw-bold  ">{stockView?.scheduledType}</p>
              </li>
              <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                <p className="m-0 fw-bold text-muted">
                  Scheduled Time/New York
                </p>
                <p className="m-0 fw-bold  ">{stockView?.time}</p>
              </li>
              <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                <p className="m-0 fw-bold text-muted">Scheduled Day</p>
                <p className="m-0 fw-bold  ">
                  {stockView?.scheduledType === "everyday"
                    ? "Everyday":
                    stockView?.scheduledType === "everymonth"
                    ? stockView?.timeType + " " + stockView?.day 
                    : stockView?.day}
                </p>
              </li>
              <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                <p className="m-0 fw-bold text-muted">Amount</p>
                <p className="m-0 fw-bold  ">${stockView?.requestAmount}</p>
              </li>
              <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                <p className="m-0 fw-bold text-muted">Commision</p>
                <p className="m-0 fw-bold  ">${stockView?.commission}</p>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OrderDetailModal;

import { takeLatest, put, call } from "redux-saga/effects";

import * as TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as API from "helpers/backend_helper";

function* onGetCMS({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getCMS, data);
    if (response.status === "failure") {
      return yield put(ACTION.getCMSFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.getCMSSuccess(response));
  } catch (error) {
    yield put(ACTION.getCMSFail(error));
  }
}

function* onTrustedContent({ payload: { data, callback } }) {
  try {
    const response = yield call(API.truestedContent, data);
    if (response.status === "failure") {
      return yield put(ACTION.trustContentFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.trustContentSuccess(response.data));
  } catch (error) {
    yield put(ACTION.trustContentFail(error));
  }
}
function* Saga() {
  yield takeLatest(TYPES.GET_CMS, onGetCMS);
  yield takeLatest(TYPES.TRUST_CONTENT, onTrustedContent);
}
export default Saga;

import React, { useEffect, useState } from "react";

// img
import c1 from "../../../../Assets/images/c1.png";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import moment from "moment";
import { transactionHistoryApi } from "helpers/backend_helper";
import { toast } from "react-toastify";
import DateFilter from "./DateFilter";
import HistoryList from "./HistoryList";
import HistoryDetailModal from "./HistoryDetailsModel";

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const HistoricalTransaction = () => {
  const [completedData, setCompletedData] = useState([]);
  const [scheduledData, setScheduleData] = useState([]);
  const [tab, setTab] = useState("Completed");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [loader, setLoader] = useState(false);
  const [selectedType, setSelectedType] = useState("incoming");
  const getData = async (tab) => {
    setLoader(true);
    try {
      let data = {
        startDate: formatDate(new Date(startDate)),
        endDate: formatDate(new Date(endDate)),
        limit: 500,
        orderBy: "createdAt",
        order: -1,
        page: 1,
        type: selectedType,
        status:
          tab === "Completed"
            ? ["success", "COMPLETE", "approved", "APPROVED", "rejected"]
            : tab === "Scheduled"
            ? []
            : null,
      };

      const res = await transactionHistoryApi(data);

      if (res.status === "success") {
        if (tab === "Completed") {
          setCompletedData(res.data);
          setLoader(false);
        } else {
          setScheduleData(res.data);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error, "Error");
      setLoader(false);
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    getData(tab);
  }, [tab, endDate, startDate, selectedType]);

  return (
    <>
  
      <div className="py-2">
        <Tab.Container id="left-tabs-example" defaultActiveKey="Completed">
          <Nav
            variant="pills"
            className={` navPillsTab navPillsTab2 gap-10 mt-3`}
          >
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Completed"
                onClick={() => setTab("Completed")}
              >
                Completed
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={` rounded-pill d-flex align-items-center justify-content-center fw-sbold`}
                eventKey="Scheduled"
                onClick={() => setTab("Scheduled")}
              >
                Pending
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Completed">
              <DateFilter
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                showTypeFilter={true}
                onChangeSelectType={setSelectedType}
                selectedType={selectedType}
                loader={loader}
              />
              <HistoryList data={completedData} loader={loader} />
            </Tab.Pane>
            <Tab.Pane eventKey="Scheduled">
              <DateFilter
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                showTypeFilter={true}
                onChangeSelectType={setSelectedType}
                selectedType={selectedType}
                loader={loader}
              />
              <HistoryList data={scheduledData} loader={loader} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default HistoricalTransaction;

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";

export const ADD_HELP_SUPPORT = "ADD_HELP_SUPPORT";
export const ADD_HELP_SUPPORT_FAIL = "ADD_HELP_SUPPORT_FAIL";
export const ADD_HELP_SUPPORT_SUCCESS = "ADD_HELP_SUPPORT_SUCCESS";

export const ALL_STOCK_LIST = "ALL_STOCK_LIST";
export const ALL_STOCK_LIST_FAIL = "ALL_STOCK_LIST_FAIL";
export const ALL_STOCK_LIST_SUCCESS = "ALL_STOCK_LIST_SUCCESS";

export const OPEN_POSITIONS = "OPEN_POSITIONS";
export const OPEN_POSITIONS_FAIL = "OPEN_POSITIONS_FAIL";
export const OPEN_POSITIONS_SUCCESS = "OPEN_POSITIONS_SUCCESS";

export const PENDING_POSITIONS = "PENDING_POSITIONS";
export const PENDING_POSITIONS_FAIL = "PENDING_POSITIONS_FAIL";
export const PENDING_POSITIONS_SUCCESS = "PENDING_POSITIONS_SUCCESS";

export const SCHEDULE_ORDER = "SCHEDULE_ORDER";
export const SCHEDULE_ORDER_FAIL = "SCHEDULE_ORDER_FAIL";
export const SCHEDULE_ORDER_SUCCESS = "SCHEDULE_ORDER_SUCCESS";

export const STOCK_HISTORY_ORDER = "STOCK_HISTORY_ORDER";
export const STOCK_HISTORY_ORDER_FAIL = "STOCK_HISTORY_ORDER_FAIL";
export const STOCK_HISTORY_ORDER_SUCCESS = "STOCK_HISTORY_ORDER_SUCCESS";

export const TRADING_ACTIVITIES = "TRADING_ACTIVITIES";
export const TRADING_ACTIVITIES_FAIL = "TRADING_ACTIVITIES_FAIL";
export const TRADING_ACTIVITIES_SUCCESS = "TRADING_ACTIVITIES_SUCCESS";

export const STOCK_SINGLE_DETAIL = "STOCK_SINGLE_DETAIL";
export const STOCK_SINGLE_DETAIL_FAIL = "STOCK_SINGLE_DETAIL_FAIL";
export const STOCK_SINGLE_DETAIL_SUCCESS = "STOCK_SINGLE_DETAIL_SUCCESS";

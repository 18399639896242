import { useFormik } from "formik";
import { countriesStatic, currenciesStatic } from "helpers/Mocks";
import { addBankAccountApi } from "helpers/backend_helper";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

const AddBank = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      accountHolderName: "",
      accountNumber: "",
      bankName: "",
      country: "Indonesia",
      currency: currenciesStatic[0].value,
    },

    validationSchema: Yup.object({
      accountHolderName: Yup.string().required(
        "Please enter your Account Holder Name"
      ),
      accountNumber: Yup.string().required("Please enter your Account Number"),
      bankName: Yup.string().required("Please enter your Bank Name"),
      country: Yup.string().required("Please enter your country Name"),
      currency: Yup.string().required("Please enter your currency"),
    }),

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const body = {
          ...values,
          instant: true,
        };
        const res = await addBankAccountApi(body);

        if (res.status === "success") {
          toast.success(res.message);
          setLoading(false);
          navigate(-1);
        } else {
          setLoading(false);
          toast.error(res.message || "something went wrong");
        }
      } catch (error) {
        setLoading(false);
        toast.error("something went wrong!");
      }
    },
  });

  useEffect(() => {
    console.log(validation?.errors, "Errors");
  }, [validation.errors]);

  return (
    <>
      <section className="AddBank position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap  gap-10">
                <div
                  className="left d-flex align-items-center gap-10"
                  onClick={() => navigate(-1)}
                >
                  {/* <Link to={"/my-wallet/bank-transfer"}> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M15.4141 8.086L3.82806 8.086L8.32806 12.586L6.91406 14L6.07329e-05 7.086L6.91406 0.172001L8.32806 1.586L3.82806 6.086L15.4141 6.086L15.4141 8.086Z"
                      fill="var(--darkLightRed)"
                    />
                  </svg>
                  {/* </Link> */}
                  <h5 className="m-0 fw-sbold ">Add Bank Account</h5>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className="py-2">
                <p className="m-0 fw-sbold ">Bank Account</p>
                <p className="m-0 text-muted">
                  ADD THIS AFTER IMPLEMENTING PAYMNET METHOD
                </p>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  validation.setTouched({
                    accountHolderName: true,
                    accountNumber: true,
                    bankName: true,
                    country: true,
                    currency: true,
                  });
                  // return false;
                }}
              >
                <Row>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Account Holder Name
                    </label>
                    <input
                      type="text"
                      name="accountHolderName"
                      className="form-control box bg-black"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.accountHolderName || ""}
                    />
                    {validation.touched.accountHolderName &&
                    validation.errors.accountHolderName ? (
                      <p className="text-danger mt-1 mb-0 ps-1">
                        {validation.errors.accountHolderName}
                      </p>
                    ) : null}
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Account Number
                    </label>
                    <input
                      type="number"
                      name="accountNumber"
                      className="form-control box bg-black"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.accountNumber || ""}
                    />
                    {validation.touched.accountNumber &&
                    validation.errors.accountNumber ? (
                      <p className="text-danger mt-1 mb-0 ps-1">
                        {validation.errors.accountNumber}
                      </p>
                    ) : null}
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      name="bankName"
                      className="form-control box bg-black"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.bankName || ""}
                    />
                    {validation.touched.bankName &&
                    validation.errors.bankName ? (
                      <p className="text-danger mt-1 mb-0 ps-1">
                        {validation.errors.bankName}
                      </p>
                    ) : null}
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Country
                    </label>
                    <Form.Select
                      className="form-control box bg-black"
                      aria-label="Default select example"
                      name="country"
                      onChange={validation.handleChange}
                      value={validation.values.country || ""}
                    >
                      {countriesStatic?.map((item) => {
                        return (
                          <option className="bg-black" value={item?.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {validation.touched.country && validation.errors.country ? (
                      <p className="text-danger mt-1 mb-0 ps-1">
                        {validation.errors.country}
                      </p>
                    ) : null}
                  </Col>
                  <Col md="6" className="my-2">
                    <label htmlFor="" className="form-label m-0  fw-sbold pb-2">
                      Bank Name
                    </label>
                    <Form.Select
                      className="form-control box bg-black"
                      aria-label="Default select example"
                      name="currency"
                      onChange={validation.handleChange}
                      value={validation.values.currency || ""}
                    >
                      {currenciesStatic?.map((item) => {
                        return (
                          <option className="bg-black" value={item?.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {validation.touched.currency &&
                    validation.errors.currency ? (
                      <p className="text-danger mt-1 mb-0 ps-1">
                        {validation.errors.currency}
                      </p>
                    ) : null}
                  </Col>
                  <Col lg="12" className="my-2">
                    <div className="btnWrpper text-end mt-4">
                      <Button
                        type="submit"
                        className="d-inline-flex align-items-center justify-content-center commonBtn"
                        style={{ height: 50, minWidth: 150 }}
                        disabled={loading}
                      >
                        {loading ? "Loading…" : "Add Bank Account"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddBank;

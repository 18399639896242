import * as TYPES from "./actionTypes";

const storedAuthUser = localStorage.getItem("authUser");
const parsedAuthUser = JSON.parse(storedAuthUser || "{}");
const { token, ...authUser } = parsedAuthUser;

const initialState = {
  meLoading: false,
  isMeFailed: false,
  error: "",
  loading: false,
  user: parsedAuthUser || {},
  authToken: token ? token || null : null,
  settings: null,
  currentWalletAddress: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.LOGIN_USER:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data,
        loading: false,
      };

    case TYPES.LOGOUT_USER:
      return { ...state, loading: true };

    case TYPES.LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, authToken: null, loading: false };

    case TYPES.API_ERROR:
      return { ...state, error: action.payload, loading: false };

    case TYPES.GET_CURRENT_USER:
      return {
        ...state,
        error: "",
        meLoading: true,
        isMeFailed: false,
      };

    case TYPES.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        meLoading: false,
        isMeFailed: false,
      };

    case TYPES.GET_CURRENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isMeFailed: true,
        meLoading: false,
      };

    default:
      return state;
  }
};

export default login;

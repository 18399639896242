import { getAllStock } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

let timeOutId = null;

const SearchComponent = ({
  setStockDetails3,
  setStockDetails2,
  stockDetails2,
  SetShowResult,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const searchHandler = async () => {
    try {
      const res = await getAllStock({ search: searchValue });

      console.log(res, "res123");

      if (res?.status === "success") {
        setSearchResult(res?.data);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  const useDebounce = () => {
    if (!searchValue) {
      setSearchResult([]);
      return;
    }

    clearTimeout(timeOutId);

    timeOutId = setTimeout(() => {
      searchHandler();
    }, 400);
  };

  useEffect(() => {
    useDebounce();
  }, [searchValue]);

  useEffect(() => {
    const emptyHandler = () => {
      setSearchResult([]);
    };

    window.addEventListener("click", emptyHandler);

    return () => window.removeEventListener("click", emptyHandler);
  }, []);

  return (
    <div className="py-2 position-relative">
      <label htmlFor="" className="form-label text-white m-0 pb-1">
        Enter Your Symbol
      </label>
      <input
        type="text"
        className="form-control border-secondary"
        style={{
          background: "black",
        }}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      <div
        className="position-absolute top-100 bg-black w-100 "
        style={{
          zIndex: 1000,
        }}
      >
        <>
          {searchResult?.map((list) => {
            return (
              <div
                className="d-flex align-items-center gap-10 p-2 w-100"
                onClick={() => {
                  const details = {
                    name: list?.name,
                    symbol: list?.symbol,
                    imgUrlDark: list?.imgUrlDark,
                    imgUrl: list?.imgUrl,
                  };
                  if (stockDetails2) {
                    setStockDetails3((p) => details);
                    SetShowResult(false);
                  } else {
                    setStockDetails2((p) => details);
                    SetShowResult(false);
                  }
                }}
              >
                <div className="imgWrp"></div>
                <img src={list?.imgUrlDark} className="stock-list-size" />
                <div className="content">
                  <h6 className=" m-0 fw-bold pb-2">{list?.symbol}</h6>
                  <p className="text-muted m-0">{list?.shortName}</p>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default SearchComponent;

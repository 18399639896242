import React, { useDebugValue, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";

// css
import styles from "./stockCompare.module.scss";
import { useParams } from "react-router-dom";
import {
  compareStockApi,
  getAllStock,
  stockDetailApi,
} from "helpers/backend_helper";
import { toast } from "react-toastify";
import SearchComponent from "./SearchComponent";
import StockDetailsBox from "./StockDetailsBox";
import Loader from "components/Loader";
import RenderSingleResultItem from "./RenderSingleResultItem";

const resultColumnArr = [
  { label: "Open", keyName: "open", isDollar: true },
  { label: "High", keyName: "high", isDollar: true },
  { label: "Low", keyName: "low", isDollar: true },
  { label: "Last Price", keyName: "lastPrice", isDollar: true },
  { label: "Change", keyName: "priceChange", isDollar: true },
  { label: "% Change", keyName: "percentageChange" },
  { label: "Vol", keyName: "volume" },
  { label: "Market Capitalization", keyName: "marketCap", isDollar: true },
  { label: "EBITDA", keyName: "ebitda", isDollar: true },
  { label: "Earning per share", keyName: "revenuePerShare" },
  { label: "Dividend yield", keyName: "dividendYield" },
  { label: "Profit Margin", keyName: "profitMargin" },
  { label: "Revenue", keyName: "revenue", isDollar: true },
  { label: "Revenue per share", keyName: "revenuePerShare", isDollar: true },
  { label: "Quarterly Earning Growth", keyName: "quarterlyRevenueGrowth" },
  { label: "Enterprise Value", keyName: "enterpriseValue", isDollar: true },
  { label: "Share Statistics", keyName: "shareStatistics" },
];

const StockCompare = () => {
  const [loading, setLoading] = useState(false);
  const [showResult, SetShowResult] = useState(false);
  const [stockDetails1, setStockDetails1] = useState();
  const [stockDetails2, setStockDetails2] = useState();
  const [stockDetails3, setStockDetails3] = useState();
  const [compareStocksData, setCompareStocksData] = useState(null);
  const [completeStockDetails, setCompleteStockDetails] = useState({});

  const { symbol } = useParams();

  const getStockDetails = async (setDataFunction, symbol) => {
    setLoading(true);
    try {
      const res = await stockDetailApi(symbol);

      if (res.status === "success") {
        setDataFunction((p) => res?.data?.companyOverview);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("something went wrong!");
    }
  };

  const compareHandler = async () => {
    if (!stockDetails2 && !stockDetails3) {
      return toast.error("Please select at least one stock to compare");
    }

    setLoading(true);
    try {
      let body = {
        symbols: [stockDetails1.symbol],
      };

      if (stockDetails2?.symbol) body.symbols.push(stockDetails2.symbol);
      if (stockDetails3?.symbol) body.symbols.push(stockDetails3.symbol);

      const res = await compareStockApi(body);

      if (res?.status === "success") {
        setCompareStocksData((p) => res.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error("something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    const allStockDetails = {};
    if (compareStocksData && compareStocksData.length > 0) {
      compareStocksData.forEach((stock, index) => {
        // console.log('🚀 ~ compareStocksData.forEach ~ index:', index);
        // Creating a detailed object for each stock
        const detailKey = `stockDetails${index + 1}`;
        allStockDetails[detailKey] = {
          symbol: stock?.symbol,
          name: stock?.name,
          currentPrice: stock?.c,
          high: stock?.h,
          low: stock?.l,
          open: stock?.o,
          volume: stock?.v,
          volumeWeightedPrice: stock?.vw,
          priceChange: stock?.change,
          percentChange: stock?.percent_change,
          lastPrice: stock?.price,
          lastChangePercentage: stock?.changePercentage,
          marketCap: stock?.marketCap,
          ebitda: stock?.ebitda,
          earningsPerShare: stock?.earningsPerShare,
          dividendYield: stock?.dividendYield,
          profitMargin: stock?.profitMargin,
          revenue: stock?.revenue,
          revenuePerShare: stock?.revenuePerShare,
          quarterlyRevenueGrowth: stock?.quarterlyRevenueGrowth,
          enterpriseValue: stock?.enterpriseValue,
          shareStatistics: stock?.shareStatistics,
          imgUrl: stock?.imgUrl,
          imgUrlDark: stock?.imgUrlDark,
        };
      });
      setCompleteStockDetails(allStockDetails);
      SetShowResult(true);
    }
  }, [compareStocksData]);

  useEffect(() => {
    if (symbol) getStockDetails(setStockDetails1, symbol);
  }, [symbol]);

  return (
    <>
      {loading && <Loader />}
      <section
        className={`${styles.StockCompare} stockCompare py-3 position-relative`}
      >
        <Container>
          <StockDetailsBox
            setData={() => {}}
            stockDetail={stockDetails1}
            showCross={false}
          />
          <Row>
            <Col lg="12" className="my-2">
              <Form>
                <SearchComponent
                  setStockDetails2={setStockDetails2}
                  setStockDetails3={setStockDetails3}
                  stockDetails2={stockDetails2}
                  SetShowResult={SetShowResult}
                />
                <div className="py-2">
                  <div
                    className={`${styles.btnWrpper} btnWrpper d-flex align-items-center gap-10`}
                  >
                    {stockDetails2 && (
                      <StockDetailsBox
                        setData={setStockDetails2}
                        stockDetail={stockDetails2}
                        SetShowResult={SetShowResult}
                      />
                    )}
                    {stockDetails3 && (
                      <StockDetailsBox
                        setData={setStockDetails3}
                        stockDetail={stockDetails3}
                        SetShowResult={SetShowResult}
                      />
                    )}
                    <Button
                      className={`${styles.btn} btn d-flex align-items-center justify-content-center fw-sbold gap-10 px-2 commonBtn`}
                      onClick={() => compareHandler()}
                    >
                      Compare Stocks
                    </Button>
                  </div>
                </div>
              </Form>
            </Col>

            {showResult && (
              <>
                <Col lg="12" className="my-2">
                  <div
                    className="cardCstm box border-0"
                    style={{ background: "#222" }}
                  >
                    <div className="py-2 d-flex align-items-center justify-content-between gap-10 px-3">
                      <p className="m-0 text-white fw-sbold">Result</p>
                    </div>
                    <div className="py-2">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="">
                            <RenderSingleResultItem
                              completeStockDetails={completeStockDetails}
                              keyName={"symbol"}
                              label={"Symbol"}
                              isHeader={true}
                            />
                          </thead>
                          <tbody>
                            <RenderSingleResultItem
                              completeStockDetails={completeStockDetails}
                              keyName={"name"}
                              label={"Company"}
                            />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div
                    className="cardCstm box border-0"
                    style={{ background: "#222" }}
                  >
                    <div className="py-2 d-flex align-items-center justify-content-between gap-10 px-3">
                      <p className="m-0 text-white fw-sbold">
                        Price Information
                      </p>
                    </div>
                    <div className="py-2">
                      <div className="table-responsive">
                        <table className={`${styles.strippedTable} table`}>
                          <thead className="">
                            <RenderSingleResultItem
                              completeStockDetails={completeStockDetails}
                              keyName={"symbol"}
                              label={"Symbol"}
                              isHeader={true}
                            />
                          </thead>
                          <tbody>
                            {resultColumnArr?.map((item) => {
                              return (
                                <RenderSingleResultItem
                                  completeStockDetails={completeStockDetails}
                                  keyName={item?.keyName}
                                  label={item?.label}
                                  isDollar={item.isDollar}
                                  isNumber={true}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default StockCompare;

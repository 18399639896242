import { call, put, takeEvery } from "redux-saga/effects"

// Customer Redux States
import { UPLOAD_FILE } from "./actionTypes"
import { fileApiFail } from "./actions"
import { postFile } from "helpers/backend_helper"

function* onAddFile({ payload: { data, callback } }) {
  try {
    const response = yield call(postFile, data)

    if (response.status == "failure") {
      return yield put(fileApiFail(response.message))
    }
    callback && callback(response)
    yield put(fileApiFail(""))
  } catch (error) {
    yield put(fileApiFail(error))
  }
}

function* fileSaga() {
  yield takeEvery(UPLOAD_FILE, onAddFile)
}

export default fileSaga

import React, { useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Transactions from "./AffiliateTAbs/Transactions";
import ClientsTab from "./AffiliateTAbs/ClientsTab";
import { AFFILIATE_TRANSACTION_LIST } from "helpers/backend_helper";
import { formatStandardNumberWithMinNMaxDecimal } from "helpers/helper";

const AffiliateArea = () => {
  const [data, setData] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [transactionLoading, setTransactionLoading] = useState();

  const getData = async (filter, field) => {
    setTransactionLoading(true);
    try {
      const response = await AFFILIATE_TRANSACTION_LIST({
        ...filter,
        startDate: field?.startDate ?? "",
        endDate: field?.endDate ?? "",
      });
      if (response.status === "failure") {
        setTransactionLoading(false);
        return toast.error(response?.message);
      }
      setTransactionLoading(false);
      setData(response);
    } catch (error) {
      setTransactionLoading(false);
      toast.error("something went wrong");
    }
  };

  return (
    <div>
      <div>
        <h5 className="m-0 currentColor fw-sbold mb-1">overview</h5>
        <p>Total Paid Commissions Overtime</p>
        <p>
          ${" "}
          {formatStandardNumberWithMinNMaxDecimal(
            data?.countData?.totalApproved
          )}
        </p>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <p className="m-0 currentColor  mb-1">Pending commissions</p>$
          {formatStandardNumberWithMinNMaxDecimal(
            data?.countData?.totalPending
          )}
        </div>
        <div>
          <p className="mb-0  d-flex align-items-center gap-1">
            Total Clients{" "}
          </p>
          <p className="mb-0 ">{totalUsers}</p>
        </div>
      </div>
      <div className="py-2">
        <Tab.Container id="left-tabs-example" defaultActiveKey="transactions">
          <Nav
            variant="pills"
            className={` underlineTab gap-10`}
            style={{
              borderBottom: "1px solid var(--darkLightGray)",
            }}
          >
            <Nav.Item className="px-1">
              <Nav.Link
                className={` bg-transparent border-0 p-0 position-relative pb-2
                        fw-sbold`}
                eventKey="transactions"
                // onClick={() => handleTabClick("Pending")}
              >
                Transaction
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="px-1">
              <Nav.Link
                className={` bg-transparent border-0 p-0 position-relative pb-2 
                        fw-sbold`}
                eventKey="clients"
                // onClick={() => handleTabClick("Historical")}
              >
                Client
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="transactions">
              <Transactions
                data={data}
                getData={getData}
                loading={transactionLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="clients">
              <ClientsTab setTotalUsers={setTotalUsers} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default AffiliateArea;

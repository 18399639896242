import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

// css
import styles from "./Auth.module.scss";

// img
import logo from "../../Assets/images/logo.png";
import authBg from "../../Assets/images/authBg.png";

const AuthLayout = () => {
  return (
    <>
      <section
        className={`${styles.authLayout} authLayout position-relative py-md-5 py-3 d-flex align-items-center justify-content-center flex-column`}
        style={{
          zIndex: "9",
          backgroundColor: "#222222",
          background: `url(${authBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        <Container className="">
          <Row className="justify-content-center">
            <Outlet />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AuthLayout;

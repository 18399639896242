import { UPLOAD_FILE, REMOVE_FILE, FILE_API_FAIL } from "./actionTypes"

export const fileApiFail = error => ({
  type: FILE_API_FAIL,
  payload: error,
})

export const uploadFile = (data, callback) => ({
  type: UPLOAD_FILE,
  payload: { data, callback },
})

export const removeFile = fileId => ({
  type: REMOVE_FILE,
  payload: fileId,
})

import React from "react";
import { Link, useNavigate } from "react-router-dom";

const IndustrySelection = ({industryList}) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="d-flex align-items-center justify-content-between gap-10 cardHead mb-2">
        <h6 className="m-0  fw-sbold">Industry Selection</h6>
        <Link to="/industry-detail" className="themeClr">
          See All
        </Link>
      </div>
      <ul
        className="list-unstyled text-center ps-0 mb-0 d-flex align-items-start gap-10"
        style={{ fontSize: 10, overflow: "auto" }}
      >
        {industryList?.length > 0 && industryList?.slice(0, 5).map((list, i) => {
          return(
        <li className="px-2 cursorPointer" key={i}   onClick={() => navigate(`/industry-stocks/${list?.name}`)}>
          <span className="class-img-icon">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 63 63"
              fill="none"
            >
              <rect
                x="0.675781"
                y="1.25098"
                width="61"
                height="61"
                rx="14.5"
                fill="#212121"
              />
              <rect
                x="0.675781"
                y="1.25098"
                width="61"
                height="61"
                rx="14.5"
                stroke="#474747"
              />
              <g clip-path="url(#clip0_0_1524)">
                <path
                  d="M15.3921 34.3558L17.5495 34.7094C17.8904 36.3614 18.5427 37.9334 19.4716 39.3414L18.1939 41.1245C17.9289 41.4854 17.9334 41.9779 18.2052 42.3338C18.5273 42.7276 18.8696 43.1044 19.2307 43.4629C19.5949 43.8277 19.9703 44.1639 20.357 44.4889C20.7131 44.7612 21.2063 44.7658 21.5674 44.5002L23.3494 43.222C24.756 44.1514 26.3269 44.8041 27.978 45.1451L28.3311 47.3042C28.3983 47.7459 28.7507 48.0892 29.194 48.1448C29.5011 48.1796 29.8104 48.2008 30.1265 48.2165C30.28 48.224 30.4299 48.1686 30.5415 48.0631C30.6532 47.9575 30.717 47.811 30.7182 47.6573V43.1665C30.7166 42.8638 30.4761 42.6164 30.1736 42.6062C24.5108 42.3126 20.0698 37.6355 20.0698 31.9651C20.0698 26.2947 24.5108 21.6176 30.1736 21.3241C30.4761 21.3138 30.7166 21.0665 30.7182 20.7637V16.2768C30.7174 16.1232 30.654 15.9766 30.5427 15.8708C30.4313 15.765 30.2815 15.7093 30.1281 15.7164C29.8132 15.7295 29.5018 15.7516 29.1939 15.7825C28.7505 15.843 28.3996 16.1876 28.331 16.6298L27.978 18.7889C26.3269 19.1304 24.7561 19.7833 23.3493 20.7126L21.5674 19.4339C21.2062 19.1682 20.7131 19.1728 20.3569 19.4451C19.9643 19.7677 19.5883 20.1102 19.2306 20.4711C18.8669 20.827 18.5244 21.204 18.2051 21.6003C17.9322 21.9566 17.9277 22.4504 18.1939 22.8118L19.4715 24.5949C18.5428 26.0024 17.8905 27.5737 17.5494 29.2252L15.392 29.5782C14.9503 29.6457 14.607 29.9983 14.5515 30.4418C14.4412 31.4474 14.4412 32.4621 14.5515 33.4677C14.6013 33.9185 14.9447 34.2813 15.3921 34.3558Z"
                  fill="#CF0A10"
                />
                <path
                  d="M30.1827 23.5786C25.7557 23.859 22.3086 27.5313 22.3086 31.9672C22.3086 36.4031 25.7557 40.0755 30.1827 40.3559C30.3235 40.3618 30.4606 40.3095 30.5619 40.2114C30.6631 40.1133 30.7197 39.978 30.7184 39.837V24.0975C30.7198 23.9565 30.6632 23.8212 30.5619 23.723C30.4606 23.6249 30.3235 23.5727 30.1827 23.5786Z"
                  fill="#CF0A10"
                />
                <path
                  d="M34.6417 24.1218H31.8398V25.2425H34.6417C35.569 25.2397 36.32 24.4887 36.3227 23.5614V20.0961C37.0947 19.8232 37.5577 19.0339 37.4193 18.2269C37.2808 17.4199 36.5812 16.8301 35.7624 16.8301C34.9436 16.8301 34.2439 17.4199 34.1055 18.2269C33.967 19.0339 34.43 19.8232 35.202 20.0961V23.5614C35.202 23.8709 34.9511 24.1218 34.6417 24.1218Z"
                  fill="white"
                />
                <path
                  d="M31.8398 27.484H37.4435C37.753 27.484 38.0039 27.2332 38.0039 26.9237C38.0039 26.6142 37.753 26.3633 37.4435 26.3633H31.8398V27.484Z"
                  fill="white"
                />
                <path
                  d="M31.8398 39.8121H34.6417C34.9512 39.8121 35.202 40.063 35.202 40.3725V43.8378C34.4301 44.1107 33.9671 44.9 34.1055 45.707C34.244 46.514 34.9436 47.1038 35.7624 47.1038C36.5812 47.1038 37.2809 46.514 37.4193 45.707C37.5578 44.9 37.0947 44.1107 36.3228 43.8378V40.3725C36.32 39.4452 35.569 38.6942 34.6417 38.6914H31.8399V39.8121H31.8398Z"
                  fill="white"
                />
                <path
                  d="M40.2453 34.208H31.8398V35.3287H40.2453C40.5548 35.3287 40.8057 35.5796 40.8057 35.8891V38.7941C40.0338 39.067 39.5707 39.8563 39.7092 40.6633C39.8476 41.4703 40.5473 42.0601 41.3661 42.0601C42.1848 42.0601 42.8845 41.4703 43.023 40.6633C43.1614 39.8563 42.6984 39.067 41.9265 38.7941V35.8891C41.9237 34.9618 41.1726 34.2108 40.2453 34.208Z"
                  fill="white"
                />
                <path
                  d="M45.849 30.2861C45.1391 30.2891 44.5076 30.7376 44.271 31.4068H31.8398V32.5276H44.271C44.5573 33.3314 45.4014 33.7925 46.2325 33.599C47.0637 33.4055 47.6173 32.6191 47.5191 31.7714C47.421 30.9237 46.7023 30.2846 45.849 30.2861Z"
                  fill="white"
                />
                <path
                  d="M38.0039 37.0106C38.0039 36.7011 37.753 36.4502 37.4435 36.4502H31.8398V37.5709H37.4435C37.753 37.571 38.0039 37.3201 38.0039 37.0106Z"
                  fill="white"
                />
                <path
                  d="M41.9265 28.044V25.1391C42.6984 24.8662 43.1615 24.0768 43.023 23.2699C42.8845 22.4629 42.1849 21.873 41.3661 21.873C40.5473 21.873 39.8476 22.4629 39.7092 23.2699C39.5707 24.0768 40.0338 24.8662 40.8057 25.1391V28.044C40.8057 28.3535 40.5548 28.6044 40.2453 28.6044H31.8398V29.7251H40.2453C41.1727 29.7223 41.9237 28.9713 41.9265 28.044Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_1524">
                  <rect
                    width="33.0605"
                    height="33.0605"
                    fill="white"
                    transform="translate(14.4688 15.4365)"
                  />
                </clipPath>
              </defs>
            </svg> */}
            <span className="bg-white rounded-circle p-2 d-block mx-auto" style={{height: 40, width: 40 , }}>

            <img className="img-fluid object-fit-contain h-100 w-100" src={list?.imgUrl} />

            </span>
          </span>
          <p className="m-0  fw-sbold">{list?.name}</p>
        </li>
         )
        })}
      </ul>
    </>
  );
};

export default IndustrySelection;

import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { privateRoutes, routes } from "./pages/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Assets/css/style.css";
import "./Assets/css/darkTheme.css";
import AuthLayout from "./layout/Auth/authLayout";
import MainLayout from "./layout/MainLayout/MainLayout";
import ProtectedRoute from "./layout/MainLayout/ProtectedRoute";
import LoginPage from "./pages/AuthPages/Login/Index";
import Dashboard from "./pages/SideTabPages/Dashboard";

import { useDispatch, useSelector } from "react-redux";
// Store
import { getProfile } from "store/actions";
import { ThemeProvider, useTheme } from "Contexts/ThemeContexts";
import { Button } from "react-bootstrap";
import { FirebaseProvider } from "./firebase/firebase";

function App() {
  const dispatch = useDispatch();

  const { authToken } = useSelector((state) => state.Login);

  // console.log(authToken, "authToken");
  return (
    <ThemeProvider>
      <FirebaseProvider>
        <Routes>
          <Route element={<AuthLayout />}>
            {/* <Route element={authToken ? <AuthLayout /> : <MainLayout />}> */}
            {/* <Route index element={<Navigate to="/login" />} /> */}
            <Route
              path="/"
              element={authToken ? <Navigate to="/home" /> : <LoginPage />}
            />
            {routes.map((data, index) => (
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact={true}
                path={data.path}
                element={data.component}
                key={index}
              />
            ))}
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="/home"
              // element={authToken ? <Dashboard /> : <LoginPage />}
              element={authToken ? <Dashboard /> : <Navigate to="/" />}
            />
            {privateRoutes.map((data, index) => (
              <Route
                onUpdate={() => window.scrollTo(0, 0)}
                exact={true}
                path={data.path}
                // element={data.component}
                element={authToken ? data.component : <Navigate to="/" />}
                key={index}
              />
            ))}
          </Route>
        </Routes>
      </FirebaseProvider>
    </ThemeProvider>
  );
}

export default App;

import React from "react";

// img
import loader from "../../Assets/images/loader.png";

const Loader = () => {
  return (
    <>
      <div
        className="position-fixed h-100 w-100 d-flex align-items-center justify-content-center"
        style={{ zIndex: 999, left: 0, top: 0, background: "#0000007a" }}
      >
        <img src={loader} alt="" className="img-fluid" style={{ height: 50 }} />
      </div>
    </>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import unitedStatesIMg from "../../../../Assets/images/unitedStates.png";
import indonesiaImg from "../../../../Assets/images/indonesia.png";
import { toast } from "react-toastify";

const options = [
  {
    id: 2,
    label: "USD",
    value: "us",
  },
  {
    id: 1,
    label: "IDR",
    value: "rp",
  },
];

const RenderSingleExchangeItem = ({ type, value }) => {
  return (
    <>
      <div className="d-flex align-items-start">
        <p className="m-0 text-muted fw-sbold me-2">
          {type === "rp" ? "IDR" : "USD"}
        </p>
        <span className="icn">
          <img
            style={{
              width: 40,
              height: 35,
            }}
            src={type === "rp" ? indonesiaImg : unitedStatesIMg}
            alt=""
          />
        </span>
      </div>
      <p className="m-0  fw-sbold">
        {type === "rp" ? "Rp" : "$"}{" "}
        {Number(value).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </p>
    </>
  );
};

const WithdrawalExchange = ({
  setStep,
  setExchangeData,
  currentBankSelected,
}) => {
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state?.Profile);
  const [selectedCurrency, setSelectedCurrency] = useState({
    id: 2,
    label: "USD",
    value: "us",
  });

  const exchangeRate = userProfile?.exchangeRate;

  const [inputValue, setInputValue] = useState(0);

  const [dollaramount, setDollarAmount] = useState(0);
  const [rupeeAmount, setRupeeAmount] = useState(0);

  const handleOnAmountChange = (e) => {
    // setAmount(text);
    setInputValue(e.target.value);
    selectedCurrency.value === "us"
      ? setDollarAmount(e.target.value)
      : selectedCurrency.value === "rp"
      ? setRupeeAmount(e.target.value)
      : null;
  };

  useEffect(() => {
    if (selectedCurrency.value === "us") {
      setRupeeAmount(dollaramount * exchangeRate);
    }
    if (selectedCurrency.value === "rp") {
      setDollarAmount(rupeeAmount / exchangeRate);
    }
  }, [dollaramount, rupeeAmount]);

  const handleClearAmount = () => {
    setDollarAmount(0);
    setRupeeAmount(0);
    setInputValue(0);
  };

  const submitHandler = () => {
    if (dollaramount < 5) {
      return toast.error("please enter Minimum $5");
    }

    setExchangeData({
      dollaramount: dollaramount,
      rupeeAmount: rupeeAmount,
    });
    setStep(4);
  };

  const path = window.location.href;

  const arr = path.split("/");
  const type = arr[arr.length - 1];

  return (
    <>
      <section className="WithdrawalExchange position-relative py-3">
        <Container>
          <Row>
            {" "}
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap  gap-10">
                <div
                  className="left d-flex align-items-center gap-10"
                  onClick={() => setStep((p) => p - 1)}
                >
                  {/* <Link to={"/my-wallet/bank-transfer"}> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M15.4141 8.086L3.82806 8.086L8.32806 12.586L6.91406 14L6.07329e-05 7.086L6.91406 0.172001L8.32806 1.586L3.82806 6.086L15.4141 6.086L15.4141 8.086Z"
                      fill="var(--darkLightRed)"
                    />
                  </svg>
                  {/* </Link> */}
                  <h5 className="m-0 fw-sbold ">
                    {" "}
                    {type === "deposit" ? "Deposit" : "Withdrawal"} Money
                  </h5>
                </div>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div
                className="d-flex align-items-center justify-content-between gap-10 p-2 rounded"
                style={{ background: "#39070A" }}
              >
                <div className="left d-flex align-items-center gap-10">
                  <span className="icn flex-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                    >
                      <path
                        d="M20.24 3.45455H2.64C2.40661 3.45455 2.18278 3.36356 2.01775 3.20159C1.85271 3.03963 1.76 2.81996 1.76 2.59091C1.76 2.36186 1.85271 2.14219 2.01775 1.98023C2.18278 1.81826 2.40661 1.72727 2.64 1.72727H17.6C17.8334 1.72727 18.0572 1.63628 18.2223 1.47432C18.3873 1.31236 18.48 1.09269 18.48 0.863636C18.48 0.634586 18.3873 0.414917 18.2223 0.252953C18.0572 0.0909902 17.8334 0 17.6 0H2.64C1.93983 0 1.26833 0.27297 0.773238 0.75886C0.278142 1.24475 0 1.90376 0 2.59091V16.4091C0 17.0962 0.278142 17.7553 0.773238 18.2411C1.26833 18.727 1.93983 19 2.64 19H20.24C20.7068 19 21.1544 18.818 21.4845 18.4941C21.8146 18.1702 22 17.7308 22 17.2727V5.18182C22 4.72372 21.8146 4.28438 21.4845 3.96045C21.1544 3.63653 20.7068 3.45455 20.24 3.45455ZM20.24 17.2727H2.64C2.40661 17.2727 2.18278 17.1817 2.01775 17.0198C1.85271 16.8578 1.76 16.6381 1.76 16.4091V5.03392C2.04254 5.13231 2.3402 5.18233 2.64 5.18182H20.24V17.2727ZM14.96 10.7955C14.96 10.5392 15.0374 10.2888 15.1825 10.0757C15.3275 9.8627 15.5337 9.69666 15.7749 9.59861C16.0161 9.50056 16.2815 9.47491 16.5375 9.52489C16.7936 9.57488 17.0288 9.69826 17.2134 9.87943C17.398 10.0606 17.5237 10.2914 17.5746 10.5427C17.6256 10.794 17.5994 11.0545 17.4995 11.2912C17.3996 11.5279 17.2304 11.7302 17.0134 11.8726C16.7963 12.0149 16.5411 12.0909 16.28 12.0909C15.9299 12.0909 15.5942 11.9544 15.3466 11.7115C15.0991 11.4685 14.96 11.139 14.96 10.7955Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <p className="m-0 text-white fw-sbold">Current Cash</p>
                </div>
                <div className="right">
                  <p className="m-0 text-white">
                    (${userProfile?.cash_withdrawable})
                  </p>
                </div>
              </div>
              <Row className="align-items-center mt-3">
                <Col md="3" className="my-2">
                  <div className="cardCstm box p-3 h-100">
                    <p className="m-0  fw-sbold">Amount</p>
                    <div className="d-flex align-items-center justify-content-between gap-10 pt-3">
                      <Form.Select
                        className="form-control h-auto w-auto bg-transparent border-0"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setDollarAmount(0);
                          setRupeeAmount(0);
                          setSelectedCurrency((p) => {
                            const findObj = options.find(
                              (i) => i.value === e.target.value
                            );
                            return findObj;
                          });
                        }}
                        value={selectedCurrency?.value}
                      >
                        {options.map((item) => {
                          return (
                            <option className="bg-black" value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <input
                        type="number"
                        placeholder="$"
                        value={inputValue}
                        className="form-control border-0 p-0 fw-bold  bg-transparent"
                        onChange={handleOnAmountChange}
                      />
                      <Button
                        className="border-0 p-0"
                        variant="transparent"
                        onClick={handleClearAmount}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.1687 0.166016C4.0212 0.166016 0.668701 3.51852 0.668701 7.66602C0.668701 11.8135 4.0212 15.166 8.1687 15.166C12.3162 15.166 15.6687 11.8135 15.6687 7.66602C15.6687 3.51852 12.3162 0.166016 8.1687 0.166016ZM11.3937 10.891C11.3243 10.9605 11.2419 11.0157 11.1512 11.0533C11.0604 11.091 10.9632 11.1103 10.865 11.1103C10.7667 11.1103 10.6695 11.091 10.5787 11.0533C10.488 11.0157 10.4056 10.9605 10.3362 10.891L8.1687 8.72352L6.0012 10.891C5.86097 11.0312 5.67077 11.11 5.47245 11.11C5.27413 11.11 5.08393 11.0312 4.9437 10.891C4.80347 10.7508 4.72469 10.5606 4.72469 10.3623C4.72469 10.2641 4.74403 10.1668 4.78161 10.0761C4.81918 9.98538 4.87426 9.90295 4.9437 9.83352L7.1112 7.66602L4.9437 5.49852C4.80347 5.35828 4.72469 5.16809 4.72469 4.96977C4.72469 4.77145 4.80347 4.58125 4.9437 4.44102C5.08393 4.30078 5.27413 4.222 5.47245 4.222C5.67077 4.222 5.86097 4.30078 6.0012 4.44102L8.1687 6.60852L10.3362 4.44102C10.4056 4.37158 10.4881 4.3165 10.5788 4.27892C10.6695 4.24134 10.7668 4.222 10.865 4.222C10.9631 4.222 11.0604 4.24134 11.1511 4.27892C11.2418 4.3165 11.3243 4.37158 11.3937 4.44102C11.4631 4.51045 11.5182 4.59289 11.5558 4.68361C11.5934 4.77433 11.6127 4.87157 11.6127 4.96977C11.6127 5.06796 11.5934 5.1652 11.5558 5.25592C11.5182 5.34665 11.4631 5.42908 11.3937 5.49852L9.2262 7.66602L11.3937 9.83352C11.6787 10.1185 11.6787 10.5985 11.3937 10.891Z"
                            fill="var(--darkLightRed)"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col md="1" className="my-2 text-center">
                  <Button className="border-0 p-0" variant="transparent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <rect
                        width="48"
                        height="48"
                        rx="24"
                        transform="matrix(1 0 0 -1 0 48)"
                        fill="#CF0A10"
                      />
                      <mask
                        id="mask0_9_93"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="48"
                        height="48"
                      >
                        <rect
                          width="48"
                          height="48"
                          rx="24"
                          transform="matrix(1 0 0 -1 0 48)"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_9_93)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17 23C17 23.5523 16.5523 24 16 24C15.4477 24 15 23.5523 15 23C15 19.134 18.134 16 22 16L29.5852 16L28.2926 14.7072L28.2094 14.6129C27.9043 14.2207 27.932 13.6534 28.2925 13.2929C28.683 12.9024 29.3161 12.9023 29.7067 13.2928L32.7071 16.2928C32.7365 16.3223 32.7641 16.3535 32.7896 16.3863L32.7903 16.3871C32.8459 16.4587 32.8905 16.5361 32.924 16.617C32.973 16.735 33 16.8643 33 17C33 17.2559 32.9024 17.5119 32.7071 17.7072L29.7067 20.7072L29.6125 20.7903C29.2202 21.0953 28.6529 21.0676 28.2925 20.7071L28.2093 20.6129C27.9043 20.2205 27.9321 19.6533 28.2926 19.2928L29.5852 18H22L21.7831 18.0046C19.1223 18.1182 17 20.3112 17 23Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M19.7066 34.7071C19.3161 35.0976 18.6829 35.0976 18.2924 34.7071L15.2924 31.7071L15.2895 31.7038C15.2624 31.6765 15.2369 31.6476 15.2131 31.6173L15.2092 31.6129C14.9042 31.2206 14.9319 30.6534 15.2924 30.2929L18.2924 27.2929L18.3866 27.2097C18.7789 26.9047 19.3461 26.9324 19.7066 27.2929L19.7898 27.3871C20.0948 27.7794 20.0671 28.3466 19.7066 28.7071L18.4138 30H25.9999L26.2168 29.9954C28.8776 29.8818 30.9999 27.6888 30.9999 25C30.9999 24.4477 31.4476 24 31.9999 24C32.5522 24 32.9999 24.4477 32.9999 25C32.9999 28.866 29.8659 32 25.9999 32H18.4138L19.7066 33.2929L19.7898 33.3871C20.0948 33.7794 20.0671 34.3466 19.7066 34.7071Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </Button>
                </Col>
                <Col md="3" className="my-2">
                  <div className="cardCstm box p-3 h-100">
                    <p className="m-0  fw-sbold">Exchange</p>
                    <div className="d-flex align-items-center justify-content-between gap-10 pt-4">
                      <div className="left">
                        <RenderSingleExchangeItem
                          type={selectedCurrency.value !== "rp" ? "rp" : "us"}
                          value={
                            selectedCurrency.value !== "rp"
                              ? rupeeAmount
                              : dollaramount
                          }
                        />
                      </div>
                      <span className="">=</span>
                      <div className="right">
                        <RenderSingleExchangeItem
                          type={selectedCurrency.value === "rp" ? "rp" : "us"}
                          value={
                            selectedCurrency.value === "rp"
                              ? rupeeAmount
                              : dollaramount
                          }
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg="12" className="my-2">
              <div className="btnWrpper text-end mt-4">
                <Button
                  onClick={() => submitHandler()}
                  className="d-inline-flex align-items-center justify-content-center commonBtn"
                  style={{ height: 50, minWidth: 150 }}
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WithdrawalExchange;

// LOGIN
// export const POST_LOGIN = "/user/login"
export const POST_LOGIN = "/user/login";
export const GET_SETTINGS = "/setting/view/";

// SIGNUP
export const POST_REGISTER = "/user/signup";
// kyc
export const POST_KYC_PROFILE = "/user/kyc";

// File
export const POST_FILE = "/adminSetting/uploadFile";
export const POST_IPFS_FILE = "/file/ipfs";

// User
export const GET_CURRENT_USER = "/icouser/profile";
export const PUT_PROFILE = "/user/updateprofile";

export const PUT_PROFILE_IMAGE = "/icouser/updateProfileImage";
export const GET_USER_BY_WALLET = "/icouser/userby/";

export const GET_USER_PROFILE = "/wallet/viewProfile";

export const GET_MARKET_DETAILS = "/adminSetting/listMarketAvailability";

// Home
export const GET_HOMEPAGE = "/home/";
export const GET_MOSTLY_VIEW = "/home/mostly-viewed-nft";
export const GET_LATEST = "/home/latest-nft";
export const GET_TOP_COLLECTIONS = "/home/top-collections";
export const GET_EXPLORE = "/home/expore-nft";

// Collections
export const GET_COLLECTION_NAME_VERIFY = "/collection/byname/";
export const GET_COLLECTION_BYTECODE = "/collection/compile-contract";
export const GET_COLLECTIONS = "/collection/list";
export const POST_COLLECTION = "/collection/add";
export const GET_COLLECTION = "/collection/view/";
export const GET_COLLECTION_BY_ADDRESS = "/collection/viewByAddress/";
export const PUT_COLLECTION = "/collection/update";

// Teams
export const GET_TEAMS = "/executive/team/list";

// Items
export const GET_ITEMS = "/item/list";
export const POST_ITEM = "/item/user/add";
export const PUT_ITEM = "/item/user/update";
export const GET_ITEM_BY_TOKEN = "/item/tokenhash/";
export const POST_ITEM_SALE = "/item/user/sale";
export const GET_ACTIVITIES = "/item/activity";
export const POST_ITEM_BUY = "/item/user/buy";
export const GET_ITEM = "/item/user/view/";
export const POST_TOGGLE_ITEM_SALE = "/item/user/sale/toggle";
/* Bids */
export const POST_BID = "/item/user/addoffer";
export const DELETE_BID = "/item/user/removeoffer";
export const POST_BID_ACTION = "/item/user/actionoffer";
export const GET_BIDS = "/item/user/offers";

// Faq
export const GET_FAQS = "/faq/list";

// Content Pages
export const GET_CONTENT_PAGE = "/page/view";

// Contact Us
export const POST_CONTACT_US = "/admin/contact-us";

export const GET_NOTIFICATIONS = "/item/user/notifications";

export const SEND_OTP = "/wallet/emailVerification";
export const SEND_OTP_MOBILE = "/wallet/phoneVerification";
// export const VERIFY_OTP = "/wallet/verifyOTP";

//forgot password
export const VERIFY_OTP_FOR_FPWD = "/user/verifyOtp";
export const FORGOT_PASSWORD = "/user/forgotPassword";
export const REST_PASSWORD = "/user/resetPassword";

//register
export const SIGNUP_AND_CREATE_WALLET = "/wallet/createwallet";
export const GENERATE_MNEMONIC = "/wallet/generateMnemonic";

// compare stock

export const COMPARE_STOCK = "stock/compareStock";
// transactions
export const ACCOUNT_ACTIVITIES = "/user/account/activities";
export const TRANSACTION_HISTORY = "/user/get/transaction";

// Banks
export const ADD_BANK_ACCOUNT = "/bankaccount/add";
export const GET_BANK_ACCOUNTS = "/bankaccount/get";
export const WITHDRAWAL = "/payment/withdrawalPaymentRequest";
export const DEPOSIT = "/payment/depositPaymentRequest";
export const TRANSACTION_UPDATE = "/payment/updateTransactionFile";

//crate account apirone
export const GENERATE_WALLET = "/wallet/generateWallet";

export const SEARCH_COIN = "/user/getCryptoByName";
export const ON_GET_CRYPTO_LIST = "/wallet/getWalletList";
export const SEND_TRANSACTION = "/wallet/sendTransaction";
export const ON_VERFIY_WALLET_ADDRESS = "/wallet/verifyAddress";
export const GET_HISTORY = "/wallet/history";
// export const GET_HISTORY = "/wallet/receiptList";

export const VERIFY_PASSWORD = "/user/verifyPassword";
// price alert

export const SET_ALERT_PRICE = "/user/addPriceAlert";

// Launchpad
export const GET_LAUNCHPAD = "/launchpad/view";
export const POST_LAUNCHPAD = "/launchpad/apply";
export const POST_LAUNCHPAD_WHITELIST = "/launchpad/whitelist";

export const POST_CONTACT_FORM = "/contact/add";

export const FORGOT_MPIN = "/wallet/forgotMpin";
//CMS
export const GET_CMS_CONTENT = "/admin/termsandconditions";
export const TRUSTED_CONTENT_API = "/user/updateProfile";
//profile
export const GET_PROFILE = "/user/profile";
//Change password
export const POST_CHANGE_PASSWORD = "/user/changePassword";
//home
export const MAIL_HOME = "/stock/dividens";
export const TOP_GAINER_API = "/stock/gainers";
export const TOP_LOSSER_API = "/stock/losers";
export const INDUSTRY_API = "/user/get/industries";
export const POPULAR_STOCK_API = "/stock/popular";
export const FAVOURITE_STOCK_API = "/stock/get/bookmark";
export const ADD_REMOVE_FAVOURITE_STOCK_API = "/stock/add/bookmark";
export const NOTIFICATION_API = "/user/notificationsWithFilter";
export const APP_MESSAGE_API = "/user/getMessages";
export const HELP_SUPPORT_API = "/admin/sendSupportMessage";
export const ALL_STOCK_API = "/stock/list";
export const OPEN_POSITION_API = "/order/getPosition";
export const PENDING_POSITION_API = "/order/getWithFilters";
export const SCHEDULE_ORDER_API = "/order/getScheduledOrders";
export const ORDER_HISTORY_API = "/order/getClosedPositionPagination";
export const TRADING_ACTIVITY_API = "/user/account/activities";
export const STOCK_DETAIL_API = "/stock/get/details";
export const CANCEL_SCHEDULE_ORDER = "/order/cancel/schedule/order";
//contact us
export const POST_CONTACT = "/contact/add";
//verify otp
export const VERIFY_OTP = "/user/verifyotp";
//resend otp
export const RESEND_OTP = "/user/resendOtp";
//Crypto price
export const CRYPTO_PRICE =
  "https://min-api.cryptocompare.com/data/pricemultifull";
//get coins
export const GET_COINS_PRICE = "/crypto/crypto-list";
// affiliate market

export const BECOME_AFFILIATE_REQUEST = "/customerAffiliated/addRequest";
export const AFFILIATE_TRANSACTION_LIST =
  "/customerAffiliated/listCommissionRequest";
export const AFFILIATE_CLIENT_LIST = "/customerAffiliated/listAffiliatedUsers";

export const STOCK_BUY_SELL = "/order/add";

export const CLOSE_ORDER = "/order/close/position";
export const CANCEL_ORDER = "/order/cancel/order";
export const NEWS_LIST = "/user/getNewsList";

import * as TYPES from "./actionTypes";

export const getCMS = (data, callback) => ({
  type: TYPES.GET_CMS,
  payload: { data, callback },
});

export const getCMSFail = (error) => ({
  type: TYPES.GET_CMS_FAIL,
  payload: error,
});

export const getCMSSuccess = (data) => ({
  type: TYPES.GET_CMS_SUCCESS,
  payload: data,
});
//terms accept
export const trustContent = (data, callback) => ({
  type: TYPES.TRUST_CONTENT,
  payload: { data, callback },
});

export const trustContentFail = (error) => ({
  type: TYPES.TRUST_CONTENT_FAIL,
  payload: error,
});

export const trustContentSuccess = (data) => ({
  type: TYPES.TRUST_CONTENT_SUCCESS,
  payload: data,
});

import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
} from "./actions"

import { postForgetPwd } from "helpers/backend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, callback } }) {
  try {
    const response = yield call(postForgetPwd, user)

    if (response.status == "failure") {
      return yield put(userForgetPasswordError(response.message))
    }

    callback && callback(response)
    yield put(userForgetPasswordSuccess(response.data))
  } catch (error) {
    yield put(userForgetPasswordError("Internal Server Error"))
  }
}

// function* resetUser({ payload: { user, callback } }) {
//   try {
//     const response = yield call(postResetPwd, user)

//     if (response.status == "failure") {
//       return yield put(userResetPasswordError(response.message))
//     }

//     callback && callback()
//     yield put(userResetPasswordSuccess(response.message))
//   } catch (error) {
//     yield put(userResetPasswordError("Internal Server Error"))
//   }
// }

export function* watchUserPasswordForget() {
  // yield takeEvery(FORGET_PASSWORD, forgetUser)
  // yield takeLatest(RESET_PASSWORD, resetUser)
}
function* Saga() {
  yield takeLatest(FORGET_PASSWORD, forgetUser);
}
export default Saga;

import React, { useState } from "react";
import transactionImg from "../../../../Assets/images/transaction.png";
import { useSelector } from "react-redux";
import moment from "moment";
import { formatStandardNumberWithMaxDecimal } from "helpers/helper";
import HistoryDetailModal from "./HistoryDetailsModel";

export const formatDateTime = (
  utcDateTime,
  timezone = "America/New_York",
  key
) => {
  // Define format strings based on the required key
  const formats = {
    date: "DD MMMM YYYY",
    date2: "DD-MM-YY",
    time: "hh:mm A",
    dateNTime: "DD MMMM YYYY hh:mm A",
  };

  // Validate if a correct format key is provided
  if (!formats[key]) {
    throw new Error(
      'Invalid format key provided. Use "date", "time", or "dateNTime".'
    );
  }

  // Perform the formatting
  return moment.utc(utcDateTime).tz(timezone).format(formats[key]);
};

const ActivityList = ({ data, loader }) => {
  const { userProfile } = useSelector((state) => state?.Profile);

  let userTimezone = userProfile?.timezone;

  const [showHistoryModel, setShowHistoryModel] = useState({
    show: false,
    details: null,
  });

  return (
    <>
      <HistoryDetailModal
        show={showHistoryModel}
        setShow={setShowHistoryModel}
        type={"activity"}
        // stockView={}
      />
      <div className="py-2">
        <ul className="list-unstyled ps-0 mb-0">
          {data?.map((item) => {
            return (
              <li className="py-2">
                <div
                  className="box rounded cardCstm"
                  style={{ fontSize: 10, lineHeight: "12px" }}
                  onClick={() => {
                    setShowHistoryModel((p) => ({
                      show: true,
                      details: item,
                    }));
                  }}
                >
                  <div className=" p-2 top d-flex align-items-center justify-content-between gap-10">
                    <div className="left d-flex align-items-center gap-10">
                      <div className="imgWrp d-flex align-items-center justify-content-center flex-shrink-0  border border-secondary rounded">
                        <img
                          src={transactionImg}
                          alt=""
                          className="img-fluid"
                          style={{
                            background: item.net_amount > 0 ? "green" : "red",
                            height: 40,
                            width: 40,
                          }}
                        />
                      </div>
                      <div className="content ">
                        <h6 className="m-0" style={{ color: "#82C3FF" }}>
                          {item?.activity_type ?? "Deposit"}
                        </h6>
                        <p className="m-0  fw-bold pt-1 m-0">
                          {formatDateTime(item?.date, userTimezone, "date")}
                        </p>
                      </div>
                    </div>
                    <div className="right text-end">
                      <h6
                        className="m-0  fw-sbold"
                        style={{
                          color: Number(item.net_amount) > 0 ? "green" : "red",
                        }}
                      >
                        {` ${
                          item.net_amount > 0 ? "+" : "-"
                        } $${formatStandardNumberWithMaxDecimal(
                          item?.net_amount>0?item?.net_amount:Math.abs(item?.net_amount),
                          2
                        )}`}
                      </h6>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {data?.length === 0 && !loader && (
          <div
            className="box rounded cardCstm my-3"
            style={{ fontSize: 10, lineHeight: "12px" }}
          >
            <div className="py-5 text-center">
              <h6 className="m-0  fw-sbold">No Data available</h6>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityList;

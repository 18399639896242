import React, { useState } from "react";

// css
import styles from "./WalletSidebar.module.scss";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

const WalletSidebar = ({ wSidebar, setWsidebar, type }) => {
  const [addbank, setAddbank] = useState(1);

  const handleWSidebar = () => {
    setWsidebar(!wSidebar);
  };
  return (
    <>
      <div
        className={`${styles.WalletSidebar} ${
          wSidebar && styles.active
        } WalletSidebar position-fixed pt-4 px-3`}
      >
        <div className="top py-3 position-relative text-center">
          <Button
            onClick={handleWSidebar}
            style={{ top: 0, left: 0 }}
            className="border-0 p-0 position-absolute"
            variant="transparent"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.625 6.725L12.7 0.499999H16.975V0.675L11.075 9.2L17.075 17.875V18H12.75L8.625 11.85L4.5 18H0.175V17.875L6.125 9.2L0.25 0.675V0.499999H4.525L8.625 6.725Z"
                fill="white"
              />
            </svg>
          </Button>
          <h6 className="m-0 fw-bold text-white">
            {type == "deposit" ? <>Deposit Funds</> : type == "withdraw" ? "Withdraw Funds" : ""}
          </h6>
        </div>
        <div className="contentBody py-3">
          {type == "deposit" ? (
            <>
              {addbank == 1 ? (
                <>
                  <Form>
                    <div className="py-2">
                      <label htmlFor="" className="form-label m-0 pb-1 text-muted">
                        Amount to Deposit
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-pill"
                        placeholder="$ Enter amount to deposit"
                      />
                      <p className="text-success m-0">Between $10 - $1000,00</p>
                    </div>

                    <div className="py-2">
                      <label htmlFor="" className="form-label m-0 pb-1 text-white">
                        Choose a Bank Account
                      </label>
                      <Dropdown className={`${styles.BankDropdown}`}>
                        <Dropdown.Toggle
                          className="rounded-0 w-100 text-start d-flex justify-content-between align-items-center"
                          style={{ background: "#222", borderColor: "#222" }}
                          id="dropdown-basic"
                        >
                          <div className="content">
                            <p className="text-white m-0 fw-bold">ABCD BANK</p>
                            <p className="text-white m-0">XXXX-XXXXXXX-2589</p>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className={` btnWrpper mt-4`}>
                      <Button
                        className="d-flex align-items-center my-3 justify-content-center w-100 commonBtn"
                        style={{ height: 50 }}
                      >
                        Add Money
                      </Button>
                      <Button
                        onClick={() => setAddbank(2)}
                        className="d-flex align-items-center my-3 justify-content-center w-100 commonBtn grayBtn"
                        style={{ height: 50 }}
                      >
                        Add New Card
                      </Button>
                    </div>
                  </Form>
                </>
              ) : addbank == 2 ? (
                <>
                  <Form>
                    <Row>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Card Holder Name
                        </label>
                        <input type="text" className="form-control rounded-pill" />
                      </Col>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0">
                          Card Number
                        </label>
                        <input type="text" className="form-control rounded-pill" />
                      </Col>
                      <Col className="my-2 col-6">
                        <label htmlFor="" className="form-label m-0">
                          Exp. Date
                        </label>
                        <input type="text" className="form-control rounded-pill" />
                      </Col>
                      <Col className="my-2 col-6">
                        <label htmlFor="" className="form-label m-0">
                          Security Code
                        </label>
                        <input type="text" className="form-control rounded-pill" />
                      </Col>
                      <Col lg="12" className="my-2">
                        <div className="flex align-items-center flex-wrap gap-10">
                          <label htmlFor="" className="form-label m-0">
                            Save Card For Furture Payment
                          </label>
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label=""
                            className="d-inline-block ms-2"
                          />
                        </div>
                      </Col>
                      <Col lg="12">
                        <Button
                          className="d-flex align-items-center my-3 justify-content-center w-100 commonBtn"
                          style={{ height: 50 }}
                        >
                          Add Card
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                <></>
              )}
            </>
          ) : type == "withdraw" ? (
            <>
              <Form>
                <div className="py-2">
                  <label htmlFor="" className="form-label m-0 pb-1 text-muted">
                    Amount to Withdraw
                  </label>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    placeholder="$ Amount to Withdraw"
                  />
                </div>
                <div className={` btnWrpper mt-2`}>
                  <Button
                    className="d-flex align-items-center justify-content-center w-100 commonBtn rounded"
                    style={{ height: 50 }}
                  >
                    Place Withdraw Request
                  </Button>
                </div>
              </Form>
              <div className="py-2">
                <ul className="list-unstyled ps-0 mb-0">
                  <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                    <p className="text-muted m-0">Withdrawable Funds</p>
                    <p className="text-white fw-bold m-0">$0.00 </p>
                  </li>
                  <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                    <p className="text-muted m-0">Unsettled Funds</p>
                    <p className="text-white fw-bold m-0">$0.00 </p>
                  </li>
                  <li className="py-2 d-flex align-items-center justify-content-between gap-10">
                    <p className="text-muted m-0">Total</p>
                    <p className="text-white fw-bold m-0">$0.00 </p>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default WalletSidebar;

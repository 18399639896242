import { takeLatest, put, call } from "redux-saga/effects";

import * as TYPES from "./actionTypes";
import * as ACTION from "./actions";
import * as API from "helpers/backend_helper";

function* onGetNotification({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getNotification, data);
    if (response.status === "failure") {
      return yield put(ACTION.getNotificationFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.getNotificationSuccess(response));
  } catch (error) {
    yield put(ACTION.getNotificationFail(error));
  }
}

function* onGetAppMessages({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getMessageApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.getAppMessagesFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.getAppMessagesSuccess(response));
  } catch (error) {
    yield put(ACTION.getAppMessagesFail(error));
  }
}
function* onAddHelpSupport({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getHelpSupport, data);
    if (response.status === "failure") {
      return yield put(ACTION.addHelpSupportFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.addHelpSupportSuccess(response));
  } catch (error) {
    yield put(ACTION.addHelpSupportFail(error));
  }
}
function* onAllStockList({ payload: { data, callback } }) {
  try {
    const response = yield call(API.getAllStock, data);
    if (response.status === "failure") {
      return yield put(ACTION.allStockListFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.allStockListSuccess(response));
  } catch (error) {
    yield put(ACTION.allStockListFail(error));
  }
}
function* onOpenPositions({ payload: { data, callback } }) {
  try {
    const response = yield call(API.openPositionApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.openPositionsFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.openPositionsSuccess(response));
  } catch (error) {
    yield put(ACTION.openPositionsFail(error));
  }
}
function* onPendingPositions({ payload: { data, callback } }) {
  try {
    const response = yield call(API.pendingPositionApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.pendingPositionsFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.pendingPositionsSuccess(response));
  } catch (error) {
    yield put(ACTION.pendingPositionsFail(error));
  }
}
function* onScheduleOrders({ payload: { data, callback } }) {
  try {
    const response = yield call(API.scheduleOrderApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.scheduleOrderFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.scheduleOrderSuccess(response));
  } catch (error) {
    yield put(ACTION.scheduleOrderFail(error));
  }
}

function* onStockHistoryOrder({ payload: { data, callback } }) {
  try {
    console.log(data, "data");
    const response = yield call(API.orderHistoryApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.stockHistoryOrderFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.stockHistoryOrderSuccess(response));
  } catch (error) {
    yield put(ACTION.stockHistoryOrderFail(error));
  }
}

function* onTradingActivities({ payload: { data, callback } }) {
  try {
    const response = yield call(API.tradingActivityApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.tradingActivitiesFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.tradingActivitiesSuccess(response));
  } catch (error) {
    yield put(ACTION.tradingActivitiesFail(error));
  }
}
function* onStockDetail({ payload: { data, callback } }) {
  try {
    const response = yield call(API.stockDetailApi, data);
    if (response.status === "failure") {
      return yield put(ACTION.stockSingleDetialFail(response.message));
    }
    callback && callback(response);
    yield put(ACTION.stockSingleDetialFailSuccess(response));
  } catch (error) {
    yield put(ACTION.stockSingleDetialFail(error));
  }
}

function* Saga() {
  yield takeLatest(TYPES.GET_NOTIFICATION, onGetNotification);
  yield takeLatest(TYPES.GET_MESSAGES, onGetAppMessages);
  yield takeLatest(TYPES.ADD_HELP_SUPPORT, onAddHelpSupport);
  yield takeLatest(TYPES.ALL_STOCK_LIST, onAllStockList);
  yield takeLatest(TYPES.OPEN_POSITIONS, onOpenPositions);
  yield takeLatest(TYPES.PENDING_POSITIONS, onPendingPositions);
  yield takeLatest(TYPES.SCHEDULE_ORDER, onScheduleOrders);
  yield takeLatest(TYPES.STOCK_HISTORY_ORDER, onStockHistoryOrder);
  yield takeLatest(TYPES.TRADING_ACTIVITIES, onTradingActivities);
  yield takeLatest(TYPES.STOCK_SINGLE_DETAIL, onStockDetail);
}
export default Saga;

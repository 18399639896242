export const CONSTANT = {
  marketPreMarket: "premarket",
  marketAfterMarket: "aftermarket",
  marketOpen: "open",
  marketClosed: "closed",
  marketHoliday: "holiday",
  // market types
  extendedHours: "extended-hours",
  market: "market",
  open: "open",
  closed: "closed",

   //


  // AffiliateStatus
  PendingAffiliate: "PENDING",
  ApprovedAffiliate: "APPROVED",
  RejectedAffiliate: "REJECTED",
  // Affiliated Types

  // Market
  Market: "market",
  Limit: "limit",
  Stop: "stop",

  // Purchase Type
  Share: "Share",
  Money: "Money",

  //
  DAILY: "everyday",
  WEEKLY: "everyweek",
  MONTHLY: "everymonth",
  //
  BUY: "BUY",
  SELL: "SELL",
    // Market
    Market: 'market',
    Limit: 'limit',
    Stop: 'stop',
};

import * as TYPES from "./actionTypes";

export const loginUser = (user, navigate) => {
  return {
    type: TYPES.LOGIN_USER,
    payload: { user, navigate },
  };
};

export const loginSuccess = (user) => {
  return {
    type: TYPES.LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (navigate, role) => {
  return {
    type: TYPES.LOGOUT_USER,
    payload: { navigate, role },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: TYPES.LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: TYPES.API_ERROR,
    payload: error,
  };
};

export const getCurrentUser = () => ({
  type: TYPES.GET_CURRENT_USER,
});

export const getCurrentUserSuccess = (user) => ({
  type: TYPES.GET_CURRENT_USER_SUCCESS,
  payload: user,
});

export const getCurrentUserFail = (error) => ({
  type: TYPES.GET_CURRENT_USER_FAIL,
  payload: error,
});

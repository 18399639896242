import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import logo from "../../Assets/images/logo-placeholder.png";

// Images
// import avatar from "images/user.png"

// Actions
import { uploadFile } from "store/actions";

function DropZone(props) {
  const dispatch = useDispatch();
  const [imageFile, setimageFile] = useState({ file: null, url: null });

  useEffect(() => {
    if (props.file) {
      setimageFile((pre) => ({
        ...pre,
        url: props.file,
      }));
    }
  }, [props.file]);

  const handleFiles = (_files) => {
    const files = _files.filter((file) => file.size < 10e6);

    if (files.length < _files.length) return toast.error("Max file size. 5MB");

    const callback = (response) => {
      console.log(response, "-res");
      props.handleChange({ target: { value: response.data.url } });
    };

    dispatch(uploadFile(files[0], callback));
    setimageFile((pre) => ({
      ...pre,
      file: files[0],
      url: URL.createObjectURL(files[0]),
    }));
  };

  return (
    <Dropzone onDrop={handleFiles}>
      {({ getRootProps, getInputProps }) => (
        <div className="dropzone-single-image avatar-xl" {...getRootProps()}>
          <input
            {...getInputProps()}
            id="formrow-profile-image-Input"
            multiple={false}
          />

          {props.children ? (
            props.children
          ) : imageFile.file?.type?.includes("video") ||
            imageFile.url?.includes("video") ? (
            <video
              width={"100%"}
              height={120}
              controls
              autoPlay={true}
              loop
              className="video_preview"
            >
              <source src={imageFile.url} type={"video/mp4"} />
            </video>
          ) : (
            <>
              <img
                className="rounded avatar-xl image-path"
                alt={""}
                src={!!imageFile.url ? imageFile?.url : logo}
              />

              <div className="edit">
                <i className="bx bx-pencil"></i>
              </div>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
}

DropZone.propTypes = {
  file: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(DropZone);

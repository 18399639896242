import { handleDecimal, handleDecimalToViewOnly } from "helpers/helper";
import { formatDateTime } from "pages/SideTabPages/MyWallet/tabbed/ActivityList";
import React from "react";
import { Button, Modal, Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const typeEnum = {
  TradingActivities: "TradingActivities",
  Orders: "Orders",
};

function HistoryOrderDetailModal({
  show,
  close,
  type,
  stockView: stockDetails,
}) {
  console.log(type, "type12");
  const { userProfile } = useSelector((state) => state?.Profile);

  let orderStatus = stockDetails?.status;
  let stockSide = stockDetails?.side;

  let userTimezone = userProfile?.timezone;
  return (
    <Modal
      show={show}
      onHide={close}
      centered // Centers the modal
    >
      <Modal.Header closeButton>Order Details</Modal.Header>
      <Modal.Body>
        <div className="py-2">
          <div
            className="d-flex align-items-center gap-10"
            style={{ fontSize: 10, lineHeight: "20px" }}
          >
            <div className="d-flex gap-10 align-items-center">
              <div className="imgWrp flex-shrink-0">
                <img
                  src={stockDetails?.imgUrl ? stockDetails?.imgUrl : ""}
                  alt=""
                  style={{ height: 50, width: 50 }}
                  className="img-fluid rounded object-fit-contain"
                />
              </div>
              <div className="content">
                <h5 className="m-0 fw-sbold  ">{stockDetails?.symbol}</h5>
                <p className="m-0  ">{stockDetails?.shortName}</p>
                <p>{stockDetails?.name}</p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="py-2">
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold text-muted">Order Type</p>
            <p className="m-0 fw-bold  ">{stockDetails?.side}</p>
          </li>
          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
            <p className="m-0 fw-bold text-muted">
              {type === typeEnum.Orders ? (
                <>
                  {orderStatus === "filled" ? (
                    <>
                      {stockSide === "buy"
                        ? "Buy Date"
                        : stockSide === "sell"
                        ? "Sell Date"
                        : ""}
                    </>
                  ) : orderStatus === "accepted" ? (
                    <>
                      {stockSide === "buy"
                        ? "Buy Date"
                        : stockSide === "sell"
                        ? "Sell Date"
                        : ""}
                    </>
                  ) : orderStatus === "canceled" ? (
                    <>Placed Date</>
                  ) : orderStatus === "pending_new" ? (
                    <>Placed Date</>
                  ) : orderStatus === "expired" ? (
                    <></>
                  ) : null}
                </>
              ) : (
                <>Date & Time</>
              )}
            </p>
            <p className="m-0 fw-bold  ">
              {type === typeEnum.Orders ? (
                <>
                  {orderStatus === "filled" ? (
                    <>
                      {formatDateTime(
                        stockDetails?.filled_at,
                        userTimezone,
                        "date"
                      )}
                      ,
                      {formatDateTime(
                        stockDetails?.filled_at,
                        userTimezone,
                        "time"
                      )}
                    </>
                  ) : orderStatus === "accepted" ? (
                    <>
                      {formatDateTime(
                        stockDetails?.filled_at,
                        userTimezone,
                        "date"
                      )}
                      ,
                      {formatDateTime(
                        stockDetails?.filled_at,
                        userTimezone,
                        "time"
                      )}
                    </>
                  ) : orderStatus === "canceled" ? (
                    <>
                      {formatDateTime(
                        stockDetails?.created_at,
                        userTimezone,
                        "date"
                      )}
                      ,
                      {formatDateTime(
                        stockDetails?.created_at,
                        userTimezone,
                        "time"
                      )}
                    </>
                  ) : orderStatus === "pending_new" ? (
                    <>
                      {formatDateTime(
                        stockDetails?.created_at,
                        userTimezone,
                        "dateNTime"
                      )}
                      ,
                      {formatDateTime(
                        stockDetails?.created_at,
                        userTimezone,
                        "time"
                      )}
                    </>
                  ) : orderStatus === "expired" ? (
                    <></>
                  ) : null}
                </>
              ) : (
                <>
                  {formatDateTime(
                    stockDetails?.transaction_time,
                    userTimezone,
                    "date"
                  )}
                  ,
                  {formatDateTime(
                    stockDetails?.transaction_time,
                    userTimezone,
                    "time"
                  )}
                </>
              )}

              {/* {formatDateTime(stockDetails?.createdAt, userTimezone, "date")} */}
            </p>
          </li>
          <div
            className="cardCstm box border-0 mt-3 px-3 py-1"
            style={{ background: "#222222" }}
          >
            <ul className="list-unstyled ps-0 mb-0 notLastBorder">
              {type === typeEnum.Orders ? (
                <>
                  {orderStatus === "filled" ||
                  orderStatus === "canceled" ||
                  orderStatus === "pending_new" ||
                  orderStatus === "pending" ||
                  orderStatus === "expired" ||
                  orderStatus === "accepted" ? (
                    <>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Id</p>
                        <p className="m-0 fw-bold  ">
                          {stockDetails?.client_order_id}
                        </p>
                      </li>

                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Quantity</p>
                        <p className="m-0 fw-bold  ">
                          {stockDetails?.filled_qty}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Order Type</p>
                        <p className="m-0 fw-bold  ">
                          {stockDetails?.order_type}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Side</p>
                        <p className="m-0 fw-bold  ">{stockDetails?.side}</p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Est. Amount</p>
                        <p className="m-0 fw-bold  ">
                          ${handleDecimal(stockDetails?.requestAmount)}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Req. Price</p>
                        <p className="m-0 fw-bold  ">
                          ${handleDecimal(stockDetails?.requestPrice)}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Time in force</p>
                        <p className="m-0 fw-bold  ">
                          {stockDetails?.time_in_force}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Status</p>
                        <p className="m-0 fw-bold  ">{stockDetails?.status}</p>
                      </li>


                      {orderStatus === "filled" ||
                      orderStatus === "accepted" ||
                      orderStatus === "pending_new" ? (
                        <>
                          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                            <p className="m-0 fw-bold text-muted">
                              Amount Executed
                            </p>
                            <p className="m-0 fw-bold  ">
                              ${handleDecimal(stockDetails?.executeAmount)}
                            </p>
                          </li>
                          <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                            <p className="m-0 fw-bold text-muted">
                              Executed Price
                            </p>
                            <p className="m-0 fw-bold  ">
                              ${handleDecimal(stockDetails?.filled_avg_price)}
                            </p>
                          </li>
                        </>
                      ) : null}
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Commission</p>
                        <p className="m-0 fw-bold  ">
                          ${handleDecimal(stockDetails?.commission)}
                        </p>
                      </li>
                      <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                        <p className="m-0 fw-bold text-muted">Scheduled</p>
                        <p className="m-0 fw-bold  ">
                          {stockDetails?.scheduled == "scheduled"
                            ? "true"
                            : "false"}
                        </p>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Order Id</p>
                    <p className="m-0 fw-bold  ">{stockDetails?.order_id}</p>
                  </li>

                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Id</p>
                    <p className="m-0 fw-bold  ">{stockDetails?.id}</p>
                  </li>

                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Activity Type</p>
                    <p className="m-0 fw-bold  ">
                      {stockDetails?.order_status}
                    </p>
                  </li>

                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Quantity</p>
                    <p className="m-0 fw-bold  ">
                      {handleDecimal(stockDetails?.qty, 9)}
                    </p>
                  </li>

                  <li className="d-flex align-items-center justify-content-between gap-10 py-2">
                    <p className="m-0 fw-bold text-muted">Price</p>
                    <p className="m-0 fw-bold  ">
                      ${handleDecimalToViewOnly(stockDetails?.price)}
                    </p>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default HistoryOrderDetailModal;

import React from "react";
import {formatDate} from "helpers/helper"

const InAppMessage = ({appMessageList}) => {
  return (
    <>
      <div className="py-2">
        <ul className="list-unstyled ps-0 mb-0">
          {appMessageList?.data?.length > 0  && appMessageList?.data?.map((list, i) => {
            return(
              <li className="py-1" key={i}>
                <div className="box d-flex align-items-center flex-wrap justify-content-between gap-10 p-2 rounded">
                  <div className="left d-flex align-items-center gap-10">
                    <div className="icn me-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M12.5 27.5C12.948 28.942 14.338 30 16 30C17.663 30 19.052 28.942 19.5 27.5H12.5Z"
                          fill="#FF822D"
                        />
                        <path
                          d="M16 6.55C15.31 6.55 14.75 5.99 14.75 5.3V3.25C14.75 2.91848 14.8817 2.60054 15.1161 2.36612C15.3505 2.1317 15.6685 2 16 2C16.3315 2 16.6495 2.1317 16.8839 2.36612C17.1183 2.60054 17.25 2.91848 17.25 3.25V5.3C17.25 5.99 16.69 6.55 16 6.55Z"
                          fill="#FFB02E"
                        />
                        <path
                          d="M27.6 22.843C26.64 22.07 26.06 20.916 25.82 19.693L24.09 10.793C23.32 6.85 19.94 4.01 16 4C12.06 4.01 8.68002 6.85 7.91002 10.793L6.18002 19.693C5.94002 20.916 5.36002 22.07 4.40002 22.843C3.96116 23.1989 3.60761 23.6487 3.36534 24.1592C3.12307 24.6697 2.99824 25.2279 3.00002 25.793V27.027C3.00002 27.569 3.43002 28 3.95002 28H28.05C28.58 28 29 27.569 29 27.027V25.793C29 24.589 28.45 23.535 27.6 22.843Z"
                          fill="#F9C23C"
                        />
                      </svg>
                    </div>
                    <div className="content">
                      <h6 className="m-0 ">{list?.title}</h6>
                      <p className="m-0 text-muted fw-sbold">
                        {/* {list?.message} */}
                        <div  dangerouslySetInnerHTML={{ __html: list?.message }} />
                      </p>
                    </div>
                  </div>
                  <div className="right">
                  <p className="m-0  fw-sbold">{formatDate(list?.createdAt)}</p>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  );
};

export default InAppMessage;

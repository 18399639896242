import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";

import logo from "../../../../Assets/images/logo.png";

// css
import styles from "../../../../layout/Auth/Auth.module.scss";

const MoneyWork = ({ step, setStep }) => {
  const optionsWork = [{ value: "employed", label: "Employed" }];
  const optionsOM = [{ value: "origin_of_money", label: "Origin of Money" }];
  const optionsLA = [{ value: "liquid_asset", label: "Liquid Asset" }];

  const [pass, setPass] = useState();
  const handlePass = () => {
    setPass(!pass);
  };
  return (
    <>
      <Col md="6" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
        >
          <span
            className="position-absolute graphic"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
          >
            <svg
              className="w-100 h-100"
              xmlns="http://www.w3.org/2000/svg"
              width="702"
              height="702"
              viewBox="0 0 702 702"
              fill="none"
            >
              <g opacity="0.3" filter="url(#filter0_f_0_599)">
                <circle cx="351" cy="351" r="117" fill="#CF0A10" />
              </g>
              <defs>
                <filter
                  id="filter0_f_0_599"
                  x="0"
                  y="0"
                  width="702"
                  height="702"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="117"
                    result="effect1_foregroundBlur_0_599"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <div className="formInner">
            <div
              className={`${styles.top} top py-2 text-center  position-relative`}
            >
              <h3 className="m-0 pb-2 fw-sbold head text-white">Money Work</h3>
              <p className="m-0 text-white">Please enter your details.</p>
            </div>
            <Form className="mx-auto py-3" style={{ maxWidth: 350 }}>
              <Row>
                <Col lg="12" className="my-2">
                  <input
                    type="text"
                    className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                    placeholder="Activities*"
                  />
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.cstmSelect} cstmSelect`}>
                    <Select classNamePrefix="deviden" options={optionsWork} />
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <input
                    type="text"
                    className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                    placeholder="Job Title*"
                  />
                </Col>
                <Col lg="12" className="my-2">
                  <input
                    type="text"
                    className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                    placeholder="Company's Name*"
                  />
                </Col>
                <Col lg="12" className="my-2">
                  <input
                    type="text"
                    className={`${styles.formControl} form-control text-white rounded-pill px-4`}
                    placeholder="Company's Address*"
                  />
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.cstmSelect} cstmSelect`}>
                    <Select classNamePrefix="deviden" options={optionsOM} />
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.cstmSelect} cstmSelect`}>
                    <Select classNamePrefix="deviden" options={optionsLA} />
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper`}>
                    <Button
                      onClick={() => setStep(3)}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </>
  );
};

export default MoneyWork;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";

// css
import styles from "../../../../layout/Auth/Auth.module.scss";

import logo from "../../../../Assets/images/logo.png";

const ValidId = ({ step, setStep }) => {
  const optionsCountry = [{ value: "country", label: "Country" }];
  const optionsLA = [{ value: "liquid_asset", label: "Liquid Asset" }];
  const optionsOM = [{ value: "origin_of_money", label: "Origin of Money" }];
  const navigate = useNavigate();
  const [pass, setPass] = useState();
  const handlePass = () => {
    setPass(!pass);
  };
  return (
    <>
      <Col md="9" sm="10" className="my-2">
        <div className="logo mb-3 text-center">
          <Link to="" className="">
            <img
              src={logo}
              alt=""
              className="img-fluid object-fit-contain"
              style={{ height: 60 }}
            />
          </Link>
        </div>
        <div
          className={`${styles.formWrpper} formWrpper px-lg-5 py-4 p-3 position-relative`}
          style={{ background: "#010101" }}
        >
          <div className="formInner">
            <div
              className={`${styles.top} top py-2 my-3 text-center  position-relative`}
            >
              <h5 className="m-0 pb-2 fw-sbold head text-white">
                Please add a photo of your valid ID or Passport
              </h5>
              <p className="m-0 text-white">
                Select relevant documents to complete your kyc
              </p>
            </div>
            <Form className="mx-auto" style={{ maxWidth: 531 }}>
              <Row className="justify-content-between">
                <Col lg="12" className="my-2">
                  <div
                    className={`${styles.uploadFile} uploadFile py-5 position-relative p-4 d-flex align-items-center justify-content-center text-center`}
                  >
                    <input
                      type="file"
                      className="file position-absolute form h-100 w-100 bg-transparent border-0"
                      style={{ top: 0, left: 0, zIndex: 9, cursor: "pointer" }}
                    />
                    <div className="content">
                      <div className="icnWrp my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="49"
                          height="48"
                          viewBox="0 0 49 48"
                          fill="none"
                        >
                          <path
                            d="M32.5 32L24.5 24L16.5 32"
                            stroke="white"
                            stroke-opacity="0.4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M24.5 24V42"
                            stroke="white"
                            stroke-opacity="0.4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M41.2799 36.78C43.2306 35.7165 44.7716 34.0337 45.6597 31.9972C46.5477 29.9607 46.7323 27.6864 46.1843 25.5334C45.6363 23.3803 44.3869 21.471 42.6333 20.1069C40.8796 18.7427 38.7216 18.0014 36.4999 18H33.9799C33.3745 15.6585 32.2462 13.4846 30.6798 11.642C29.1134 9.79927 27.1496 8.33567 24.9361 7.36118C22.7226 6.3867 20.317 5.92669 17.9002 6.01573C15.4833 6.10478 13.1181 6.74057 10.9823 7.8753C8.84649 9.01003 6.99574 10.6142 5.56916 12.5671C4.14259 14.5201 3.1773 16.771 2.74588 19.1508C2.31446 21.5305 2.42813 23.977 3.07835 26.3065C3.72856 28.636 4.8984 30.7877 6.49992 32.6"
                            stroke="white"
                            stroke-opacity="0.4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M32.5 32L24.5 24L16.5 32"
                            stroke="white"
                            stroke-opacity="0.4"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="py-2 my-2">
                        <h6 className="py-1 text-white m-0">
                          Select a file or drag and drop here
                        </h6>
                        <p className="m-0 py-1 text-muted">
                          JPG, PNG or PDF, file size no more than 10MB
                        </p>
                      </div>
                      <div className="btnWRpper mt-4">
                        <Button className="d-inline-flex align-items-center justify-content-center rounded-pill commonBtn">
                          SELECT FILE
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="12" className="my-2">
                  <div className={`${styles.btnWrpper} btnWrpper`}>
                    <Button
                      onClick={() => setStep("6")}
                      className="d-flex align-items-center justify-content-center w-100 commonBtn rounded-pill"
                    >
                      Next
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Col>
    </>
  );
};

export default ValidId;

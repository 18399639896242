import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChooseYourMethod from "./ChooseYouMethod";
import BankTransfer from "../BankTransfer";
import WithdrawalExchange from "../withdrawalExchange";
import TransactionDetail from "../TransactionDetail";
import TransactionUpdate from "../TransactionUpdate";

const Withdrawal = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [currentBankSelected, setCurrentBankSelected] = useState();
  const [exchangeData, setExchangeData] = useState();
  const [successTransactionDetails, setSuccessTransactionDetails] = useState(
    {}
  );
  return (
    <>
      {step === 1 && <ChooseYourMethod setStep={setStep} />}
      {step === 2 && (
        <BankTransfer
          setStep={setStep}
          currentBankSelected={currentBankSelected}
          setCurrentBankSelected={setCurrentBankSelected}
        />
      )}
      {step === 3 && (
        <WithdrawalExchange
          setStep={setStep}
          currentBankSelected={currentBankSelected}
          setExchangeData={setExchangeData}
        />
      )}
      {step === 4 && (
        <TransactionDetail
          setStep={setStep}
          exchangeData={exchangeData}
          currentBankSelected={currentBankSelected}
          setSuccessTransactionDetails={setSuccessTransactionDetails}
        />
      )}
      {step === 5 && (
        <TransactionUpdate
          setStep={setStep}
          exchangeData={exchangeData}
          currentBankSelected={currentBankSelected}
          successTransactionDetails={successTransactionDetails}
        />
      )}
    </>
  );
};

export default Withdrawal;

import * as TYPES from "./actionTypes";

const INIT_STATE = {
  loading: false,
  content: {},
  error: "",
};

const Reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TYPES.GET_CMS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_CMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.GET_CMS_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action?.payload,
      };

    case TYPES.TRUST_CONTENT:
      return {
        ...state,
        loading: true,
      };

    case TYPES.TRUST_CONTENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TYPES.TRUST_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action?.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

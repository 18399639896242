import DateFilter from "./DateFilter";

import { useEffect, useState } from "react";

// img
import {
  accountActivities,
  transactionHistoryApi,
} from "helpers/backend_helper";
import moment from "moment";
import { toast } from "react-toastify";
import ActivityList from "./ActivityList";

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const ActivityLog = () => {
  const [activityData, setActivityData] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [loader, setLoader] = useState(false);
  const getData = async () => {
    setLoader(true);

    try {
      const data = `?startDate=${formatDate(
        new Date(startDate)
      )}&endDate=${formatDate(new Date(endDate))}`;

      const res = await accountActivities(data);

      if (res.status === "success") {
        setLoader(false);

        setActivityData(res?.data?.nonTradeActivity);
      } else {
        setLoader(false);

        toast.error(res.message || "something went wrong");
      }
    } catch (error) {
      setLoader(false);

      console.log(error, "Error");
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, [endDate, startDate]);

  return (
    <>
      <DateFilter
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        loader={loader}
      />
      <ActivityList data={activityData} loader={loader} />
    </>
  );
};

export default ActivityLog;
